/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IChangeTransportState, IChangeTransportTypes } from './types';

const INITIAL_STATE: IChangeTransportState = {
  data: {
    fileTransportVoucherFront: null,
    numberVT: null,
    fileTransportVoucherBack: null,
    confirmedAddress: 'Sim',
    calledAddress: null,
    unitConfirmed: 'Sim',
    calledUnit: null,
    itineraryGoing: [],
    itineraryBack: [],
  },
  loading: false,
  error: null,
};

const changeTransport: Reducer<IChangeTransportState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case IChangeTransportTypes.CHANGE_TRANSPORT_SET_DATA: {
        if (action.payload.type === 'front') {
          draft.data = {
            ...state.data,
            fileTransportVoucherFront: action.payload.file,
            numberVT: action.payload.numberVT,
          };
        }
        if (action.payload.type === 'back') {
          draft.data = {
            ...state.data,
            fileTransportVoucherBack: action.payload.file,
          };
        }
        break;
      }
      case IChangeTransportTypes.CHANGE_TRANSPORT_ADDRESS_OR_UNIT: {
        if (action.payload.typeCall === 'address') {
          draft.data = {
            ...state.data,
            confirmedAddress: action.payload.confirmed,
            calledAddress: action.payload.callNumber,
          };
        }
        if (action.payload.typeCall === 'unit') {
          draft.data = {
            ...state.data,
            unitConfirmed: action.payload.confirmed,
            calledUnit: action.payload.callNumber,
          };
        }
        break;
      }
      case IChangeTransportTypes.CHANGE_TRANSPORT_ITINERARY: {
        if (action.payload.typeItinerary === 'ida') {
          draft.data = {
            ...state.data,
            itineraryGoing: action.payload.itinerary,
          };
        }
        if (action.payload.typeItinerary === 'volta') {
          draft.data = {
            ...state.data,
            itineraryBack: action.payload.itinerary,
          };
        }
        break;
      }
      case IChangeTransportTypes.CHANGE_TRANSPORT_REQUEST: {
        draft.data = {
          fileTransportVoucherFront:
            action.payload.data.fileTransportVoucherFront,
          numberVT: action.payload.data.numberVT,
          fileTransportVoucherBack:
            action.payload.data.fileTransportVoucherBack,
          confirmedAddress: action.payload.data.confirmedAddress,
          calledAddress: action.payload.data.calledAddress,
          unitConfirmed: action.payload.data.unitConfirmed,
          calledUnit: action.payload.data.calledUnit,
          itineraryGoing: action.payload.data.itineraryGoing,
          itineraryBack: action.payload.data.itineraryBack,
        };
        draft.loading = true;
        draft.error = null;
        break;
      }
      case IChangeTransportTypes.CHANGE_TRANSPORT_SUCCESS: {
        draft.data = {
          fileTransportVoucherFront: null,
          numberVT: null,
          fileTransportVoucherBack: null,
          confirmedAddress: 'Sim',
          calledAddress: null,
          unitConfirmed: 'Sim',
          calledUnit: null,
          itineraryGoing: [],
          itineraryBack: [],
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      case IChangeTransportTypes.CHANGE_TRANSPORT_FAILURE: {
        draft.data = {
          fileTransportVoucherFront: null,
          numberVT: null,
          fileTransportVoucherBack: null,
          confirmedAddress: 'Sim',
          calledAddress: null,
          unitConfirmed: 'Sim',
          calledUnit: null,
          itineraryGoing: [],
          itineraryBack: [],
        };
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default changeTransport;
