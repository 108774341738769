/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IHealthDependent } from 'models/request/health/duplicate';

/**
 * Actions Types
 */
export enum IHealthDuplicateTypes {
  HEALTH_DUPLICATE_SET_DATA = '@request/HEALTH_DUPLICATE_SET_DATA',
  HEALTH_DUPLICATE_REQUEST = '@request/HEALTH_DUPLICATE_REQUEST',
  HEALTH_DUPLICATE_SUCCESS = '@request/HEALTH_DUPLICATE_SUCCESS',
  HEALTH_DUPLICATE_FAILURE = '@request/HEALTH_DUPLICATE_FAILURE',
  HEALTH_DUPLICATE_RESET = '@request/HEALTH_DUPLICATE_RESET',
}

/**
 * State Types
 */

export interface IHealthData {
  dependents?: IHealthDependent[];
  optionId?: string;
}

export interface IHealthError {
  status?: number;
  message?: string;
}

export interface IHealthDuplicateState {
  readonly data: IHealthData | null;
  readonly loading: boolean;
  readonly error: IHealthError | null;
}
