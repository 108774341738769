import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import AuthLayout from 'components/_layouts/Auth';
import LandingLayout from 'components/_layouts/Landing';
import DefaultLayout from 'components/_layouts/Default';
import EmptyLayout from 'components/_layouts/Empty';

import Chat from 'components/Chat';
import CheckAccess from 'components/CheckAccess';
import Route from './Route';

const Landing = lazy(() => import('components/Landing'));
const Auth = lazy(() => import('components/Auth'));
const Maintenance = lazy(() => import('components/Maintenance'));
const SessionExpired = lazy(() => import('components/Auth/SessionExpired'));
const Home = lazy(() => import('components/Home'));
const BlockedAccess = lazy(() => import('components/BlockedAccess'));
const Paycheck = lazy(() => import('components/Paycheck'));
const Benefits = lazy(() => import('components/Benefits'));
const TimeSheet = lazy(() => import('components/TimeSheet'));
const Faq = lazy(() => import('components/Faq'));
const AccountConfig = lazy(() => import('components/AccountConfig'));
const Request = lazy(() => import('components/Request'));
const RequestTrack = lazy(() => import('components/Request/Track'));
const TrackDetails = lazy(() => import('components/Request/Track/Details'));
const VacationDetails = lazy(
  () => import('components/Request/Track/DetailsVacation'),
);
const RequestTrackType = lazy(
  () => import('components/Request/shared/RequestTrack'),
);
const Inss = lazy(() => import('components/Request/Inss'));
const INSSRequest = lazy(() => import('components/Request/Inss/Request'));
const INSSSend = lazy(() => import('components/Request/Inss/Send'));

const Employment = lazy(() => import('components/Request/Employment'));

const EmploymentRequestContact = lazy(
  () => import('components/Request/Employment/Contact'),
);

const EmploymentRequestBankData = lazy(
  () => import('components/Request/Employment/BankData'),
);

const EmploymentRequestAddress = lazy(
  () => import('components/Request/Employment/Address'),
);

const EmploymentRequestDependentFamilySalary = lazy(
  () => import('components/Request/Employment/Dependent/FamilySalary'),
);

const EmploymentRequestDependentIRPF = lazy(
  () => import('components/Request/Employment/Dependent/IRPF'),
);

const EmploymentRequestInscription = lazy(
  () => import('components/Request/Employment/Inscription'),
);

const EmploymentRequestPersonalInformation = lazy(
  () => import('components/Request/Employment/PersonalInformation'),
);

const RequestHealth = lazy(() => import('components/Request/Benefits/Health'));
/* const RequestHealthDuplicate = lazy(
  () => import('components/Request/Benefits/Health/Duplicate'),
); */
const RequestCardLoss = lazy(() => import('components/Request/shared/Loss'));
const RequestCardBreak = lazy(() => import('components/Request/shared/Break'));
const RequestCardTheft = lazy(() => import('components/Request/shared/Theft'));

const RequestHealthAddDependent = lazy(
  () => import('components/Request/Benefits/Health/Dependents/Add'),
);
const RequestHealthDeleteDependent = lazy(
  () => import('components/Request/Benefits/shared/Dependents/Delete'),
);
const RequestTransport = lazy(() => import('components/Request/Transport'));
const NewItinerary = lazy(
  () => import('components/Request/Transport/NewItinerary'),
);
/* const ChangeItinerary = lazy(
  () => import('components/Request/Transport/ChangeItinerary'),
); */
const CancelBenefit = lazy(
  () => import('components/Request/Transport/CancelBenefit'),
);
const DontWantBenefit = lazy(
  () => import('components/Request/Transport/CancelBenefit/DontWant'),
);

const RequestDental = lazy(() => import('components/Request/Benefits/Dental'));

/* const RequestDentalDuplicate = lazy(
  () => import('components/Request/Benefits/Dental/Duplicate'),
); */
/* const RequestHealthDuplicateOthers = lazy(
  () => import('components/Request/Benefits/Health/Duplicate/Others'),
); */

/* const RequestDentalDuplicateOthers = lazy(
  () => import('components/Request/Benefits/Dental/Duplicate/Others'),
); */
const RequestDentalAddDependent = lazy(
  () => import('components/Request/Benefits/Dental/Dependents/Add'),
);
const RequestDentalDeleteDependent = lazy(
  () => import('components/Request/Benefits/shared/Dependents/Delete'),
);

const BadgeRequest = lazy(() => import('components/Request/Badge'));

const Announcements = lazy(() => import('components/Announcements'));
const AnnouncementsDetails = lazy(
  () => import('components/Announcements/Details'),
);

const ResearchForm = lazy(() => import('components/ResearchForm'));
const Notification = lazy(() => import('components/Notification'));
const Browserwarning = lazy(() => import('components/Viewbrowser'));

const Routes: React.FC = () => {
  return (
    <>
      <Chat />
      <Switch>
        <Route
          exact
          path="/"
          component={Landing}
          title="Landing Page"
          layout={LandingLayout}
        />
        <Route
          isGlobal
          path="/maintenance"
          component={Maintenance}
          title="Manutenção"
          layout={AuthLayout}
        />
        <Route
          path="/auth"
          component={Auth}
          title="Autenticação"
          layout={AuthLayout}
        />
        <Route
          isGlobal
          path="/check_access/:id"
          component={CheckAccess}
          title="Novo Acesso Detectado"
          layout={EmptyLayout}
        />
        <Route
          isGlobal
          path="/blocked_access"
          component={BlockedAccess}
          title="Acesso Bloqueado"
          layout={AuthLayout}
        />
        <Route
          path="/session_expired"
          isPrivacy
          component={SessionExpired}
          title="Sessão Expirada"
          layout={AuthLayout}
        />
        <Route
          path="/home"
          isPrivate
          component={Home}
          title="Home"
          layout={DefaultLayout}
        />
        <Route
          path="/paycheck"
          isPrivate
          component={Paycheck}
          title="Demonstrativos"
          layout={DefaultLayout}
        />
        <Route
          path="/benefits"
          isPrivate
          component={Benefits}
          title="Benefícios"
          layout={DefaultLayout}
        />
        <Route
          path="/time_sheet"
          isPrivate
          component={TimeSheet}
          title="Espelho de Ponto"
          layout={DefaultLayout}
        />
        <Route
          path="/faq"
          isPrivate
          component={Faq}
          title="FAQ"
          layout={DefaultLayout}
        />
        <Route
          path="/account_config"
          isPrivate
          component={AccountConfig}
          title="Perfil"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/request"
          isPrivate
          component={Request}
          title="Solicitações"
          layout={DefaultLayout}
        />
        <Route
          path="/request/track"
          isPrivate
          component={RequestTrack}
          title="Solicitações | Acompanhamento"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/track/details"
          isPrivate
          component={TrackDetails}
          title="Acompanhamento | Detalhes"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/track/vacation/details"
          isPrivate
          component={VacationDetails}
          title="Acompanhamento de Férias | Detalhes"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/track/:request"
          isPrivate
          component={RequestTrackType}
          title="Acompanhamento | Solicitação"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/request/inss"
          isPrivate
          component={Inss}
          title="Solicitações | Carta de Proporcionalidade de INSS"
          layout={DefaultLayout}
        />
        <Route
          path="/request/inss/request"
          isPrivate
          component={INSSRequest}
          title="Solicitações | Solicitar Nova Carta"
          layout={DefaultLayout}
        />
        <Route
          path="/request/inss/send"
          isPrivate
          component={INSSSend}
          title="Solicitações | Enviar Nova Carta"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/request/employment"
          isPrivate
          component={Employment}
          title="Solicitações | Declaração de Vínculo Empregatício"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/request/employment/inscription"
          isPrivate
          component={EmploymentRequestInscription}
          title="Solicitações | Solicitar Nova Carta"
          layout={DefaultLayout}
        />

        <Route
          path="/request/employment/personalinformation/:action?"
          isPrivate
          component={EmploymentRequestPersonalInformation}
          title="Solicitações | Dados Pessoais | Alterar"
          layout={DefaultLayout}
        />

        <Route
          path="/request/employment/contact/:action?"
          isPrivate
          component={EmploymentRequestContact}
          title="Solicitações | Dados de Contato | Alterar"
          layout={DefaultLayout}
        />

        <Route
          path="/request/employment/bankdata"
          isPrivate
          component={EmploymentRequestBankData}
          title="Solicitações | Dados Bancários | Alterar"
          layout={DefaultLayout}
        />
        <Route
          path="/request/employment/address"
          isPrivate
          component={EmploymentRequestAddress}
          title="Solicitações | Dados Residenciais | Alterar"
          layout={DefaultLayout}
        />
        <Route
          path="/request/employment/dependent/familysalary/:action?"
          isPrivate
          component={EmploymentRequestDependentFamilySalary}
          title="Solicitações | Dependente Salário Família"
          layout={DefaultLayout}
        />

        <Route
          path="/request/employment/dependent/irpf/:action?"
          isPrivate
          component={EmploymentRequestDependentIRPF}
          title="Solicitações | Dependente Salário Família"
          layout={DefaultLayout}
        />

        <Route
          exact
          path="/request/health"
          isPrivate
          component={RequestHealth}
          title="Solicitações | Plano de Saúde"
          layout={DefaultLayout}
        />
        {/* <Route
          exact
          path="/request/health/duplicate"
          isPrivate
          component={RequestHealthDuplicate}
          title="Solicitações | PS | 2ª Via de Carteirinha"
          layout={DefaultLayout}
        /> */}
        <Route
          path="/request/:benefit/:type/loss"
          isPrivate
          component={RequestCardLoss}
          title="Solicitações | Perda do Cartão"
          layout={DefaultLayout}
        />
        <Route
          path="/request/:benefit/:type/break"
          isPrivate
          component={RequestCardBreak}
          title="Solicitações | Quebra ou Avaria"
          layout={DefaultLayout}
        />
        <Route
          path="/request/:benefit/:type/theft"
          isPrivate
          component={RequestCardTheft}
          title="Solicitações | Roubo ou Furto"
          layout={DefaultLayout}
        />
        {/* <Route
          path="/request/health/duplicate/others"
          isPrivate
          component={RequestHealthDuplicateOthers}
          title="Solicitações | PS | 2ª Via de Carteirinha | Outros"
          layout={DefaultLayout}
        /> */}
        <Route
          path="/request/health/dependent/add"
          isPrivate
          component={RequestHealthAddDependent}
          title="Solicitações | PS | Incluir Dependentes"
          layout={DefaultLayout}
        />
        <Route
          path="/request/health/dependent/delete"
          isPrivate
          component={() => <RequestHealthDeleteDependent benefit="health" />}
          title="Solicitações | PS | Excluir Dependentes"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/request/dental"
          isPrivate
          component={RequestDental}
          title="Solicitações | Plano Odontológico"
          layout={DefaultLayout}
        />
        {/* <Route
          exact
          path="/request/dental/duplicate"
          isPrivate
          component={RequestDentalDuplicate}
          title="Solicitações | PO | 2ª Via de Carteirinha"
          layout={DefaultLayout}
        /> */}
        {/* <Route
          path="/request/dental/duplicate/others"
          isPrivate
          component={RequestDentalDuplicateOthers}
          title="Solicitações | PO | 2ª Via de Carteirinha | Outros"
          layout={DefaultLayout}
        /> */}
        <Route
          exact
          path="/request/dental/dependent/add"
          isPrivate
          component={RequestDentalAddDependent}
          title="Solicitações | PO | Incluir Dependentes"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/request/dental/dependent/delete"
          isPrivate
          component={() => <RequestDentalDeleteDependent benefit="dental" />}
          title="Solicitações | PO | Excluir Dependentes"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/request/transport"
          isPrivate
          component={RequestTransport}
          title="Solicitações | Vale Transporte"
          layout={DefaultLayout}
        />
        <Route
          path="/request/transport/new/itinerary"
          isPrivate
          component={NewItinerary}
          title="Solicitações | VT | Inclusão"
          layout={DefaultLayout}
        />
        {/* <Route
          path="/request/transport/change/itinerary"
          isPrivate
          component={ChangeItinerary}
          title="Solicitações | VT | Alteração"
          layout={DefaultLayout}
        /> */}
        <Route
          exact
          path="/request/transport/cancel"
          isPrivate
          component={CancelBenefit}
          title="Solicitações | VT | Cancelamento"
          layout={DefaultLayout}
        />
        <Route
          path="/request/transport/cancel/dont_want"
          isPrivate
          component={DontWantBenefit}
          title="Solicitações | VT | Cancelamento| Não Desejo Mais o Benefício"
          layout={DefaultLayout}
        />
        <Route
          path="/request/badge"
          isPrivate
          component={BadgeRequest}
          title="Solicitações | Segunda Via Crachá "
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/announcements"
          isPrivate
          component={Announcements}
          title="Comunicados"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/announcements/:title"
          isPrivate
          component={AnnouncementsDetails}
          title="Comunicados | Detalhes"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/pesquisas/:slug"
          isPrivate
          component={ResearchForm}
          title="Pesquisa"
          layout={DefaultLayout}
        />
        <Route
          exact
          path="/notification"
          isPrivate
          component={Notification}
          title="Notificações"
          layout={DefaultLayout}
        />
        <Route
          isGlobal
          path="/browser_warning"
          layout={AuthLayout}
          component={Browserwarning}
          title="Acesso não autorizado"
        />

        <Route
          path="*"
          layout={EmptyLayout}
          component={() => <h1>404 Not found</h1>}
          title="Página não encontrada"
        />
      </Switch>
    </>
  );
};

export default Routes;
