import styled from 'styled-components';
import { MIN_WIDTH_DESKTOP } from 'utils/constants';

export const Container = styled.div`
  position: fixed;
  width: 220px;
  height: 56px;
  background: linear-gradient(
      265.44deg,
      rgba(145, 216, 246, 0.06) 1.46%,
      rgba(145, 216, 246, 0.3) 105.09%
    ),
    ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.16);
  
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.tertiary};
  padding: auto 13px;
  bottom: 0;
  right: 1%;

  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    font-family: Roboto;
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 16px;
    padding: 10px 0;
    padding-left: 10px;
    border-left: 1px solid ${({ theme }) => theme.colors.primary};
  }

  svg {
    margin: 13px 12px;
  }

  display: flex; /* CHAT NICE - REMOVER EM PRODUCAOA */
  
  @media (max-width: ${MIN_WIDTH_DESKTOP}px) {
      display: none !important;
  }
 
`;
