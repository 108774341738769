/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum ResearchFormTypes {
  LOAD_RESEARCH_FORM_REQUEST = '@research/LOAD_RESEARCH_FORM_REQUEST',
  LOAD_RESEARCH_FORM_SUCCESS = '@research/LOAD_RESEARCH_FORM_SUCCESS',
  LOAD_RESEARCH_FORM_FAILURE = '@research/LOAD_RESEARCH_FORM_FAILURE',
  LOAD_RESEARCH_FORM_EXIT = '@research/LOAD_RESEARCH_FORM_VARIABLE',
}

interface IResearchFormData {
  acf: any;
  id: number;
  link: string;
  slug: string;
  title: {
    rendered: string;
  };
}

/**
 * State Types
 */
export interface IResearchFormState {
  readonly data: IResearchFormData;
  readonly loading: boolean;
  readonly error: boolean;
  readonly researchFormRoute: string;
  readonly researchFormExit: boolean;
}
