import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from 'services/api';

import { IState } from 'store';

import IConfigurationData from 'models/accountConfig/configuration';
import {
  getConfigurationSuccess,
  getConfigurationFailure,
  configurationPutRequest,
  configurationPutSuccess,
  configurationPutFailure,
} from './actions';

import { IConfigurationTypes } from './types';

type IConfigurationPutRequestType = ReturnType<typeof configurationPutRequest>;

export interface INotification {
  push: boolean;
  email: boolean;
  sms: boolean;
}

export function* getConfiguration() {
  try {
    const { data }: AxiosResponse<IConfigurationData> = yield call(
      api.get,
      `/preferencia-usuario`,
    );

    const arrayNotification = [];

    if (data.notificacao.push) {
      arrayNotification.push('push');
    }
    if (data.notificacao.email) {
      arrayNotification.push('email');
    }
    if (data.notificacao.sms) {
      arrayNotification.push('sms');
    }

    yield put(getConfigurationSuccess(data, arrayNotification));
  } catch (error) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };
      yield put(getConfigurationFailure(response));

      return;
    }

    yield put(
      getConfigurationFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

export function* configurationPut({ payload }: IConfigurationPutRequestType) {
  try {
    const { arrayNotification } = payload;

    const getCdCpf = (state: IState) => state.user.current?.documentos.cdCPF;
    const cdCpf: string = yield select(getCdCpf);

    let push;
    let email;
    let sms;

    arrayNotification.map((notification) => {
      if (notification === 'push') {
        push = true;
      }

      if (notification === 'email') {
        email = true;
      }

      if (notification === 'sms') {
        sms = true;
      }
      return notification;
    });

    const notification: INotification = {
      push: push || false,
      email: email || false,
      sms: sms || false,
    };

    const formData = new FormData();
    formData.append('cdCPF', cdCpf);
    formData.append('nmUrlAvatar', '000000000');
    formData.append('notificacao', JSON.stringify(notification));

    yield call(api.put, '/preferencia-usuario', formData);

    yield put(configurationPutSuccess());
  } catch (error) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };

      yield put(configurationPutFailure(response));

      return;
    }

    yield put(
      configurationPutFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

export default all([
  takeLatest(IConfigurationTypes.USER_CONFIGURATION_REQUEST, getConfiguration),
  takeLatest(
    IConfigurationTypes.USER_CONFIGURATION_PUT_REQUEST,
    configurationPut,
  ),
]);
