/* eslint-disable no-restricted-syntax */
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { IState } from 'store';

import api from 'services/api';
import { IHealthDuplicateState, IHealthDuplicateTypes } from './types';

import {
  healthDuplicateRequest,
  healthDuplicateSuccess,
  healthDuplicateFailure,
} from './actions';

type IHealthDuplicateType = ReturnType<typeof healthDuplicateRequest>;

export function* healthDuplicate({ payload }: IHealthDuplicateType) {
  try {
    const { type, attachment } = payload;

    const getCpf = (state: IState) => state.auth.cpf;
    const cpf: string = yield select(getCpf);

    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const matricula: string = yield select(getMatricula);

    const getHealth = (state: IState) => state.healthDuplicate;
    const { data }: IHealthDuplicateState = yield select(getHealth);

    const defineOtherReason = () => {
      switch (data?.optionId) {
        case '1':
          return '30 dias de plano, mas não recebeu a carteirinha';
        case '2':
          return 'Carteirinha com nome incorreto';
        case '3':
          return 'Alterar nome';
        default:
          return '';
      }
    };

    const defineDescricaoMotivo = () => {
      switch (type) {
        case 'Perda':
          return 'Perda do Cartão';
        case 'Avaria':
          return 'Quebra ou Avaria';
        case 'Roubo':
          return 'Roubo ou Furto';
        case 'Outros':
          return defineOtherReason();
        default:
          return '';
      }
    };

    for (const dependent of data?.dependents || []) {
      const formData = new FormData();

      formData.append('cpf', cpf);
      formData.append('matricula', matricula);
      formData.append('tipoMotivo', type);
      formData.append('tipoPlano', 'PM');
      formData.append('nomeSolicitanteCarteirinha', dependent.name);
      formData.append(
        'descricaoMotivo',
        defineDescricaoMotivo().charAt(0).toUpperCase() +
          defineDescricaoMotivo().slice(1).toLocaleLowerCase(),
      );

      if (attachment) {
        formData.append('anexo', attachment);
      }

      yield call(api.post, '/portal-conecta/segunda-via-carteirinha', formData);
    }

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `Saúde - Segunda Via - ${
        type !== 'Outros'
          ? defineDescricaoMotivo()
          : `Outros - ${defineOtherReason()}`
      }`,
    });

    yield put(healthDuplicateSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(healthDuplicateFailure(response));
      return;
    }
    yield put(
      healthDuplicateFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  } finally {
    const { handleClickNextStep } = payload;

    handleClickNextStep();
  }
}

export default all([
  takeLatest(IHealthDuplicateTypes.HEALTH_DUPLICATE_REQUEST, healthDuplicate),
]);
