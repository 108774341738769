/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IDependent } from 'models/dependents';

/**
 * Actions Types
 */
export enum DependentsTypes {
  LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST = '@dependents/LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST',
  LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_SUCCESS = '@dependents/LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_SUCCESS',
  LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_FAILURE = '@dependents/LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_FAILURE',
  LOAD_DEPENDENTS_IRPF_REQUEST = '@dependents/LOAD_DEPENDENTS_IRPF_REQUEST',
  LOAD_DEPENDENTS_IRPF_REQUEST_SUCCESS = '@dependents/LOAD_DEPENDENTS_IRPF_REQUEST_SUCCESS',
  LOAD_DEPENDENTS_IRPF_REQUEST_FAILURE = '@dependents/LOAD_DEPENDENTS_IRPF_REQUEST_FAILURE'
}

/**
 * State Types
 */
export interface IDependentsState {
  readonly dataFamilySalary: IDependent[];
  readonly dataIRPF: IDependent[];
  readonly loading: boolean;
  readonly error: boolean;
}
