import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 40px;
  margin-top: 34px;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  svg {
    width: 50px;
    height: 50px;
  }

  svg > .path {
    stroke: ${({ theme }) => theme.colors.disabledSecondary};
  }
`;
