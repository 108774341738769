/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { ITransportDuplicateState, ITransportDuplicateTypes } from './types';

const INITIAL_STATE: ITransportDuplicateState = {
  data: null,
  loading: false,
  error: null,
};

const transportDuplicate: Reducer<ITransportDuplicateState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITransportDuplicateTypes.TRANSPORT_DUPLICATE_SET_DATA: {
        draft.data = action.payload.data;
        break;
      }
      case ITransportDuplicateTypes.TRANSPORT_DUPLICATE_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ITransportDuplicateTypes.TRANSPORT_DUPLICATE_SUCCESS: {
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case ITransportDuplicateTypes.TRANSPORT_DUPLICATE_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case ITransportDuplicateTypes.TRANSPORT_DUPLICATE_RESET: {
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default transportDuplicate;
