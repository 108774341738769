/* eslint-disable no-underscore-dangle */
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from 'services/api';

import history from 'services/history';

import {
  formatCpf,
  formatRg,
  formatPis,
  formatCel,
  formatTel,
  formatCep,
  formatCapitalize,
  capitalizeText,
} from 'utils/format';

import { IUser } from 'models/user';
import { UserTypes } from './types';

import {
  getUserSuccess,
  getUserFailure,
  blockUserRequest,
  blockUserSuccess,
  blockUserFailure,
} from './actions';
import { logoutSuccess } from '../auth/actions';

type blockUserRequestType = ReturnType<typeof blockUserRequest>;

export function* getUser() {
  try {
    const { data }: AxiosResponse<IUser[]> = yield call(
      api.get,
      '/colaborador',
    );

    const newData = data.map((user) => ({
      ...user,
      nmNome: user.nmNome ? capitalizeText(user.nmNome) : '',
      nmCargo: user.nmCargo ? user.nmCargo.toLowerCase() : '',
      nmEstadoCivil: user.nmEstadoCivil
        ? formatCapitalize(user.nmEstadoCivil)
        : '',
      nmEscolaridade: user.nmEscolaridade
        ? capitalizeText(user.nmEscolaridade)
        : '',
      nmEmpresa: user.nmEmpresa ? user.nmEmpresa.toLowerCase() : '',
      formattedBorn: user.dtNascimento
        ? user.dtNascimento.replace(new RegExp('/', 'g'), '.')
        : '',
      formattedAdmission: user.dtAdmissao
        ? user.dtAdmissao.replace(new RegExp('/', 'g'), '.')
        : '',
      formattedGeneroDeclarado: user.nmGeneroDeclarado
        ? formatCapitalize(user.nmGeneroDeclarado)
        : '',
      banco: {
        ...user.banco,
        nmBanco: user.banco.nmBanco ? user.banco.nmBanco.toLowerCase() : '',
      },
      documentos: {
        ...user.documentos,
        formattedCpf: user.documentos.cdCPF
          ? formatCpf(user.documentos.cdCPF)
          : '',
        formattedRg: user.documentos.cdRG ? formatRg(user.documentos.cdRG) : '',
        formattedPis: user.documentos.cdPIS
          ? formatPis(user.documentos.cdPIS)
          : '',
      },
      endereco: {
        ...user.endereco,
        nmEndereco: user.endereco.nmEndereco
          ? capitalizeText(user.endereco.nmEndereco)
          : '',
        nmComplemento: user.endereco.nmComplemento
          ? user.endereco.nmComplemento.toLowerCase()
          : '',
        nmBairro: user.endereco.nmBairro
          ? capitalizeText(user.endereco.nmBairro)
          : '',
        nmMunicipio: user.endereco.nmMunicipio
          ? capitalizeText(user.endereco.nmMunicipio)
          : '',
        nmEstado: user.endereco.nmEstado
          ? user.endereco.nmEstado.toUpperCase()
          : '',
        formattedCep: user.endereco.cdCEP ? formatCep(user.endereco.cdCEP) : '',
      },
      telefone: {
        ...user.telefone,
        formattedCel:
          user?.telefone.cdDDDCel && user?.telefone.cdCel
            ? formatCel(`${user?.telefone.cdDDDCel}${user?.telefone.cdCel}`)
            : '',
        formattedTel:
          user?.telefone.cdDDDTel && user?.telefone.cdTelefone
            ? formatTel(
                `${user?.telefone.cdDDDTel}${user?.telefone.cdTelefone}`,
              )
            : '',
      },
    }));

    yield put(getUserSuccess(newData, newData[0] || null));
  } catch (err: any) {
    if (err && err?.response) {
      let message = '';

      switch (err.response.status) {
        case 404:
          message = 'CPF válido, mas não localizamos seu cadastro';
          break;
        default:
          message = err.response.data.message;
      }

      const response = {
        status: err.response.status,
        message,
      };

      yield put(getUserFailure(response));
      return;
    }

    yield put(
      getUserFailure({
        status: 400,
        message: 'Erro interno no servidor',
      }),
    );
  }
}

export function* blockUser({ payload }: blockUserRequestType) {
  try {
    const { cpf } = payload;

    yield call(api.post, '/bloquear-acesso', {
      cpf,
    });

    yield put(blockUserSuccess());
    history.push('/blocked_access');
    yield put(logoutSuccess());
  } catch (err: any) {
    if (err && err?.response) {
      yield put(blockUserFailure(err.response));
      return;
    }

    yield put(
      blockUserFailure({
        status: 400,
        message: 'Erro interno no servidor',
      }),
    );
  }
}

export default all([takeLatest(UserTypes.GET_USER_REQUEST, getUser)]);
