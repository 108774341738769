/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import styled from 'styled-components';

interface IFloatingBalloon {
  visible: boolean;
}

export const Container = styled.button<IFloatingBalloon>`
  height: 100vh;
  width: 100%;
  margin: 0;
  background: rgba(0, 0, 0, 0.27);
  border: 0;

  position: fixed;
  bottom: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: visibility 0.2s, opacity 0.3s linear;
`;

export const Balloon = styled.div<IFloatingBalloon>`
  position: absolute;
  bottom: 0;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  bottom: ${({ visible }) => (visible ? 0 : 15)}px;
  transition: bottom 0.5s linear;
`;

export const Content = styled.div`
  width: auto;
  height: 77px;
  background: white;
  border-radius: 7px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
  margin: 0 15px;
  padding: 20px 31px 19px 25px;
  text-align: left;

  > span {
    text-align: left;
    font-size: 13px;
    line-height: 135%;
    color: black;

    > svg {
      width: 16px;
      height: 16px;
    }

    > span {
      font-weight: 700;
    }
  }
`;

export const BalloonTip = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid white;

  margin-top: -2px;
  margin-bottom: 5px;
`;
