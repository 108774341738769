import IDental from 'models/benefits/dental';
import { DentalTypes } from './types';

export const getDentalRequest = () => ({
  type: DentalTypes.GET_DENTAL_REQUEST,
});

export const getDentalSuccess = (data: IDental | null) => ({
  type: DentalTypes.GET_DENTAL_SUCCESS,
  payload: {
    data,
  },
});

export const getDentalFailure = ({
  haveInsurance,
}: {
  haveInsurance: boolean | null;
}) => ({
  type: DentalTypes.GET_DENTAL_FAILURE,
  payload: {
    haveInsurance,
  },
});
