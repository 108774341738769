import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import apiRH from 'services/api';

import { IDependent, IDependentsRequest } from 'models/dependents';
import { DependentsTypes } from './types';

import {
  loadDependentsFamilySalaryRequestFailure,
  loadDependentsIRPFRequestFailure,
  loadDependentsFamilySalaryRequestSuccess,
  loadDependentsIRPFRequestSuccess
} from './actions';

export function* loadDependents(params: any) {
  const param: IDependentsRequest = params.payload;
  try {

    const { data: dependents }: AxiosResponse<IDependent[]> = yield call(
      apiRH.post,
      '/dependente',
      param
    );

    if (param.salarioFamilia) {
      yield put(loadDependentsFamilySalaryRequestSuccess(dependents));
    }
    if (param.impostoRenda) {
      yield put(loadDependentsIRPFRequestSuccess(dependents));
    }
  } catch (err) {
    if (param.salarioFamilia) {
      yield put(loadDependentsFamilySalaryRequestFailure());
    }
    if (param.impostoRenda) {
      yield put(loadDependentsIRPFRequestFailure());
    }
  }
}
//@ts-ignore
export default all([
  takeLatest(DependentsTypes.LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST, loadDependents),
  takeLatest(DependentsTypes.LOAD_DEPENDENTS_IRPF_REQUEST, loadDependents)
]);
