import { IAnnouncements } from 'models/announcements';
import { AnnouncementsTypes } from './types';

export const loadAnnouncementsRequest = ({
  page,
  id,
}: {
  page: number;
  id?: string;
}) => ({
  type: AnnouncementsTypes.LOAD_ANNOUNCEMENTS_REQUEST,
  payload: {
    page,
    id,
  },
});

export const loadAnnouncementsSuccess = (
  data: IAnnouncements[],
  totalPage: number,
  currentPage: number,
  id: string | undefined,
) => ({
  type: AnnouncementsTypes.LOAD_ANNOUNCEMENTS_SUCCESS,
  payload: {
    data,
    totalPage,
    currentPage,
    id,
  },
});

export const loadAnnouncementsFailure = () => ({
  type: AnnouncementsTypes.LOAD_ANNOUNCEMENTS_FAILURE,
});

export const loadAnnouncementsReset = () => ({
  type: AnnouncementsTypes.LOAD_ANNOUNCEMENTS_RESET,
});
