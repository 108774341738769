/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { IState } from 'store';
import { IUserState } from 'store/modules/user/types';
import { IChatState } from 'store/modules/chat/types';
import { setLoaded, setOpenWindow, setToken } from 'store/modules/chat/actions';
import { ChatChannels } from 'store/modules/chat/types';
import {
  showChatButton,
  /* isProductionEnv, */
} from 'utils/general';
import { CD_FILIAL_EXECUTIVE } from 'utils/constants';
import { shortenName } from 'utils/format';
import { NiceChat } from '@rededor/nice-chat-component-lib-react';

interface ParamTypes {
  chat: string;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Chat: React.FC = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  //const [tokenNiceChat, setTokenNiceChat] = useState<ChatChannels | null>(null);
  const [nameUser, setNameUser] = useState<string>('');
  const query: any = useQuery();

  const dispatch = useDispatch();

  const { data, current } = useSelector<IState, IUserState>(
    (state) => state.user,
  );

  const { openWindow, token: tokenNiceChat } = useSelector<IState, IChatState>(
    (state) => state.chat,
  );

  const dispatchOpenWindow = (flag: boolean) => {
    dispatch(setOpenWindow(flag));
  };

  const dispatchLoaded = (flag: boolean) => {
    dispatch(setLoaded(flag));
  };

  const dispatchToken = (token: ChatChannels) => {
    dispatch(setToken(token));
  };

  const cdFilial = current?.cdFilial;

  const isExecutive = (): boolean => {
    return cdFilial === CD_FILIAL_EXECUTIVE;
  };

  const clearBEStorage = () => {
    for (let key in localStorage) {
      if (
        key.startsWith('_BE') ||
        key.startsWith('_BEChatWindow') ||
        key.startsWith('_BECustomerId') ||
        key.startsWith('_BEChatWindow_version') ||
        key.startsWith('_DFOVisitorLastActiveLoggedAt')
      ) {
        localStorage.removeItem(key);
      }
    }
  };

  useEffect(() => {
    if (cdFilial) {
      clearBEStorage()
      setNameUser(current?.nmNome);
      if (isExecutive() && tokenNiceChat !== ChatChannels.Executive) {
        dispatchToken(ChatChannels.Executive);
      }
    } else {
      clearBEStorage()
      setNameUser('');
      if (tokenNiceChat !== ChatChannels.Default) {
        dispatchToken(ChatChannels.Default);
      }
    }
  }, [cdFilial]);

  /* if (isProductionEnv()) {
    return null;
  } */

  useEffect(() => {
    const chatButton: any = document.getElementById('chatButtonFooter');
    if (chatButton) {
      chatButton.style.display = 'none';
    }
    dispatchLoaded(true);
  }, []);

  return (
    <NiceChat
      id="niceChat"
      style={{ display: 'none' }}
      token={String(tokenNiceChat)}
      isLazy={false}
      isMobile={isTabletOrMobile}
      /* hidePreSurvey={nameUser !== ''} */
      userName={shortenName(nameUser)}
      onChatLoaded={() => {
        const niceChat: any = document.getElementById('niceChat');
        showChatButton();
        dispatchLoaded(false);
        window.niceChat.open = () => {
          if (openWindow !== true) {
            dispatchOpenWindow(true);
            niceChat.click();
          }
        };

        if (isExecutive() && query.get('chat') === 'open') {
          niceChat.click();
        }

        window.brandembassy('sendFirstMessageAutomatically', 'Conversa iniciada');

      }}
      onWidgetChange={(obj: any) => {
        dispatchOpenWindow(obj.detail === true);

        if (obj.detail === true) {
          window.brandembassy('sendFirstMessageAutomatically', 'Conversa iniciada');
          const frame: any = document.querySelector(
            '#be-chat-container iframe',
          );
          const innerDocument: any =
            frame.contentDocument || frame.contentWindow.document;
          const elemName = innerDocument.querySelector('[name="name"]');
          if (cdFilial && elemName) {
            setTimeout(() => {
              elemName.setAttribute('readonly', 'readonly');
            }, 1000);
          }
        }

        // //const  header:any =   innerDocument.querySelector('[class^="ActionPanel_Close"]');
        // //header.insertAdjacentHTML( 'beforeBegin',"<span style='text-align:left'>Olá, COLABORADOR</span> " );
        // //console.log('innerDocument - header:', header);
        // const elems: any = innerDocument.querySelectorAll(
        //   '[class^="BrandLabel_BrandLabel"]',
        // );
        // if (elems.length) {
        //   elems.forEach((element: any) => {
        //     /* eslint-disable no-param-reassign */
        //     element.innerHTML = `Ativado por ${getRedeDorBrand()}`;
        //   });
        // }
      }}
    />
  );
};

export default Chat;
