/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import IDental from 'models/benefits/dental';

/**
 * Actions Types
 */
export enum DentalTypes {
  GET_DENTAL_REQUEST = '@dental/GET_DENTAL_REQUEST',
  GET_DENTAL_SUCCESS = '@dental/GET_DENTAL_SUCCESS',
  GET_DENTAL_FAILURE = '@dental/GET_DENTAL_FAILURE',
}

/**
 * State Types
 */
export interface IDentalState {
  readonly data: IDental | null;
  readonly haveInsurance: boolean | null;
  readonly disabled: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
