/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { INotificationTypes, INotificationState } from './types';

const INITIAL_STATE: INotificationState = {
  dataNotificationAlert: null,
  dataNotification: null,
  page: 1,
  limit: 10,
  numberClosedNotification: 0,
  loading: false,
  idNotification: '',
  errorNotificationAlert: null,
  errorNotification: null,
  errorNotificationPut: null,
};

const notification: Reducer<INotificationState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case INotificationTypes.NOTIFICATION_ALERT_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case INotificationTypes.NOTIFICATION_ALERT_GET_SUCCESS: {
        draft.dataNotificationAlert = action.payload.dataNotificationAlert;
        draft.loading = false;
        draft.errorNotificationAlert = null;
        break;
      }
      case INotificationTypes.NOTIFICATION_ALERT_GET_FAILURE: {
        draft.loading = false;
        draft.errorNotificationAlert = action.payload.error;
        break;
      }
      case INotificationTypes.NOTIFICATION_ALL_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case INotificationTypes.NOTIFICATION_ALL_GET_SUCCESS: {
        draft.numberClosedNotification =
          action.payload.numberClosedNotification;
        draft.loading = false;
        draft.errorNotificationAlert = null;
        break;
      }
      case INotificationTypes.NOTIFICATION_ALL_GET_FAILURE: {
        draft.loading = false;
        draft.errorNotificationAlert = action.payload.error;
        break;
      }
      case INotificationTypes.NOTIFICATION_GET_REQUEST: {
        draft.page = action.payload.page;
        draft.limit = action.payload.limit;
        draft.loading = true;
        break;
      }
      case INotificationTypes.NOTIFICATION_GET_SUCCESS: {
        draft.dataNotification = action.payload.dataNotification;
        draft.loading = false;
        draft.errorNotification = null;
        break;
      }
      case INotificationTypes.NOTIFICATION_GET_FAILURE: {
        draft.loading = false;
        draft.errorNotification = action.payload.error;
        break;
      }
      case INotificationTypes.NOTIFICATION_GET_RESET: {
        draft.dataNotification = null;
        draft.page = 1;
        draft.limit = 10;
        draft.loading = false;
        draft.errorNotification = null;
        break;
      }
      case INotificationTypes.NOTIFICATION_PUT_OPEN_REQUEST: {
        draft.idNotification = action.payload.idNotification;
        break;
      }
      case INotificationTypes.NOTIFICATION_PUT_OPEN_SUCCESS: {
        draft.errorNotificationPut = null;
        break;
      }
      case INotificationTypes.NOTIFICATION_PUT_OPEN_FAILURE: {
        draft.errorNotificationPut = action.payload.errorNotificationPut;
        break;
      }
      default:
        break;
    }
  });
};

export default notification;
