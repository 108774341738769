/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IAnnualIncomeState, AnnualIncomeTypes } from './types';

const INITIAL_STATE: IAnnualIncomeState = {
  data: null,
  disabled: false,
  printing: false,
  loading: false,
  error: false,
};

const annualIncome: Reducer<IAnnualIncomeState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case AnnualIncomeTypes.LOAD_ANNUAL_INCOME_REQUEST: {
        draft.loading = true;
        break;
      }
      case AnnualIncomeTypes.LOAD_ANNUAL_INCOME_SUCCESS: {
        draft.data = action.payload.data;
        draft.disabled = action.payload.data === null;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case AnnualIncomeTypes.LOAD_ANNUAL_INCOME_FAILURE: {
        draft.data = null;
        draft.disabled = false;
        draft.loading = false;
        draft.error = true;
        break;
      }
      case AnnualIncomeTypes.PRINT_ANNUAL_INCOME_REQUEST: {
        draft.printing = true;
        break;
      }
      case AnnualIncomeTypes.PRINT_ANNUAL_INCOME_SUCCESS: {
        draft.printing = false;
        draft.error = false;
        break;
      }
      case AnnualIncomeTypes.PRINT_ANNUAL_INCOME_FAILURE: {
        draft.printing = false;
        break;
      }
      default:
        break;
    }
  });
};

export default annualIncome;
