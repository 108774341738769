import styled from 'styled-components';
import backgroundImage from 'assets/images/background.jpg';
import {
  DESKTOP_HEADER_HEIGHT,
  MAX_WIDTH_TABLET,
  MOBILE_MAIN_HEADER_HEIGHT,
  UP_HEADER_HEIGHT,
} from 'utils/constants';

interface IUpdateAvailable {
  update: boolean;
  isPhoneUpdate: boolean;
}

export const Container = styled.div`
  width: 100%;
  min-height: 100%;

  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;

  .containerHeaderButtonPageInitial {
    margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;
  }

  #containerHeader {
    margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;
    top: ${(props: IUpdateAvailable) => (props.isPhoneUpdate ? 0 : UP_HEADER_HEIGHT)}px;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    background-image: none;
    background: linear-gradient(
        359.15deg,
        rgba(209, 206, 206, 0.5) 21.77%,
        rgba(209, 206, 206, 0) 40.6%
      ),
      linear-gradient(
        118.58deg,
        rgba(242, 242, 242, 0.96) 43.74%,
        rgba(242, 242, 242, 0) 96.41%
      ),
      #f2f2f2;

    #containerHeader {
      top: 0px;
    }
  }
`;

/* eslint-disable no-nested-ternary */
export const Wrapper = styled.div`
  padding-top: ${(props: IUpdateAvailable) =>
    props.isPhoneUpdate ? 80 : (props.update ? DESKTOP_HEADER_HEIGHT + 65 : DESKTOP_HEADER_HEIGHT)}px;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    padding-top: ${(props: IUpdateAvailable) =>
    props.isPhoneUpdate ? 65 : (props.update
      ? MOBILE_MAIN_HEADER_HEIGHT + 65
      : MOBILE_MAIN_HEADER_HEIGHT)}px;

    padding-bottom: ${(props: IUpdateAvailable) =>
    props.update ? MOBILE_MAIN_HEADER_HEIGHT : 0}px;
  }
`;
