import { ITrackGetData, ITrackGetError, ITrackGetTypes } from './types';

export const trackGetRequest = ({ currentPage }: { currentPage: number }) => ({
  type: ITrackGetTypes.TRACK_GET_REQUEST,
  payload: {
    currentPage,
  },
});

export const trackGetSuccess = (data: ITrackGetData) => ({
  type: ITrackGetTypes.TRACK_GET_SUCCESS,
  payload: {
    data,
  },
});

export const trackGetFailure = (error: ITrackGetError) => ({
  type: ITrackGetTypes.TRACK_GET_FAILURE,
  payload: {
    error,
  },
});

export const trackGetReset = () => ({
  type: ITrackGetTypes.TRACK_GET_RESET,
});
