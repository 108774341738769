/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IChatState, ChatTypes, ChatChannels } from './types';

const INITIAL_STATE: IChatState = {
  loaded: false,
  token: ChatChannels.Default,
  openWindow: false,
};

const chat: Reducer<IChatState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case ChatTypes.CHAT_LOADED: {
        draft.loaded = action.payload.loaded;
        break;
      }

      case ChatTypes.CHAT_TOKEN: {
        draft.token = action.payload.token;
        break;
      }

      case ChatTypes.CHAT_OPEN_WINDOW: {
        draft.openWindow = action.payload.openWindow;
        break;
      }

      default:
        break;
    }
  });
};

export default chat;
