/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { IUpdateAvailableState, UpdateAvailableTypes } from './types';

const INITIAL_STATE: IUpdateAvailableState = {
  update: false,
  downloadUpdates: false,
};

const updateAvailable: Reducer<IUpdateAvailableState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case UpdateAvailableTypes.UPDATE_AVAILABLE: {
        draft.update = action.payload.update;
        draft.downloadUpdates = action.payload.downloadUpdates;
        break;
      }
      default:
        break;
    }
  });
};

export default updateAvailable;
