/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import { version } from '../../package.json';

export function isMobile(): boolean {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function isIOS(): boolean {
  return !!navigator.userAgent.match(/iPhone/i);
}

export function isMiBrowser(): boolean {
  return !!navigator.userAgent.match(/MiuiBrowser/i);
}

export function isSamsungBrowser(): boolean {
  return !!navigator.userAgent.match(/SamsungBrowser/i);
}
export function isChrome(): boolean {
  return !!navigator.userAgent.match(/Chrome/i);
}
export function isEdg(): boolean {
  return !!navigator.userAgent.match(/Edg/i);
}
export function isApple(): boolean {
  return (
    !!navigator.userAgent.match(/iPhone/i) ||
    !!navigator.userAgent.match(/iPad/i) ||
    !!navigator.userAgent.match(/iPod/i) ||
    !!navigator.vendor.match(/Apple Computer, Inc./i)
  );
}

export function saveVersion(): void {
  localStorage.setItem('rh-digital:version', version);
}

export function getBenefitContraction(
  benefit: 'health' | 'transport' | 'dental',
) {
  switch (benefit) {
    case 'health':
      return 'PS';
    case 'dental':
      return 'PO';
    case 'transport':
      return 'VT';
    default:
      return '';
  }
}

export function getFlowName(type: string) {
  switch (type) {
    // case 'duplicate':
    //   return '2ª Via de Carteirinha';
    case 'add':
      return 'Inclusão de Dependentes';
    case 'cancel':
      return 'Cancelamento';
    default:
      return '';
  }
}

//Ex.: CPF ###.###.###-##
export function stringFormatter(str: string, mask: string, maskChar = '#') {
  const arrStr = Array.from(str);
  const arrMaskCount = mask.split(maskChar);
  if (arrStr.length !== arrMaskCount.length - 1) {
    return str;
  }
  const arrMask = Array.from(mask);
  let i = 0,
    result = '';

  arrMask.forEach((m: string, k) => {
    if (m === maskChar) {
      result = `${result}${arrStr[i]}`;
      i++;
    } else {
      result = `${result}${m}`;
    }
  });
  return result;
}

export function filterNumber(str: string) {
  return String(str).replace(/\D/gim, '');
}

export function validateFile(file: File, accept: string): string {
  if (!accept) {
    return 'Não foi possível identificar o tipo de arquivo';
  }
  const typeRegex = new RegExp(accept.replace(/, /g, '|'));
  let message = '';

  if (!typeRegex.test(file.type)) {
    message = `O ${file.name} não é permitido`;
  }

  if (!(file.size <= 20 * 1024 * 1024)) {
    message = `O ${file.name} ultrapassa 20MB de capacidade.`;
  }
  return message;
}

export function sortPerDate(dateA: Date, dateB: Date): number {
  return dateB.getTime() - dateA.getTime();
}

export function validatePhone(phone: string): boolean {
  if (!phone) {
    return false;
  }

  const phoneDig = filterNumber(phone);
  if (phoneDig.substring(0, 1) === '0') {
    return false;
  }
  if (
    Number(phoneDig.substring(3, 2)) < 2 ||
    Number(phoneDig.substring(3, 2)) === 9
  ) {
    return false;
  }

  const exp = /^\(\d{2}\) \d{4,5}-\d{3,4}$/gi;
  return exp.test(phone);
}

export function validatePhoneCell(str: string): boolean {
  if (!str) {
    return false;
  }
  const phone = filterNumber(str);

  if (phone.substring(3, 2) !== '9') {
    return false;
  }

  const exp = '^((1[1-9])|([2-9][0-9]))((9[0-9]{3}[0-9]{5}))$';
  const regex = new RegExp(exp);
  return regex.test(phone);
}

export function getRedeDorBrand(): string {
  return `Rede D’Or`;
}

export function isProductionEnv() {
  return (
    process.env.REACT_APP_ENV !== 'develop' &&
    process.env.REACT_APP_ENV !== 'homolog'
  );
}

export function handleChatButton() {
  if (typeof window.niceChat.open === 'function') {
    window.niceChat.open();
  }
  /* const isDevelop = !isProductionEnv();
  if (isDevelop) {
    if (typeof window.niceChat.open === 'function') {
      window.niceChat.open();
    }
  } else if (window.fcWidget) {
    window.fcWidget.open();
  } */
}

export function showChatButton() {
  setTimeout(() => {
    const chatButton: any = document.getElementById('chatButtonFooter');
    if (
      window.niceChat &&
      typeof window.niceChat.open === 'function' &&
      chatButton
    ) {
      chatButton.style.display = 'flex';
    }
  }, 500);
}

export function calculateAge(birthDate: Date): number {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function dateDiff(startingDate: string, endingDate: string) {
  let startDate = new Date(
    new Date(startingDate).toISOString().substring(0, 10),
  );
  if (!endingDate) {
    endingDate = new Date().toISOString().substring(0, 10); // need date in YYYY-MM-DD format
  }
  let endDate = new Date(endingDate);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();
  const february =
    (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
      ? 29
      : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let yearDiff = endDate.getFullYear() - startYear;
  let monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  let dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  return { years: yearDiff, months: monthDiff, days: dayDiff };
}

export function validatePastDate(date: string): boolean {
  const exp = '^([0-9]{2}/[0-9]{2}/[0-9]{4})$';
  const regex = new RegExp(exp);
  if (!regex.test(date)) {
    return false;
  }

  const currentDate = new Date();
  const cDay = currentDate.getDate();
  const cMonth = currentDate.getMonth() + 1; // Mês começa em 0
  const cYear = currentDate.getFullYear();

  const parts = date.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  if (year < 1900 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  if (
    cYear < year ||
    (cYear === year && cMonth < month) ||
    (cYear === year && cMonth === month && cDay < day)
  ) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Ano Bissexto
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }
  return day > 0 && day <= monthLength[month - 1];
}

export function validateCpf(cpf: string): boolean {
  if (!cpf) {
    return false;
  }
  const currentCpf = filterNumber(cpf);

  if (currentCpf === null) {
    return false;
  }
  if (currentCpf.length !== 11) {
    return false;
  }
  if (
    currentCpf === '00000000000' ||
    currentCpf === '11111111111' ||
    currentCpf === '22222222222' ||
    currentCpf === '33333333333' ||
    currentCpf === '44444444444' ||
    currentCpf === '55555555555' ||
    currentCpf === '66666666666' ||
    currentCpf === '77777777777' ||
    currentCpf === '88888888888' ||
    currentCpf === '99999999999'
  ) {
    return false;
  }
  let numero = 0;
  let caracter = '';
  const numeros = '0123456789';
  let j = 10;
  let somatorio = 0;
  let resto = 0;
  let digito1 = 0;
  let digito2 = 0;
  let cpfAux = '';
  cpfAux = currentCpf.substring(0, 9);
  for (let i = 0; i < 9; i += 1) {
    caracter = cpfAux.charAt(i);
    if (numeros.search(caracter) === -1) {
      return false;
    }
    numero = Number(caracter);
    somatorio += numero * j;
    j -= 1;
  }
  resto = somatorio % 11;
  digito1 = 11 - resto;
  if (digito1 > 9) {
    digito1 = 0;
  }
  j = 11;
  somatorio = 0;
  cpfAux += digito1;
  for (let i = 0; i < 10; i += 1) {
    caracter = cpfAux.charAt(i);
    numero = Number(caracter);
    somatorio += numero * j;
    j -= 1;
  }
  resto = somatorio % 11;
  digito2 = 11 - resto;
  if (digito2 > 9) {
    digito2 = 0;
  }
  cpfAux += digito2;
  if (currentCpf !== cpfAux) {
    return false;
  }

  return true;
}

export function validateEmail(email: string): boolean {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email) === true;
}

export function toUpperCaseFirst(str: string) {
  if (str.length > 1) {
    return str[0].toUpperCase() + str.substring(1).toLowerCase();
  }
  return str;
}

export function yearsDiff(date: string): number {
  const [day, month, year] = date.split('/').map(Number);

  const presentDate = new Date();
  const presentYear = presentDate.getFullYear();
  const presentMonth = presentDate.getMonth() + 1;
  const presentDay = presentDate.getDate();

  let yearsDiff = presentYear - year;

  // Verifica se a data fornecida ainda não ocorreu neste ano
  if (month > presentMonth || (month === presentMonth && day > presentDay)) {
    yearsDiff--;
  }

  return yearsDiff;
}
