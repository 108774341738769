import { ITrackChangeError, ITrackChangeTypes } from './types';

export const trackChangeRequest = ({
  called,
  description,
  file,
  handleClickNextStep,
}: {
  called: string;
  description: string | undefined;
  file: File[];
  handleClickNextStep: () => void;
}) => ({
  type: ITrackChangeTypes.TRACK_CHANGE_REQUEST,
  payload: {
    called,
    description,
    file,
    handleClickNextStep,
  },
});

export const trackChangeSuccess = () => ({
  type: ITrackChangeTypes.TRACK_CHANGE_SUCCESS,
});

export const trackChangeFailure = (error: ITrackChangeError) => ({
  type: ITrackChangeTypes.TRACK_CHANGE_FAILURE,
  payload: {
    error,
  },
});
