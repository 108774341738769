/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IDependentsState, DependentsTypes } from './types';

const INITIAL_STATE: IDependentsState = {
  dataFamilySalary: [],
  dataIRPF: [],
  loading: false,
  error: false,
};

const dependents: Reducer<IDependentsState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case DependentsTypes.LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST: {
        draft.loading = true;
        break;
      }
      case DependentsTypes.LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_SUCCESS: {
        draft.dataFamilySalary = action.payload.data;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case DependentsTypes.LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case DependentsTypes.LOAD_DEPENDENTS_IRPF_REQUEST: {
        draft.loading = true;
        break;
      }
      case DependentsTypes.LOAD_DEPENDENTS_IRPF_REQUEST_SUCCESS: {
        draft.dataIRPF = action.payload.data;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case DependentsTypes.LOAD_DEPENDENTS_IRPF_REQUEST_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
        break;
    }
  });
};

export default dependents;
