import { IVacation } from 'models/vacation';
import { VacationTypes } from './types';

export const loadVacationRequest = () => ({
  type: VacationTypes.LOAD_VACATION_REQUEST,
});

export const loadVacationSuccess = (data: IVacation[]) => ({
  type: VacationTypes.LOAD_VACATION_SUCCESS,
  payload: {
    data,
  },
});

export const loadVacationFailure = () => ({
  type: VacationTypes.LOAD_VACATION_FAILURE,
});

export const printVacationRequest = ({
  competence,
}: {
  competence?: {
    ano?: string;
    mes?: string;
  }[];
}) => ({
  type: VacationTypes.PRINT_VACATION_REQUEST,
  payload: {
    competence,
  },
});

export const printVacationSuccess = () => ({
  type: VacationTypes.PRINT_VACATION_SUCCESS,
});

export const printVacationFailure = () => ({
  type: VacationTypes.PRINT_VACATION_FAILURE,
});
