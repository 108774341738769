/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum ITrackChangeTypes {
  TRACK_CHANGE_REQUEST = '@request/TRACK_CHANGE_REQUEST',
  TRACK_CHANGE_SUCCESS = '@request/TRACK_CHANGE_SUCCESS',
  TRACK_CHANGE_FAILURE = '@request/TRACK_CHANGE_FAILURE',
}

/**
 * State Types
 */
export interface ITrackChangeData {
  called: string;
  description: string;
  file?: File[];
}

export interface ITrackChangeError {
  status?: number;
  message?: string;
  success?: boolean;
}

export interface ITrackChangeState {
  readonly data: ITrackChangeData;
  readonly loading: boolean;
  readonly error: ITrackChangeError | null;
}
