/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IDentalDependent } from 'models/request/dental/dependent';

/**
 * Actions Types
 */
export enum IDentalDependentTypes {
  ADD_DEPENDENT_DENTAL_SET_DATA = '@request/ADD_DEPENDENT_DENTAL_SET_DATA',
  ADD_DEPENDENT_DENTAL_REQUEST = '@request/ADD_DEPENDENT_DENTAL_REQUEST',
  ADD_DEPENDENT_DENTAL_SUCCESS = '@request/ADD_DEPENDENT_DENTAL_SUCCESS',
  ADD_DEPENDENT_DENTAL_FAILURE = '@request/ADD_DEPENDENT_DENTAL_FAILURE',
  ADD_DEPENDENT_DENTAL_RESET = '@request/ADD_DEPENDENT_DENTAL_RESET',
}

/**
 * State Types
 */

export interface IDentalData {
  optionId: string;
  dateEvent?: string;
  current?: IDentalDependent;
  dependents: IDentalDependent[];
}

export interface IDentalError {
  status?: number;
  message?: string;
}

export interface IDentalDependentState {
  readonly data: IDentalData;
  readonly loading: boolean;
  readonly error: IDentalError | null;
}
