import React, { ButtonHTMLAttributes } from 'react';
import ButtonComponent from './styles';

import { ButtonAlign, ButtonSizes } from './Enum';

// buttonSizes não sera utilizado
export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  size?: ButtonSizes;
  color?: string;
  align?: ButtonAlign;
  disabled?: boolean;
  transparent?: boolean;
}

const Button: React.FC<IButton> = ({
  id,
  size = ButtonSizes.DEFAULT,
  color,
  align = ButtonAlign.CENTER,
  children,
  ...rest
}) => {
  let idElem: string | undefined = id;
  if (!idElem) {
    idElem = children?.toString().toUpperCase().includes('PROSSEGUIR')
      ? 'nextButton'
      : undefined;
  }

  return (
    <ButtonComponent
      id={idElem}
      className={
        children?.toString().toUpperCase().includes('PROSSEGUIR')
          ? 'nextButton'
          : ''
      }
      color={color}
      size={size}
      align={align}
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
};

export default Button;
