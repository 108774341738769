/* eslint-disable array-callback-return */
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { IAnnouncements, IAnnouncementsEnvelope } from 'models/announcements';

import wordpressApi from 'services/api/wordpress';

import { IState } from 'store';

import { AnnouncementsTypes } from './types';

import {
  loadAnnouncementsRequest,
  loadAnnouncementsFailure,
  loadAnnouncementsSuccess,
} from './actions';

type ILoadAnnouncements = ReturnType<typeof loadAnnouncementsRequest>;

export function* loadAnnouncements({ payload }: ILoadAnnouncements) {
  try {
    const { page, id } = payload;

    let announcements: IAnnouncements[] = [];

    const getDataAnnouncements = (state: IState) => state.announcements.data;
    const dataAnnouncements: IAnnouncements[] = yield select(
      getDataAnnouncements,
    );

    const getCdFilial = (state: IState) => state.user.current?.cdFilial;
    const cdFilial: string = yield select(getCdFilial);

    const getNmRegional = (state: IState) =>
      state.user.current?.nmRegional.toLowerCase();
    const nmRegional: string = yield select(getNmRegional);

    const announcementsHref =
      page === 1
        ? `/comunicados?order_by=date&categoria_segmentacao_slug=${nmRegional},${cdFilial}&_embed=1&per_page=5&page=${page}&_envelope=1`
        : `/comunicados?order_by=date&categoria_segmentacao_slug=${nmRegional},${cdFilial}&_embed=1&per_page=3&page=${
            page - 1
          }&exclude=${id}&_envelope=1`;

    const { data }: AxiosResponse<IAnnouncementsEnvelope> = yield call(
      wordpressApi.get,
      announcementsHref,
    );

    const { body, headers } = data;
    let stringIdConcat = '';

    if (page === 1) {
      body.map((element) => {
        element.tag_segmentacao.map((tag) => {
          if (tag === 61) {
            announcements = [...announcements, element];
          }
        });
      });

      body.map((element) => {
        if (element.tag_segmentacao.length === 0) {
          announcements = [...announcements, element];
        }
      });

      announcements.map((element) => {
        stringIdConcat += `${element.id},`;
      });
    } else {
      announcements = body;
    }

    const newAnnouncements = dataAnnouncements.concat(announcements);
    const idAnnouncements = id || stringIdConcat;

    yield put(
      loadAnnouncementsSuccess(
        newAnnouncements,
        headers['X-WP-TotalPages'],
        page,
        idAnnouncements,
      ),
    );
  } catch (err) {
    yield put(loadAnnouncementsFailure());
  }
}

export default all([
  takeLatest(AnnouncementsTypes.LOAD_ANNOUNCEMENTS_REQUEST, loadAnnouncements),
]);
