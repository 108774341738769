import { all, takeLatest, put, call } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import api from 'services/api';
import { ITrackGetTypes, ITrackGetData } from './types';

import { trackGetRequest, trackGetFailure, trackGetSuccess } from './actions';

type ITrackGetType = ReturnType<typeof trackGetRequest>;

export function* tracksGetRequest({ payload }: ITrackGetType) {
  try {
    const { currentPage } = payload;

    const { data }: AxiosResponse<ITrackGetData> = yield call(
      api.get,
      `/portal-conecta/buscar-chamados/${currentPage}`,
    );

    yield put(trackGetSuccess(data));
  } catch (error:any) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };

      yield put(trackGetFailure(response));
      return;
    }

    yield put(
      trackGetFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

export default all([
  takeLatest(ITrackGetTypes.TRACK_GET_REQUEST, tracksGetRequest),
]);
