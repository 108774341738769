import { IDentalData, IDentalError, IDentalDependentTypes } from './types';

export const dentalAddDependentSetData = (data: IDentalData) => ({
  type: IDentalDependentTypes.ADD_DEPENDENT_DENTAL_SET_DATA,
  payload: {
    data,
  },
});

export const dentalAddDependentRequest = ({
  handleClickNextStep,
}: {
  handleClickNextStep: () => void;
}) => ({
  type: IDentalDependentTypes.ADD_DEPENDENT_DENTAL_REQUEST,
  payload: {
    handleClickNextStep,
  },
});

export const dentalAddDependentSuccess = () => ({
  type: IDentalDependentTypes.ADD_DEPENDENT_DENTAL_SUCCESS,
});

export const dentalAddDependentFailure = (error: IDentalError) => ({
  type: IDentalDependentTypes.ADD_DEPENDENT_DENTAL_FAILURE,
  payload: {
    error,
  },
});

export const dentalAddDependentReset = () => ({
  type: IDentalDependentTypes.ADD_DEPENDENT_DENTAL_RESET,
});
