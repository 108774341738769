import { IInssRequestResponse } from 'models/request/inss/request';
import { InssRequestTypes } from './types';

export const inssRequestRequest = ({
  matricula,
  periodoCompetencia,
  handleClickNextStep,
}: {
  matricula: string;
  periodoCompetencia: string;
  handleClickNextStep: () => void;
}) => ({
  type: InssRequestTypes.INSS_REQUEST_REQUEST,
  payload: {
    matricula,
    periodoCompetencia,
    handleClickNextStep,
  },
});

export const inssRequestSuccess = (response: IInssRequestResponse) => ({
  type: InssRequestTypes.INSS_REQUEST_SUCCESS,
  payload: {
    response,
  },
});

export const inssRequestFailure = () => ({
  type: InssRequestTypes.INSS_REQUEST_FAILURE,
});

export const inssRequestReset = () => ({
  type: InssRequestTypes.INSS_REQUEST_RESET,
});
