/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';

import { MAX_WIDTH_TABLET } from 'utils/constants';

export interface IText {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'p';
  color?: string;
  htmlFor?: string | undefined;
  align?: 'left' | 'center' | 'right';
  fontWeight?: '100' | '300' | '400' | '500' | '700' | '900';
  cursor?: string|null;
}

export interface ISpan {
  type: 'caption' | 'disclaimer';
  color?: string;
  htmlFor?: string | undefined;
  align?: 'left' | 'center' | 'right';
  fontWeight?: '100' | '300' | '400' | '500' | '700' | '900';
  cursor?: string;
}

// Primeiro item do array define o tamanho para web
// Segundo item do array define o tamanho para mobile
const config = {
  h1: {
    fontSize: [32, 24],
    fontWeight: [300, 300],
  },
  h2: {
    fontSize: [24, 18],
    fontWeight: [300, 300],
  },
  h3: {
    fontSize: [18, 16],
    fontWeight: [300, 400],
  },
  h4: {
    fontSize: [16, 14],
    fontWeight: [500, 500],
  },
  p: {
    fontSize: [16, 14],
    fontWeight: [400, 400],
  },
};

export const TextComponent = styled.span<IText>`
  font-family: 'Roboto', sans-serif;
  position: relative;
  width: fit-content;
  text-align: ${({ align }) => align};
  color: ${({ theme, color }) => color || theme.colors.textPrimary};
  cursor: ${({ cursor }) => cursor || 'default'};
  font-size: ${({ as }) => config[as].fontSize[0]}px;
  font-weight: ${({ as, fontWeight }) =>
    fontWeight || config[as].fontWeight[0]};

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    font-size: ${({ as }) => config[as].fontSize[1]}px;
    font-weight: ${({ as, fontWeight }) =>
    fontWeight || config[as].fontWeight[1]};
  }
`;

export const SpanComponent = styled.span<ISpan>`
  font-family: 'Roboto', sans-serif;
  position: relative;
  width: fit-content;
  text-align: ${({ align }) => align};
  color: ${({ theme, color }) => color || theme.colors.textPrimary};
  cursor: ${({ cursor }) => cursor || 'default'};
  font-size: ${({ type }) => (type === 'caption' ? 12 : 13)}px;
  font-weight: ${({ type }) => (type === 'caption' ? 300 : 400)};

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    font-size: ${({ type }) => (type === 'caption' ? 10 : 12)}px;
    font-weight: ${({ type }) => (type === 'caption' ? 400 : 400)};
  }
`;
