import { IAnnualIncome } from 'models/paycheck/annualIncome';
import { AnnualIncomeTypes } from './types';

export const loadAnnualIncomeRequest = () => ({
  type: AnnualIncomeTypes.LOAD_ANNUAL_INCOME_REQUEST,
});

export const loadAnnualIncomeSuccess = (data: IAnnualIncome[]) => ({
  type: AnnualIncomeTypes.LOAD_ANNUAL_INCOME_SUCCESS,
  payload: {
    data,
  },
});

export const loadAnnualIncomeFailure = () => ({
  type: AnnualIncomeTypes.LOAD_ANNUAL_INCOME_FAILURE,
});

export const printAnnualIncomeRequest = (items: [{
  CNPJPagador: string;
  anoBase: number;
}]) => ({
  type: AnnualIncomeTypes.PRINT_ANNUAL_INCOME_REQUEST,
  payload: items,
});

export const printAnnualIncomeSuccess = () => ({
  type: AnnualIncomeTypes.PRINT_ANNUAL_INCOME_SUCCESS,
});

export const printAnnualIncomeFailure = () => ({
  type: AnnualIncomeTypes.PRINT_ANNUAL_INCOME_FAILURE,
});

