import { AxiosResponse } from 'axios';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { format } from 'date-fns';
import { MONTHS } from 'utils/constants';

import {
  IPayment,
  IPaymentRequest,
  IPrintPaymentRequest,
} from 'models/paycheck/payment';

import api from 'services/api';
import { sortPerDate } from 'utils/general';

import { IState } from 'store';

import {
  loadPaymentSuccess,
  loadPaymentFailure,
  printPaymentRequest,
  printPaymentSuccess,
  printPaymentFailure,
} from './actions';

import { PaymentTypes } from './types';

type printPaymentRequestType = ReturnType<typeof printPaymentRequest>;

function defineTitle(tipo: string): string {
  switch (tipo) {
    case '131':
      return '13º salário (parcela 1)';
    case '132':
      return '13º salário (parcela 2)';
    default:
      return 'Folha Salarial';
  }
}

function sortPaymentData(newData: IPayment[]): IPayment[] {
  const sortedData = newData.slice().sort((itemA, itemB) => {
    const dateA = new Date(
      `${itemA.anoCompetencia}-${itemA.mesCompetencia}-05`,
    );
    const dateB = new Date(
      `${itemB.anoCompetencia}-${itemB.mesCompetencia}-05`,
    );

    return sortPerDate(dateA, dateB);
  });

  return sortedData;
}

export function* loadPayment() {
  try {
    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getMatricula);

    const { data }: AxiosResponse<IPaymentRequest[]> = yield call(
      api.get,
      `/demonstrativo-pagamento/${inscription}`,
    );

    const newData: IPayment[] = data.map((payment) => ({
      ...payment,
      id:
        payment.mesCompetencia && payment.anoCompetencia && payment.tipo
          ? `${payment.mesCompetencia}/${payment.anoCompetencia} - ${payment.tipo}`
          : '-',
      title: payment.tipo
        ? defineTitle(String(payment.tipo))
        : 'Folha Salarial - Dados inconsistentes',
      subTitle:
        payment.mesCompetencia && payment.anoCompetencia
          ? `${MONTHS[Number(payment.mesCompetencia) - 1]} ${
              payment.anoCompetencia
            }`
          : '-',
    }));

    yield put(loadPaymentSuccess(sortPaymentData(newData)));
  } catch (err:any) {
    if (!!err && !!err?.response && err.response?.status === 404) {
      yield put(loadPaymentSuccess([]));

      return;
    }

    yield put(loadPaymentFailure());
  }
}

export function* printPayment({ payload }: printPaymentRequestType) {
  try {
    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getMatricula);

    const { competence } = payload;

    const { data }: AxiosResponse<IPrintPaymentRequest> = yield call(
      api.post,
      '/demonstrativo-pdf',
      {
        cdMatricula: inscription,
        competencia: competence,
      },
    );

    const { encodedFile, message } = data;

    let fileName: string;

    if (message && message.toLowerCase().includes('erro')) {
      throw message;
    }

    const dataLayer = window.dataLayer || [];

    const currentDate = format(new Date(), 'ddMMyy');
    const currentTime = format(new Date(), 'hhmmss');

    if (competence && competence?.length > 1) {
      // Many files
      const lastCompetence = competence[0];
      const firstCompetence = competence.pop();

      fileName = `Demonstrativo de Pagamento - ${inscription} - ${firstCompetence?.subTitle} - ${lastCompetence?.subTitle} - ${currentDate}${currentTime}.pdf`;

      dataLayer.push({
        event: 'multDownloadPayments',
      });
    } else {
      // Only one file

      fileName = `Demonstrativo de Pagamento - ${inscription} - ${
        competence && competence[0].subTitle
      } - ${currentDate}${currentTime}.pdf`;
      dataLayer.push({
        event: 'downloadPayments',
        file: fileName, // Passar nome do arquivo baixado
      });
    }

    const downloadLink = document.createElement('a');

    downloadLink.href = encodedFile;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);

    downloadLink.click();
    document.body.removeChild(downloadLink);

    yield put(printPaymentSuccess());
  } catch (err) {
    // TODO Remover quando o UX fizer uma tela para cenário de tratativa de erro
    // eslint-disable-next-line no-console
    console.log('Erro: ', err);
    yield put(printPaymentFailure());
  }
}

export default all([
  takeLatest(PaymentTypes.LOAD_PAYMENT_REQUEST, loadPayment),
  takeLatest(PaymentTypes.PRINT_PAYMENT_REQUEST, printPayment),
]);
