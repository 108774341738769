/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-delete-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from 'store';
import { IUserState } from 'store/modules/user/types';
import { IChatState } from 'store/modules/chat/types';
import { IUpdateAvailableState } from 'store/modules/updateAvailable/types';
import { INotificationState } from 'store/modules/notification/types';
import {
  getAllNotificationRequest,
  getNotificationAlertRequest,
} from 'store/modules/notification/actions';

import { IHeader, ILink } from 'models/header';
import { IMenu } from 'models/menu';
import theme from 'styles/theme';

import MainHeader from 'shared/Header/MainHeader';
import Menu from 'shared/Menu';
import FloatingChatButton from 'shared/FloatingChatButton';
import UpdateAvailable from 'shared/UpdateAvailable';

import { handleChatButton } from 'utils/general';
import {
  URL_VACANCIES,
  URL_PORTAL_TO_KNOW,
  URL_PARTNERSHIP_CLUB,
  URL_MANAGENT_PROGRAM_AVAILABLE,
} from 'utils/constants';

import { logoutRequest } from 'store/modules/auth/actions';
import { loadAnnouncementsReset } from 'store/modules/announcements/actions';

import ContentNotification from 'shared/ContentNotification';
import { Container, Wrapper } from './styles';

interface DefaultProps {
  children?: React.ReactNode | null;
}

interface NiceChatrops {
  open(): void;
  close(): void;
  isOpen: boolean;
  hidePreSurvey(): void;
}

declare global {
  // eslint-disable-next-line
  interface Window {
    process: any;
    fcWidget: any;
    dataLayer: any;
    brandembassy: any;
    niceChat: NiceChatrops;
  }
}

const Default: React.FC<DefaultProps> = ({ children }) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const [alertNotification, setAlertNotification] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const isMobile: boolean = width <= 1024;

  const { update, downloadUpdates } = useSelector<
    IState,
    IUpdateAvailableState
  >((state) => state.updateAvailable);

  const { dataNotificationAlert } = useSelector<IState, INotificationState>(
    (state) => state.notification,
  );

  const { data, current } = useSelector<IState, IUserState>(
    (state) => state.user,
  );

  const { loaded } = useSelector<IState, IChatState>((state) => state.chat);

  const cdFilial = String(current?.cdFilial);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const dataLayer = window.dataLayer || [];

  const wrapperRef = useRef<HTMLDivElement>(null);

  function handleNotification() {
    setIsOpenNotification(!isOpenNotification);
    setAlertNotification(false);

    if (dataNotificationAlert && dataNotificationAlert.docs.length > 0) {
      const { createdAt } = dataNotificationAlert.docs[0];

      localStorage.setItem('rh-digital:createdAtNotification', createdAt);
    }

    if (data && data.length > 0) {
      const { cdCPF } = data[0].documentos;
      const cpfBase64 = window.btoa(cdCPF);

      localStorage.setItem('rh-digital:userBase64', cpfBase64);
    }

    dataLayer.push({
      event: 'notification',
      action: 'alertNotification',
    });
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  // Verifica se o codigo filial tem permissão para prosseguir
  const verifyManagementProgramAvailable = () => {
    const cdNotAllowed: any[] = ['01050002', '04G40006', '01310026'];
    return cdNotAllowed.findIndex((i: string) => i === cdFilial) < 0;
  };

  const links: ILink[] = [
    {
      id: 'paycheck',
      name: 'Demonstrativos',
      iconName: 'paycheck',
      onClick: () => history.push('/paycheck'),
      subLinks: [
        {
          id: 'payment',
          name: 'Pagamentos',
          onClick: () =>
            history.push({ pathname: '/paycheck', state: { tab: 'payment' } }),
        },
        {
          id: 'vacation',
          name: 'Férias',
          onClick: () =>
            history.push({ pathname: '/paycheck', state: { tab: 'vacation' } }),
        },
        {
          id: 'annual_income',
          name: 'Rendimentos Anuais',
          onClick: () =>
            history.push({
              pathname: '/paycheck',
              state: { tab: 'annual_income' },
            }),
        },
      ],
    },
    {
      id: 'benefits',
      name: 'Benefícios',
      iconName: 'benefits',
      onClick: () => history.push('/benefits'),
      subLinks: [
        {
          id: 'health',
          name: 'Plano de saúde',
          onClick: () =>
            history.push({ pathname: '/benefits', state: { tab: 'health' } }),
        },
        {
          id: 'dental',
          name: 'Plano odontológico',
          onClick: () =>
            history.push({ pathname: '/benefits', state: { tab: 'dental' } }),
        },
      ],
    },
    {
      id: 'time_sheet',
      name: 'Espelho de ponto',
      iconName: 'timeSheet',
      onClick: () => history.push('/time_sheet'),
    },
    {
      id: 'request',
      name: 'Solicitações',
      iconName: 'request',
      onClick: () => history.push('/request'),
    },
    {
      id: 'announcements',
      name: 'Comunicados',
      iconName: 'announcements',
      onClick: () => {
        // Caso esteja na url de comunicados, não reseta o estado
        location.pathname.split('/', 2)[1] !== 'announcements' &&
          dispatch(loadAnnouncementsReset());

        history.push('/announcements');
      },
    },
    {
      id: 'QuickAccess',
      name: 'Acessos Rápidos',
      iconName: 'quickAccess',
      onClick: () => {
        return null;
      },
      subLinks: [
        {
          id: 'partnershipClub',
          name: `Clube de Parcerias`,
          onClick: () => {
            window.open(URL_PARTNERSHIP_CLUB, '_blank');
          },
        },
        {
          id: 'linkManagentProgramAvailable',
          name: `Programa Gestão das Emoções`,
          isVisible: verifyManagementProgramAvailable(),
          onClick: () => {
            window.open(URL_MANAGENT_PROGRAM_AVAILABLE, '_blank');
          },
        },
        {
          id: 'vacancies',
          name: 'Vagas',
          onClick: () => {
            window.open(URL_VACANCIES, '_blank');
          },
        },
      ],
    },
  ];
  // logout

  const headerProps: IHeader = {
    links,
    buttons: [
      {
        id: 'portalToKnow',
        icon: 'iconPortalToKnow',
        size: '29',
        isMobile: true,
        onClick: () => {
          window.open(URL_PORTAL_TO_KNOW, '_blank');
        }
      },
      {
        id: 'chat',
        icon: 'chatCircle',
        color: theme.colors.white,
        size: '20',
        // CHAT NICE - AGUARDANDO LIBERACAO
        //disabled: loaded !== true,
        isMobile: true,
        onClick: () => handleChatButton(),
      },
      {
        id: 'faq',
        icon: 'helpCircle',
        color: theme.colors.white,
        size: '20',
        isMobile: true,
        onClick: () => history.push('/faq'),
      },
      {
        id: 'alertNotification',
        icon: 'alert',
        color: theme.colors.white,
        size: '20',
        isMobile: true,
        onClick: () => {
          handleNotification();
        },
      },
      {
        id: 'account_config',
        icon: 'profile',
        color: theme.colors.white,
        size: '23',
        isMobile: true,
        onClick: () =>
          history.push({
            pathname: '/account_config',
            state: { tab: 'account_config' },
          }),
      },
      {
        id: 'menu',
        icon: 'menu',
        color: theme.colors.white,
        size: '20',
        isMobile: true,
        onClick: () => setIsOpenMenu(!isOpenMenu),
      },
      {
        id: 'exit',
        icon: 'exit',
        color: theme.colors.white,
        size: '20',
        onClick: () => {
          localStorage.removeItem('rh-digital:openApplication');
          sessionStorage.removeItem('rh-digital:openApplication');
          window.location.reload();
          dispatch(logoutRequest());
        },
      },
    ],
    setIsOpenNotification,
    setAlertNotification,
  };

  const menuProps: IMenu = {
    links,
    isOpen: isOpenMenu,
    handleMenu: () => setIsOpenMenu(!isOpenMenu),
  };

  function handleIsCloseNotification() {
    setIsOpenNotification(false);
  }

  function handleGetNoficiationRequest() {
    setInterval(() => {
      setTimeout(() => {
        dispatch(getAllNotificationRequest());
      }, 1000);
      dispatch(getNotificationAlertRequest());
    }, 18000000);
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getAllNotificationRequest());
    }, 1000);
    dispatch(getNotificationAlertRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, window.location.href]);

  useEffect(() => {
    handleGetNoficiationRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {update && (
        <UpdateAvailable update={update} downloadUpdates={downloadUpdates} />
      )}
      <Container update={update} openNotification={isOpenNotification}>
        <MainHeader
          {...headerProps}
          isOpenNotification={isOpenNotification}
          setIsOpenNotification={setIsOpenNotification}
          alertNotification={alertNotification}
          setAlertNotification={setAlertNotification}
        />
        <Menu {...menuProps} />
        {isMobile && isOpenNotification ? (
          <ContentNotification
            handleIsCloseNotification={handleIsCloseNotification}
            data={dataNotificationAlert}
          />
        ) : (
          <>
            <Wrapper ref={wrapperRef}>{children}</Wrapper>
            <FloatingChatButton />
          </>
        )}
      </Container>
    </>
  );
};

export default Default;
