/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */

import { all, takeLatest, put, call, delay, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import {
  osName,
  osVersion,
  browserName,
  isMobile,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';

import api from 'services/api';
import history from 'services/history';

import { TOKEN_JWT_STORAGE } from 'utils/constants';

import { IDevice } from 'models/user/device';
import IConfigurationData from 'models/accountConfig/configuration';
import { version } from '../../../../package.json';

import { AuthTypes, IError } from './types';

import {
  sendTokenRequest,
  sendTokenSuccess,
  sendTokenFailure,
  checkTokenRequest,
  checkTokenSuccess,
  checkTokenFailure,
  validateToken,
  logoutSuccess,
  logoutFailure,
  logoutRequest,
  getDeviceInfoRequest,
  getDeviceInfoSuccess,
  getDeviceInfoFailure,
  sendPhoneUpdateRequest,
  setSendTokenMethod,
  TokenMethodType,
} from './actions';
import { getUserRequest } from '../user/actions';
import { INotification } from '../accountConfig/configuration/sagas';

type sendTokenRequestType = ReturnType<typeof sendTokenRequest>;
type checkTokenRequestType = ReturnType<typeof checkTokenRequest>;
type logoutRequestType = ReturnType<typeof logoutRequest>;
type getDeviceInfoRequestType = ReturnType<typeof getDeviceInfoRequest>;
type sendPhoneUpdateRequestType = ReturnType<typeof sendPhoneUpdateRequest>;

const isDevelop = process.env.REACT_APP_ENV === 'develop';

export function* sendToken({ payload }: sendTokenRequestType) {
  try {
    const { cpf, resendToken, method, handleNextStep, captcha } = payload;

    const cpfBase64 = window.btoa(cpf);

    const getMethod = () => {
      return method === 'email' ? { forceEmail: true } : { forceSms: true };
    };

    // Faz a validação do token do captch do google apenas se NÃO for um reenvio de token
    if (!resendToken) {
      yield call(
        api.post,
        '/google-recaptcha',
        isDevelop
          ? {}
          : {
              token: captcha,
            },
      );
    }

    const {
      data,
    }: AxiosResponse<{
      message: string;
      availableMethodsToSendToken: Array<{
        method: string;
        maskData: string;
        default: boolean;
      }>;
    }> = yield call(api.post, '/authentication/token', {
      cpf: cpfBase64,
      ...getMethod(),
    });

    const defaultMethod =
      method ??
      data.availableMethodsToSendToken.find((itemMethod) => itemMethod.default)
        ?.method;

    if (defaultMethod) {
      yield put(
        setSendTokenMethod({
          sendTokenMethod: defaultMethod as TokenMethodType,
        }),
      );
    }

    if (!resendToken) {
      handleNextStep();
    }

    const emailData = data.availableMethodsToSendToken.find(
      (item) => item.method === 'email',
    );
    const smsData = data.availableMethodsToSendToken.find(
      (item) => item.method === 'sms',
    );

    yield put(
      sendTokenSuccess({ email: emailData?.maskData, cel: smsData?.maskData }),
    );
  } catch (err: any) {
    if (err && err?.response) {
      let message = '';
      let hasPhone = true;
      let userExists = true;

      switch (String(err.response.data.message).toLowerCase()) {
        case 'usuário não possui celular cadastrado':
          hasPhone = false;
          message = '';
          break;
        case 'usuário não localizado':
          userExists = false;
          message = 'CPF válido, mas não localizamos seu cadastro';
          break;
        case 'usuário bloqueado':
          history.push('/blocked_access');
          break;
        case 'usuario bloqueado':
          history.push('/blocked_access');
          break;
        default:
          hasPhone = true;
          userExists = false;
          message = err.response.data.message;
      }

      const response: IError = {
        hasPhone,
        userExists,
        message,
      };

      yield put(sendTokenFailure(response));
      return;
    }

    let defaultMessage = 'Algo deu errado. Tente novamente mais tarde.';

    if (!navigator.onLine) {
      defaultMessage = 'Algo deu errado. Verifique sua conexão com a internet.';
    }

    yield put(
      sendTokenFailure({
        hasPhone: true,
        userExists: false,
        message: defaultMessage,
      }),
    );
  }
}

function* updateNotificationPreference(
  notification: INotification,
  cpf: string,
) {
  const formData = new FormData();
  formData.append('cdCPF', cpf);
  formData.append('nmUrlAvatar', '000000000');
  formData.append('notificacao', JSON.stringify(notification));

  yield call(api.put, '/preferencia-usuario', formData);
}

export function* checkToken({ payload }: checkTokenRequestType) {
  try {
    const { code } = payload;

    const getRememberMe = (state: { auth: { rememberMe: boolean } }) =>
      state.auth.rememberMe;
    const rememberMe: boolean = yield select(getRememberMe);

    const getCpf = (state: { auth: { cpf: string } }) => state.auth.cpf;
    const cpf: string = yield select(getCpf);
    const cpfBase64 = window.btoa(cpf);

    const {
      data,
    }: AxiosResponse<{
      _id: string;
      lastAccess: string;
      token: string;
      expiration: string;
      status: number;
      message: string;
    }> = yield call(api.post, '/authentication/validacao-sms', {
      token: code,
      cpf: cpfBase64,
      ip: '',
      device: isMobile ? `${mobileVendor} - ${mobileModel}` : 'Desktop',
      so: `${osName} ${osVersion}`,
      browser: browserName,
      localizacao: '',
      keepAccess: rememberMe,
      version,
    });

    if (data.status === 400) {
      throw new Error('Código inválido ou expirado. Tente novamente.');
    }

    api.defaults.headers.Authorization = data.token;

    //REMOVIDO TEMPORARIAMENTE, POIS A API FICOU FORA DO AR

    // // Consent
    // const hashUser = String(CryptoJS.MD5(`${cpf}_RH_DIGITAL`));

    // const { body = '[]' }: IResponseConsent = yield ApiGetSingleConsent(
    //   String(process.env.REACT_APP_BASE_URL_PRIVACY_TOOLS),
    //   hashUser,
    //   String(process.env.REACT_APP_HASH_CONSENT_PRIVACY_TOOLS),
    //   String(process.env.REACT_APP_TOKEN_PRIVACY_TOOLS),
    // );

    // const dataConsent: IConsent[] = JSON.parse(body);

    // const acceptedPrivacy = !!dataConsent.find(
    //   (item) =>
    //     item.hashTemplate ===
    //     process.env.REACT_APP_HASH_CONSENT_PRIVACY_TOOLS &&
    //     item.consent === true,
    // );

    //REMOVIDO TEMPORARIAMENTE, POIS A API FICOU FORA DO AR
    const acceptedPrivacy = true;

    yield put(validateToken());
    yield put(getUserRequest());

    sessionStorage.setItem('rh-digital:openApplication', 'true');

    yield delay(2000);

    // Se rememberMe estiver selecionado, salva tempo de expiração no localStorage
    if (rememberMe) {
      window.localStorage.setItem(TOKEN_JWT_STORAGE, data.token);
    } else {
      // Senão, limpa expiresAt do localStorage e salva em sessionStorage
      window.localStorage.removeItem(TOKEN_JWT_STORAGE);
      window.sessionStorage.setItem(TOKEN_JWT_STORAGE, data.token);
    }

    const getSendTokenMethod = (state: {
      auth: { sendTokenMethod: TokenMethodType };
    }) => state.auth.sendTokenMethod;
    const sendTokenMethod: TokenMethodType = yield select(getSendTokenMethod);

    const { data: preferenceData }: AxiosResponse<IConfigurationData> =
      yield call(api.get, `/preferencia-usuario`);

    const { sms, email, push } = preferenceData.notificacao;

    if (sendTokenMethod === 'sms' && !sms) {
      const notification = {
        push,
        email,
        sms: true,
      };

      yield call(updateNotificationPreference, notification, cpf);
    }

    if (sendTokenMethod === 'email' && !email) {
      const notification = {
        push,
        email: true,
        sms,
      };

      yield call(updateNotificationPreference, notification, cpf);
    }
    yield put(
      checkTokenSuccess({
        tokenJWT: data.token,
        idDevice: data._id,
        acceptedPrivacy,
      }),
    );
  } catch (err: any) {
    if (err && err?.response) {
      const response = {
        status: err.response?.data?.status || err.response?.status,
        message:
          err.response?.data?.message ||
          'Código inválido ou expirado. Tente novamente.',
      };

      yield put(checkTokenFailure(response));
    } else {
      yield put(
        checkTokenFailure({
          status: 500,
          message: err?.message,
        }),
      );
    }
  }
}

export function* logout({ payload }: logoutRequestType) {
  const { reason } = payload;

  const getSigned = (state: { auth: { signed: boolean } }) => state.auth.signed;
  const signed: boolean = yield select(getSigned);

  try {
    if (signed) {
      if (window.location.href.includes('check_access')) return;

      if (
        localStorage.getItem('rh-digital:openApplication') === 'true' &&
        window.location.href.includes('pesquisas') &&
        reason !== 'tokenExpired' &&
        reason !== 'blockedAccess'
      ) {
        return;
      }

      // Limpa LocalStorage
      for (const key in localStorage) {
        if (
          key.startsWith('_BE') ||
          key.startsWith('_BEChatWindow') ||
          key.startsWith('_BECustomerId') ||
          key.startsWith('_BEChatWindow_version') ||
          key.startsWith('_DFOVisitorLastActiveLoggedAt') ||
          key.match('popUpOpen')
        ) {
          localStorage.removeItem(key);
        }
      }

      yield put(logoutSuccess());

      window.localStorage.removeItem(TOKEN_JWT_STORAGE);
      window.sessionStorage.removeItem(TOKEN_JWT_STORAGE);

      switch (reason) {
        case 'tokenExpired':
          history.push('/session_expired');
          return;
        case 'blockedAccess':
          history.push('/blocked_access');
          return;
        default:
          return;
      }
    }
  } catch (err) {
    yield put(logoutFailure());
  }
}

const isBlockedUser = () =>
  new Promise((resolve) => {
    api
      .get('/status-usuario')
      .then((response) =>
        resolve(response.data.message === 'Usuário Bloqueado'),
      )
      .catch((error) => {
        if (error.response) {
          return resolve(error.response.data.message === 'Usuário Bloqueado');
        }
        return error;
      });
  });

export function* getDeviceInfo({ payload }: getDeviceInfoRequestType) {
  try {
    const { deviceId } = payload;

    const { data }: AxiosResponse<IDevice> = yield call(
      api.get,
      `/device-usuario-especifico/${deviceId}`,
    );

    const device: IDevice = {
      ...data,
      hourAccess: format(new Date(data.lastAccess), 'dd.MM.yyyy - HH:mm aaa', {
        locale: ptBR,
      }),
    };

    yield put(getDeviceInfoSuccess(device));
  } catch (error) {
    yield put(
      getDeviceInfoFailure('Não foi possível recuperar os dados deste device.'),
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* setToken({ payload }: any) {
  if (!payload) return;

  // Tenta buscar a data de expiração do localStorage
  let tokenJWT = window.localStorage.getItem(TOKEN_JWT_STORAGE);

  // SE não conseguir capturar do localStorage, tenta do sessionStorage
  if (!tokenJWT) {
    tokenJWT = window.sessionStorage.getItem(TOKEN_JWT_STORAGE);
  }

  // Se não retornou de nenhum dos dois, faz logout
  if (!tokenJWT) {
    yield put(logoutRequest());
    return;
  }

  api.defaults.headers.Authorization = tokenJWT;

  const getSigned = (state: { auth: { signed: boolean } }) => state.auth.signed;
  const signed: boolean = yield select(getSigned);

  if (signed) {
    const isBlocked: boolean = yield call(isBlockedUser);

    // Realiza logout do usuário bloqueado
    if (isBlocked) {
      yield put(logoutRequest('blockedAccess'));
    }
  }
}

export function* sendPhoneUpdate({ payload }: sendPhoneUpdateRequestType) {
  try {
    yield put({
      type: AuthTypes.PHONE_UPDATE_LOADING,
      payload: {
        loadingPhoneUpdate: true,
      },
    });

    const { cpf } = payload;
    const cpfBase64 = window.btoa(cpf);

    const {
      data,
    }: AxiosResponse<{
      message: string;
    }> = yield call(
      api.post,
      '/portal-conecta/atualizacao-cadastral/telefone/prelogin',
      {
        ...payload,
        cpf: cpfBase64,
      },
    );

    let celMessageSent = data.message.trim();

    const celStarts = celMessageSent.indexOf('(');
    celMessageSent = celMessageSent.substring(celStarts, celMessageSent.length);

    yield put(sendTokenSuccess({ cel: celMessageSent }));
  } catch (err: any) {
    if (err && err?.response) {
      let message = '';
      let hasPhone = true;
      let userExists = true;

      switch (String(err.response.data.message).toLowerCase()) {
        case 'usuário não possui celular cadastrado':
          hasPhone = false;
          message = '';
          break;
        case 'usuário não localizado':
          userExists = false;
          message = 'CPF válido, mas não localizamos seu cadastro';
          break;
        case 'usuário bloqueado':
          history.push('/blocked_access');
          break;
        case 'usuario bloqueado':
          history.push('/blocked_access');
          break;
        default:
          hasPhone = true;
          userExists = false;
          message = err.response.data.message;
      }

      const response: IError = {
        hasPhone,
        userExists,
        message,
      };

      yield put(sendTokenFailure(response));
      return;
    }

    let defaultMessage = 'Algo deu errado. Tente novamente mais tarde.';

    if (!navigator.onLine) {
      defaultMessage = 'Algo deu errado. Verifique sua conexão com a internet.';
    }

    yield put(
      sendTokenFailure({
        hasPhone: true,
        userExists: false,
        message: defaultMessage,
      }),
    );
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.SEND_TOKEN_REQUEST, sendToken),
  takeLatest(AuthTypes.CHECK_TOKEN_REQUEST, checkToken),
  takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
  takeLatest(AuthTypes.GET_DEVICE_INFO_REQUEST, getDeviceInfo),
  takeLatest(AuthTypes.PHONE_UPDATE_REQUEST, sendPhoneUpdate),
]);
