import React from 'react';

import Icon from 'shared/Icon';

import { Container, IconContent, Info } from './styles';

export interface IMessage {
  type?: 'success' | 'error' | 'warn' | 'info';
}

const Message: React.FC<IMessage> = ({ type = 'info', children }) => {
  function getIconName() {
    switch (type) {
      case 'success':
        return 'checkCircle';
      case 'error':
        return 'alertCircle';
      case 'warn':
        return 'alertTri';
      default:
        return 'info';
    }
  }

  return (
    <Container type={type}>
      <IconContent type={type}>
        <Icon name={getIconName()} width="32px" height="32px" />
      </IconContent>
      <Info>{children}</Info>
    </Container>
  );
};

export default Message;
