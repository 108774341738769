/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum VacationRequestTypes {
  VACATION_REQUEST_REQUEST = '@request/VACATION_REQUEST_REQUEST',
  VACATION_REQUEST_SUCCESS = '@request/VACATION_REQUEST_SUCCESS',
  VACATION_REQUEST_FAILURE = '@request/VACATION_REQUEST_FAILURE',
  VACATION_REQUEST_RESET = '@request/VACATION_REQUEST_RESET',
}

export interface IVacationResponse {
  cdFilial: string;
  cdMatricula: string;
  cdCPF: string;
  cdSolicitacao: string;
  dtSolicitacao: string;
  dtAtendimento: string;
  nmStatus: string;
  dtGozoInicial: string;
  dtGozoFinal: string;
  nrDiasGozo: string;
  boolAbono: boolean,
  boolAdiantamento13: boolean;
}

/**
 * State Types
 */
export interface IVacationRequestState {
  readonly data: IVacationResponse[];
  readonly loading: boolean;
  readonly error: boolean;
}
