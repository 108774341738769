import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from 'services/api';

import { IState } from 'store';

import INotificationData from 'models/notification';

import {
  getNotificationAlertRequest,
  getNotificationAlertSuccess,
  getNotificationAlertFailure,
  getAllNotificationRequest,
  getAllNotificationSuccess,
  getAllNotificationFailure,
  getNotificationRequest,
  getNotificationSuccess,
  getNotificationFailure,
  putNotificationOpen,
  putNotificationSuccess,
  putNotificationFailure,
} from './actions';

import { INotificationTypes } from './types';

export function* getNotificationAlert() {
  try {
    const getCdMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getCdMatricula);

    const { data }: AxiosResponse<INotificationData> = yield call(
      api.get,
      `/colaborador/notificacao`,
      {
        params: {
          cdMatricula: inscription,
          page: 1,
          limit: 6,
          sortField: 'createdAt',
          sort: -1,
        },
      },
    );

    yield put(getNotificationAlertSuccess(data));
  } catch (error) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };
      yield put(getNotificationAlertFailure(response));

      return;
    }

    yield put(
      getNotificationAlertFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

export function* getAllNotification() {
  try {
    const getCdMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getCdMatricula);

    const { data }: AxiosResponse<INotificationData> = yield call(
      api.get,
      `/colaborador/notificacao`,
      {
        params: {
          opened: false,
          cdMatricula: inscription,
          page: 1,
          limit: 0,
          sortField: 'createdAt',
          sort: -1,
        },
      },
    );

    const { totalDocs } = data;

    yield put(
      getAllNotificationSuccess({ numberClosedNotification: totalDocs }),
    );
  } catch (error) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };
      yield put(getAllNotificationFailure(response));

      return;
    }

    yield put(
      getAllNotificationFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

type IGetNotificationRequestType = ReturnType<typeof getNotificationRequest>;

export function* getNotification({ payload }: IGetNotificationRequestType) {
  try {
    const { page, limit } = payload;

    const getCdMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getCdMatricula);

    const { data }: AxiosResponse<INotificationData> = yield call(
      api.get,
      `/colaborador/notificacao`,
      {
        params: {
          cdMatricula: inscription,
          page,
          limit,
          sortField: 'createdAt',
          sort: -1,
        },
      },
    );

    yield put(getNotificationSuccess(data));
  } catch (error) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };
      yield put(getNotificationFailure(response));

      return;
    }

    yield put(
      getNotificationFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

type IPutNotificationOpen = ReturnType<typeof putNotificationOpen>;

export function* openNotification({ payload }: IPutNotificationOpen) {
  try {
    const { idNotification } = payload;

    yield call(api.put, `/colaborador/notificacao/${idNotification}/open`);

    yield put(getAllNotificationRequest());
    yield put(getNotificationAlertRequest());
    yield put(putNotificationSuccess());
  } catch (error) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };
      yield put(putNotificationFailure(response));

      return;
    }

    yield put(
      putNotificationFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

export default all([
  takeLatest(
    INotificationTypes.NOTIFICATION_ALERT_GET_REQUEST,
    getNotificationAlert,
  ),
  takeLatest(
    INotificationTypes.NOTIFICATION_ALL_GET_REQUEST,
    getAllNotification,
  ),
  takeLatest(INotificationTypes.NOTIFICATION_GET_REQUEST, getNotification),
  takeLatest(
    INotificationTypes.NOTIFICATION_PUT_OPEN_REQUEST,
    openNotification,
  ),
]);
