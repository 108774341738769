import { IDevice } from 'models/user/device';
import { TokenMethodType } from './actions';

/**
 * Actions Types
 */
export enum AuthTypes {
  CURRENT_STEP = '@auth/CURRENT_STEP',
  SEND_TOKEN_REQUEST = '@auth/SEND_TOKEN_REQUEST',
  SEND_TOKEN_SUCCESS = '@auth/SEND_TOKEN_SUCCESS',
  SEND_TOKEN_FAILURE = '@auth/SEND_TOKEN_FAILURE',
  CHECK_TOKEN_REQUEST = '@auth/CHECK_TOKEN_REQUEST',
  CHECK_TOKEN_SUCCESS = '@auth/CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILURE = '@auth/CHECK_TOKEN_FAILURE',
  CHECK_PRIVACY_REQUEST = '@auth/CHECK_PRIVACY_REQUEST',
  CHECK_PRIVACY_SUCCESS = '@auth/CHECK_PRIVACY_SUCCESS',
  CHECK_PRIVACY_FAILURE = '@auth/CHECK_PRIVACY_FAILURE',
  ADD_PRIVACY_REQUEST = '@auth/ADD_PRIVACY_REQUEST',
  ADD_PRIVACY_SUCCESS = '@auth/ADD_PRIVACY_SUCCESS',
  ADD_PRIVACY_FAILURE = '@auth/ADD_PRIVACY_FAILURE',
  LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = '@auth/LOGOUT_FAILURE',
  GET_DEVICE_INFO_REQUEST = '@auth/GET_DEVICE_INFO_REQUEST',
  GET_DEVICE_INFO_SUCCESS = '@auth/GET_DEVICE_INFO_SUCCESS',
  GET_DEVICE_INFO_FAILURE = '@auth/GET_DEVICE_INFO_FAILURE',
  SET_REMEMBER_ME = '@auth/SET_REMEMBER_ME',
  VALIDATE_TOKEN = '@auth/VALIDATE_TOKEN',
  RESET_ERROR = '@auth/RESET_ERROR',
  RESET_AUTH = '@auth/RESET_AUTH',
  PHONE_UPDATE = '@auth/PHONE_UPDATE',
  PHONE_UPDATE_SUPPORT = '@auth/PHONE_UPDATE_SUPPORT',
  PHONE_UPDATE_REQUEST = '@auth/PHONE_UPDATE_REQUEST',
  PHONE_UPDATE_SUCCESS = '@auth/PHONE_UPDATE_SUCCESS',
  PHONE_UPDATE_FAILURE = '@auth/PHONE_UPDATE_FAILURE',
  PHONE_UPDATE_LOADING = '@auth/PHONE_UPDATE_LOADING',
  SET_SEND_TOKEN_METHOD = '@auth/SET_SEND_TOKEN_METHOD',
}

export interface IError {
  hasPhone?: boolean;
  userExists?: boolean;
  status?: number;
  message: string;
}

/**
 * State Types
 */
export interface IAuthState {
  readonly currentStep: number;
  readonly cpf: string;
  readonly cel: string;
  readonly email: string;
  readonly tokenJWT: string;
  readonly idDevice: string;
  readonly rememberMe: boolean;
  readonly acceptedPrivacy: boolean;
  readonly signed: boolean;
  readonly tokenSubmitted: boolean;
  readonly validatedSMS: boolean;
  readonly addingPrivacy: boolean;
  readonly loadingToken: boolean;
  readonly loading: boolean;
  readonly device: IDevice | null;
  readonly error: IError | null;
  readonly isPhoneUpdate: boolean;
  readonly isPhoneUpdateSupport?: boolean;
  readonly loadingPhoneUpdate?: boolean;
  readonly phoneUpdateSuccess: boolean;
  readonly sendTokenMethod?: TokenMethodType;
}
