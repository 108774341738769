/* eslint-disable no-undef */
import styled, { DefaultTheme } from 'styled-components';

import { MAX_WIDTH_TABLET } from 'utils/constants';
import { IButton } from './index';
import { ButtonSizes } from './Enum';

function defineColorText(color?: string, theme?: DefaultTheme) {
  if (color === theme?.colors.tertiary) return theme?.colors.primary;

  return theme?.colors.white;
}

function defineHoverColor(color?: string, theme?: DefaultTheme) {
  switch (color) {
    case theme?.colors.primary:
      return theme?.colors.primaryHover;
    case theme?.colors.secondary:
      return theme?.colors.secondaryHover;
    case theme?.colors.tertiary:
      return theme?.colors.tertiaryHover;
    case theme?.colors.quaternary:
      return theme?.colors.quaternaryHover;
    default:
      return theme?.colors.primaryHover;
  }
}

const ButtonComponent = styled.button<IButton>`
  height: ${({ size }) => size || ButtonSizes.DEFAULT}px;
  width: 308px;
  border: 1px solid
    ${({ theme, disabled, color }) =>
      disabled ? theme.colors.disabled : color || theme.colors.primary};
  background: ${({ theme, transparent, color }) =>
    transparent ? 'transparent' : color || theme.colors.primary};
  border-radius: 4px;

  font-size: ${({ size }) => (size === ButtonSizes.SMALL ? 12 : 14)}px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  color: ${({ theme, transparent, color }) =>
    transparent
      ? color || theme.colors.primary
      : defineColorText(color, theme)};

  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align ?? 'flex-start'};
  align-self: center;
  transition: color 1s;
  transition: background 0.5s;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme, color }) => defineHoverColor(color, theme)};
    border: 1px solid ${({ theme, color }) => defineHoverColor(color, theme)};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.disabled};
    border: 1px solid ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    width: 100%;
  }
`;

export default ButtonComponent;
