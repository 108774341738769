import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getNotificationReset } from 'store/modules/notification/actions';

import Text from 'shared/Tipografia/Text';
import Icon from 'shared/Icon';
import ButtonLink from 'shared/Button/ButtonLink';
import CardContentNotification from 'shared/Card/CardContentNotification';

import INotificationData from 'models/notification';

import {
  Container,
  ContainerTitle,
  ButtonClose,
  Separator,
  Content,
  ContainerButton,
} from './styles';

interface IContentNotification {
  handleIsCloseNotification(): void;
  data: INotificationData | null;
}

const ContentNotification: React.FC<IContentNotification> = ({
  handleIsCloseNotification,
  data,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleRedirectNotification() {
    dispatch(getNotificationReset());

    history.push('/notification');
  }

  function handleOverFlowHidden() {
    const elementRoot = window.document.getElementById('root');

    if (window.innerWidth < 1025 && elementRoot && !!data && data.docs.length < 4) {
      elementRoot.style.overflow = 'hidden';
    }
  }

  function handleOverFlowUnset() {
    const elementRoot = window.document.getElementById('root');

    if (window.innerWidth < 1025 && elementRoot) {
      elementRoot.style.overflow = 'unset';
    }
  }

  useEffect(() => {
    handleOverFlowHidden();

    return () => {
      handleOverFlowUnset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container noNotification={!!data && data.docs.length > 0}>
      <ContainerTitle>
        <Text as="h3">Notificações</Text>

        <ButtonClose type="button" onClick={() => handleIsCloseNotification()}>
          <Icon name="close" width="18" height="18" />
        </ButtonClose>
      </ContainerTitle>
      <Separator />
      <Content numberNotifications={!!data && data?.docs.length > 4}>
        {data && data.docs.length > 0 ? (
          data.docs.map(
            ({ _id, type, subtype, month, year, createdAt, opened }) => (
              <CardContentNotification
                key={_id}
                id={_id}
                type={type}
                subtype={subtype}
                month={month}
                year={year}
                createdAt={createdAt}
                opened={opened}
              />
            ),
          )
        ) : (
          <CardContentNotification
            key="noNotifications"
            id="noNotifications"
            type="noNotifications"
          />
        )}
      </Content>
      {data && data.docs.length > 0 && (
        <>
          <Separator />
          <ContainerButton>
            <ButtonLink
              id="see-more-notifications"
              onClick={() => handleRedirectNotification()}
            >
              Ver mais
            </ButtonLink>
          </ContainerButton>
        </>
      )}
    </Container>
  );
};

export default ContentNotification;
