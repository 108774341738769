import { IDependentsRequest, IDependent } from 'models/dependents';
import { DependentsTypes } from './types';

export const getDependentsFamilySalaryRequest = (data: IDependentsRequest) => ({
  type: DependentsTypes.LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST,
  payload: { ...data,  salarioFamilia: true }
});

export const loadDependentsFamilySalaryRequestSuccess = (data: IDependent[]) => ({
  type: DependentsTypes.LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_SUCCESS,
  payload: {
    data,
  },
});
export const loadDependentsFamilySalaryRequestFailure = () => ({ type: DependentsTypes.LOAD_DEPENDENTS_FAMILY_SALARY_REQUEST_FAILURE });

export const getDependentsIRPFRequest = (data: IDependentsRequest) => ({
  type: DependentsTypes.LOAD_DEPENDENTS_IRPF_REQUEST,
  payload: { ...data, impostoRenda: true}
});

export const loadDependentsIRPFRequestSuccess = (data: IDependent[]) => ({
  type: DependentsTypes.LOAD_DEPENDENTS_IRPF_REQUEST_SUCCESS,
  payload: {
    data,
  },
});

export const loadDependentsIRPFRequestFailure = () => ({ type: DependentsTypes.LOAD_DEPENDENTS_IRPF_REQUEST_FAILURE });
