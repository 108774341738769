import { ITransportError, ICancelTransportTypes } from './types';

export const cancelTransportSetData = (file?: File) => ({
  type: ICancelTransportTypes.CANCEL_TRANSPORT_SET_DATA,
  payload: {
    file,
  },
});

export const cancelTransportRequest = (
  handleClickNextStep: () => void,
  type: 'Avaria' | 'Perda' | 'Roubo' | 'Outros' | '',
) => ({
  type: ICancelTransportTypes.CANCEL_TRANSPORT_REQUEST,
  payload: {
    type,
    handleClickNextStep,
  },
});

export const cancelTransportSuccess = () => ({
  type: ICancelTransportTypes.CANCEL_TRANSPORT_SUCCESS,
});

export const cancelTransportFailure = (error: ITransportError) => ({
  type: ICancelTransportTypes.CANCEL_TRANSPORT_FAILURE,
  payload: {
    error,
  },
});
