/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IFaq } from 'models/faq';

/**
 * Actions Types
 */
export enum FaqTypes {
  LOAD_FAQ_REQUEST = '@faq/LOAD_FAQ_REQUEST',
  LOAD_FAQ_SUCCESS = '@faq/LOAD_FAQ_SUCCESS',
  LOAD_FAQ_FAILURE = '@faq/LOAD_FAQ_FAILURE',
}

/**
 * State Types
 */
export interface IFaqState {
  readonly data: IFaq[];
  readonly loading: boolean;
  readonly error: boolean;
}
