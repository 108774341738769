/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';

import { MAX_WIDTH_TABLET, UP_HEADER_HEIGHT } from 'utils/constants';

export const Container = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: ${UP_HEADER_HEIGHT}px;
  padding-left: 70px;
  display: flex;
  align-items: center;
  background: linear-gradient(
      359.15deg,
      rgba(209, 206, 206, 0.5) -21.77%,
      rgba(209, 206, 206, 0) 40.6%
    ),
    linear-gradient(
      118.58deg,
      rgba(242, 242, 242, 0.96) 43.74%,
      rgba(242, 242, 242, 0) 96.41%
    ),
    url(.png), #f2f2f2;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    display: none;
  }
`;

export const DivBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }
`;

export const TextBack = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-left: 17.5px;

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    text-transform: uppercase;
  }
`;
