/* eslint-disable @typescript-eslint/no-explicit-any */

import { ChatTypes, ChatChannels } from './types';

export const setLoaded = (loaded:boolean) => ({
  type: ChatTypes.CHAT_LOADED,
  payload: {
    loaded
  },
});

export const setToken = (token:ChatChannels) => ({
  type: ChatTypes.CHAT_TOKEN,
  payload: {
    token
  },
});


export const setOpenWindow = (openWindow:boolean) => ({
  type: ChatTypes.CHAT_OPEN_WINDOW,
  payload: {
    openWindow
  },
});

