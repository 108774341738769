import { IPayment } from 'models/paycheck/payment';
import { PaymentTypes } from './types';

export const loadPaymentRequest = () => ({
  type: PaymentTypes.LOAD_PAYMENT_REQUEST,
});

export const loadPaymentSuccess = (data: IPayment[]) => ({
  type: PaymentTypes.LOAD_PAYMENT_SUCCESS,
  payload: {
    data,
  },
});

export const loadPaymentFailure = () => ({
  type: PaymentTypes.LOAD_PAYMENT_FAILURE,
});

export const printPaymentRequest = ({
  competence,
}: {
  competence?: {
    ano?: string;
    mes?: string;
    tipoFolha?: string;
    subTitle?: string;
  }[];
}) => ({
  type: PaymentTypes.PRINT_PAYMENT_REQUEST,
  payload: {
    competence,
  },
});

export const printPaymentSuccess = () => ({
  type: PaymentTypes.PRINT_PAYMENT_SUCCESS,
});

export const printPaymentFailure = () => ({
  type: PaymentTypes.PRINT_PAYMENT_FAILURE,
});
