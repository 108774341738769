/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IHealth } from 'models/benefits/health';

/**
 * Actions Types
 */
export enum HealthTypes {
  GET_HEALTH_REQUEST = '@health/GET_HEALTH_REQUEST',
  GET_HEALTH_SUCCESS = '@health/GET_HEALTH_SUCCESS',
  GET_HEALTH_FAILURE = '@health/GET_HEALTH_FAILURE',
  HEALTH_CHANGE_TAB = '@health/HEALTH_CHANGE_TAB',
}

/**
 * State Types
 */
export interface IHealthState {
  readonly data: IHealth | null;
  readonly haveInsurance: boolean | null;
  readonly disabled: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
