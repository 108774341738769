/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IEmploymentRequestState, EmploymentRequestTypes } from './types';

const INITIAL_STATE: IEmploymentRequestState = {
  loading: false,
  error: false,
};

const employmentRequest: Reducer<IEmploymentRequestState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case EmploymentRequestTypes.EMPLOYMENT_REQUEST_REQUEST: {
        draft.loading = true;
        break;
      }
      case EmploymentRequestTypes.EMPLOYMENT_REQUEST_SUCCESS: {
        draft.loading = false;
        draft.error = false;
        break;
      }
      case EmploymentRequestTypes.EMPLOYMENT_REQUEST_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case EmploymentRequestTypes.EMPLOYMENT_REQUEST_RESET: {
        draft.loading = false;
        draft.error = false;
        break;
      }
      default:
        break;
    }
  });
};

export default employmentRequest;
