import { InssSendTypes, IError } from './types';

export const inssSendRequest = ({
  matricula,
  periodoCompetencia,
  file,
  handleClickNextStep,
}: {
  matricula: string;
  periodoCompetencia: string;
  file: File;
  handleClickNextStep: () => void;
}) => ({
  type: InssSendTypes.INSS_SEND_REQUEST,
  payload: {
    matricula,
    periodoCompetencia,
    file,
    handleClickNextStep,
  },
});

export const inssSendSuccess = () => ({
  type: InssSendTypes.INSS_SEND_SUCCESS,
});

export const inssSendFailure = (error: IError) => ({
  type: InssSendTypes.INSS_SEND_FAILURE,
  payload: {
    error,
  },
});

export const inssSendReset = () => ({
  type: InssSendTypes.INSS_SEND_RESET,
});
