import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import api from 'services/api';
import { VacationRequestTypes, IVacationResponse } from './types';

import {
  vacationRequestFailure,
  vacationRequestRequest,
  vacationRequestSuccess,
} from './actions';

type vacationRequestType = ReturnType<typeof vacationRequestRequest>;

export function* vacationRequest({ payload }: vacationRequestType) {
  try {
    const { matricula } = payload;

    const { data }: AxiosResponse<IVacationResponse[]> = yield call(
      api.post,
      '/solicitacao-ferias',
      {
        matricula
      },
    );

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `VACATION - Solicitar Status de Férias`,
    });
    yield put(vacationRequestSuccess(data));
  } catch (err) {
    yield put(vacationRequestFailure());
  }
}

export default all([
  takeLatest(VacationRequestTypes.VACATION_REQUEST_REQUEST, vacationRequest),
]);
