/* eslint-disable no-await-in-loop */
import { all, takeLatest, put, call } from 'redux-saga/effects';

import api from 'services/api';

import { IAttachment, IDestinatary } from 'models/executive';

import { isProductionEnv } from 'utils/general';
import { IExecutiveTypes } from './types';

import {
  executiveRequest,
  executiveFailure,
  executiveSuccess,
} from './actions';

type IExecutiveType = ReturnType<typeof executiveRequest>;

const convertFileToBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(new Error('Falha na conversão do arquivo para base64'));
    };
  });

async function convertFiles(files: File[]) {
  const attachments: IAttachment[] = [];

  for (let i = 0; i < files.length; i += 1) {
    const fileBase64: unknown = await convertFileToBase64(files[i])
      .then((response) => {
        return response;
      })
      .catch((error) => {
        executiveFailure(error);
      });

    const newFIleBase64 = String(fileBase64).split(',', 2);

    if (fileBase64) {
      const newAttachment: IAttachment = {
        name: files[i].name,
        type: files[i].type,
        content: newFIleBase64[1],
      };

      attachments.push(newAttachment);
    }
  }

  return attachments;
}

export function* executivesRequest({ payload }: IExecutiveType) {
  try {
    const { message } = payload;
    const emailsTo: IDestinatary[] = isProductionEnv()
      ? [
          {
            email: 'eduardo.castro@rededor.com.br',
            name: 'Eduardo Castro',
            type: 'to',
          },
          {
            email: 'tatyana.natorf@rededor.com.br',
            name: 'Tatyana Nator',
            type: 'to',
          },
        ]
      : [
          {
            email: 'nathalia.nttdata@rededor.com.br',
            name: 'Nathalia Oliveira Silva',
            type: 'to',
          },
          {
            email: 'nathalia.oliveirasilva@emeal.nttdata.com',
            name: 'Nathalia Oliveira Silva',
            type: 'to',
          },
        ];

    let filesConverted: IAttachment[] = [];

    if (message?.attachments) {
      filesConverted = yield call(convertFiles, message.attachments);
    }

    yield call(api.post, '/email/enviar', {
      ...message,
      attachments: filesConverted && filesConverted,
      from_email: 'admin@rededor.com.br',
      to: emailsTo,
      headers: {
        'Reply-To': 'no-reply@rededor.com.br',
      },
      important: false,
      merge: true,
      merge_language: 'mailchimp',
      tags: ['contato'],
      subaccount: 'sites',
    });

    yield put(executiveSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(executiveFailure(response));
      return;
    }

    yield put(
      executiveFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  } finally {
    const { handleClickNextStep } = payload;
    handleClickNextStep();
  }
}

export default all([
  takeLatest(IExecutiveTypes.EXECUTIVE_REQUEST, executivesRequest),
]);
