/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import api from 'services/api';

import { IState } from 'store';
import { IDentalDependentState, IDentalDependentTypes } from './types';

import {
  dentalAddDependentRequest,
  dentalAddDependentSuccess,
  dentalAddDependentFailure,
} from './actions';

type IDentalAddDependentType = ReturnType<typeof dentalAddDependentRequest>;

export function* dentalAddDependent({ payload }: IDentalAddDependentType) {
  try {
    const getCpf = (state: IState) => state.auth.cpf;
    const cpf: string = yield select(getCpf);

    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const matricula: string = yield select(getMatricula);

    const getDental = (state: IState) => state.dentalDependent;
    const { data }: IDentalDependentState = yield select(getDental);

    const formData = new FormData();

    const dependents = [
      {
        nomeDependente: data.current?.name,
        rgDependente: data.current?.rg,
        cpfDependente: data.current?.cpf,
        maeDependente: data.current?.mother,
        dataEvento: data.dateEvent,
        dtNascDependente: data.current?.dateBorn,
        grauParentesco: data.current?.type,
        sexoDependente: data.current?.genre,
        estadoCivil: data.current?.status,
        cartaoNacionalSaude: data.current?.cns,
      },
    ];

    for (const dependent of data.dependents) {
      if(dependent.fileCertificate) {
        for (let i = 0; i < dependent.fileCertificate.length; i += 1) {
          formData.append('anexo', dependent.fileCertificate[i]);
        }
      }
      if (dependent?.fileCns) {
        formData.append('anexo', dependent.fileCns);
      }
      if (dependent?.fileCpf) {
        formData.append('anexo', dependent.fileCpf);
      }
      if (dependent?.fileRg) {
        formData.append('anexo', dependent.fileRg);
      }

      dependents.push({
        nomeDependente: dependent.name,
        rgDependente: dependent.rg,
        cpfDependente: dependent.cpf,
        maeDependente: dependent.mother,
        dataEvento: data.dateEvent,
        dtNascDependente: dependent.dateBorn,
        grauParentesco: dependent.type,
        sexoDependente: dependent.genre,
        estadoCivil: dependent.status,
        cartaoNacionalSaude: dependent.cns,
      });
    }

    if(data.current?.fileCertificate) {
      for (let i = 0; i < data.current?.fileCertificate.length; i += 1) {
        formData.append('anexo', data.current?.fileCertificate[i]);
      }
    }
    if (data.current?.fileCns) {
      formData.append('anexo', data.current.fileCns);
    }
    if (data.current?.fileCpf) {
      formData.append('anexo', data.current.fileCpf);
    }
    if (data.current?.fileRg) {
      formData.append('anexo', data.current.fileRg);
    }

    if(data.current?.fileOperator) {
      for (let i = 0; i < data.current?.fileOperator.length; i += 1) {
        formData.append('anexo', data.current?.fileOperator[i]);
      }
    }

    formData.append('cpf', cpf);
    formData.append('matricula', matricula);
    formData.append('motivo', data.optionId === 'Exceção' ? 'União Estável' : data.optionId);
    formData.append('tipoPlano', 'PO');
    formData.append('dependentes', JSON.stringify(dependents));

    yield call(api.post, '/portal-conecta/inclusao-dependentes', formData);

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `Dental - Adicionar Dependente - ${data.optionId === 'Exceção' ? 'União Estável' : data.optionId}`,
    });

    yield put(dentalAddDependentSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(dentalAddDependentFailure(response));
      return;
    }

    yield put(
      dentalAddDependentFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  } finally {
    const { handleClickNextStep } = payload;
    handleClickNextStep();
  }
}

export default all([
  takeLatest(IDentalDependentTypes.ADD_DEPENDENT_DENTAL_REQUEST, dentalAddDependent),
]);
