/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum ErrorNetworkTypes {
  ERROR_NETWORK = '@error/ERROR_NETWORK',
}

/**
 * State Types
 */
export interface IErrorNetworkState {
  readonly messageError?: any | null;
}
