/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import IConfigurationData from 'models/accountConfig/configuration';

/**
 * Actions Types
 */
export enum IConfigurationTypes {
  USER_CONFIGURATION_REQUEST = '@configuration/USER_CONFIGURATION_REQUEST',
  USER_CONFIGURATION_SUCCESS = '@configuration/USER_CONFIGURATION_SUCCESS',
  USER_CONFIGURATION_FAILURE = '@configuration/USER_CONFIGURATION_FAILURE',
  USER_CONFIGURATION_PUT_REQUEST = '@configuration/USER_CONFIGURATION_PUT_REQUEST',
  USER_CONFIGURATION_PUT_SUCCESS = '@configuration/USER_CONFIGURATION_PUT_SUCCESS',
  USER_CONFIGURATION_PUT_FAILURE = '@configuration/USER_CONFIGURATION_PUT_FAILURE',
}

/**
 * State Types
 */

export interface IConfigurationError {
  status?: number;
  message?: string;
}

export interface IConfigurationState {
  readonly data: IConfigurationData | null;
  readonly arrayNotification: string[];
  readonly loading: boolean;
  readonly error: IConfigurationError | null;
}
