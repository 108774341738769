/* eslint-disable no-console*/
/* eslint-disable no-unused-vars*/

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { Router, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import history from 'services/history';
import Routes from './routes/Routes';
import { store, persistor } from './store';
import GlobalStyle from './styles/global';
import theme from './styles/theme';

const tagManagerArgs = {
  gtmId: 'GTM-TRLP5FP',
};

TagManager.initialize(tagManagerArgs);

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {/*@ts-ignore*/}
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <GlobalStyle />
            <Routes />
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
