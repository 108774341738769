/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum InssSendTypes {
  INSS_SEND_REQUEST = '@request/INSS_SEND_REQUEST',
  INSS_SEND_SUCCESS = '@request/INSS_SEND_SUCCESS',
  INSS_SEND_FAILURE = '@request/INSS_SEND_FAILURE',
  INSS_SEND_RESET = '@request/INSS_SEND_RESET',
}

/**
 * State Types
 */

export interface IError {
  status?: number;
  message?: string;
}

export interface IInssSendState {
  readonly loading: boolean;
  readonly error: IError | null;
}
