/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IMessage } from 'models/executive';

/**
 * Actions Types
 */
export enum IExecutiveTypes {
  EXECUTIVE_REQUEST = '@request/EXECUTIVE_REQUEST',
  EXECUTIVE_SUCCESS = '@request/EXECUTIVE_SUCCESS',
  EXECUTIVE_FAILURE = '@request/EXECUTIVE_FAILURE',
}

/**
 * State Types
 */
export interface IExecutiveData {
  message?: IMessage | null;
}

export interface IExecutiveError {
  status?: number;
  message?: string;
  success?: boolean;
}

export interface IExecutiveState {
  readonly data: IExecutiveData;
  readonly loading: boolean;
  readonly error: IExecutiveError | null;
}
