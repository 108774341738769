/* eslint-disable no-undef */
import React from 'react';

import { ComponentSpinner } from './styles';

export interface ISpinner {
  color?: string;
  size?: string;
}

const Spinner: React.FC<ISpinner> = ({ color, size }) => (
  <ComponentSpinner color={color} size={size} viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </ComponentSpinner>
);

export default Spinner;
