/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { ITimeSheetState, TimeSheetTypes } from './types';

const INITIAL_STATE: ITimeSheetState = {
  data: [],
  disabled: false,
  printing: false,
  loading: false,
  error: false,
};

const timeSheet: Reducer<ITimeSheetState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TimeSheetTypes.LOAD_TIME_SHEET_REQUEST: {
        draft.loading = true;
        break;
      }
      case TimeSheetTypes.LOAD_TIME_SHEET_SUCCESS: {
        draft.data = action.payload.data;
        draft.disabled = action.payload.data.length === 0;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case TimeSheetTypes.LOAD_TIME_SHEET_FAILURE: {
        draft.data = [];
        draft.disabled = false;
        draft.loading = false;
        draft.error = true;
        break;
      }
      case TimeSheetTypes.PRINT_TIME_SHEET_REQUEST: {
        draft.printing = true;
        break;
      }
      case TimeSheetTypes.PRINT_TIME_SHEET_SUCCESS: {
        draft.printing = false;
        draft.error = false;
        break;
      }
      case TimeSheetTypes.PRINT_TIME_SHEET_FAILURE: {
        draft.printing = false;
        break;
      }
      default:
        break;
    }
  });
};

export default timeSheet;
