/* eslint-disable @typescript-eslint/no-explicit-any */
import { ILandingContent } from 'models/landing';
import { LandingTypes, IError } from './types';

export const setFloatingBalloon = ({
  showFloatingBalloon,
}: {
  showFloatingBalloon: boolean;
}) => ({
  type: LandingTypes.SET_FLOATING_BALLOON,
  payload: {
    showFloatingBalloon,
  },
});

export const setDeferredPrompt = (deferredPrompt: any) => ({
  type: LandingTypes.SET_DEFERRED_PROMPT,
  payload: {
    deferredPrompt,
  },
});

export const getLandingContentRequest = () => ({
  type: LandingTypes.GET_LANDING_CONTENT_REQUEST,
});

export const getLandingContentSuccess = (data: ILandingContent[]) => ({
  type: LandingTypes.GET_LANDING_CONTENT_SUCCESS,
  payload: {
    data,
  },
});

export const getLandingContentFailure = (error: IError) => ({
  type: LandingTypes.GET_LANDING_CONTENT_FAILURE,
  payload: {
    error,
  },
});
