/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { ITimeSheet } from 'models/timeSheet';

/**
 * Actions Types
 */
export enum TimeSheetTypes {
  LOAD_TIME_SHEET_REQUEST = '@timeSheet/LOAD_TIME_SEET_REQUEST',
  LOAD_TIME_SHEET_SUCCESS = '@timeSheet/LOAD_TIME_SEET_SUCCESS',
  LOAD_TIME_SHEET_FAILURE = '@timeSheet/LOAD_TIME_SEET_FAILURE',
  PRINT_TIME_SHEET_REQUEST = '@timeSheet/PRINT_TIME_SHEET_REQUEST',
  PRINT_TIME_SHEET_SUCCESS = '@timeSheet/PRINT_TIME_SHEET_SUCCESS',
  PRINT_TIME_SHEET_FAILURE = '@timeSheet/PRINT_TIME_SHEET_FAILURE',
}

/**
 * State Types
 */
export interface ITimeSheetState {
  readonly data: ITimeSheet[];
  readonly disabled: boolean;
  readonly printing: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
