import { IHealthData, IHealthError, IHealthDependentTypes } from './types';

export const healthAddDependentSetData = (data: IHealthData) => ({
  type: IHealthDependentTypes.ADD_DEPENDENT_HEALTH_SET_DATA,
  payload: {
    data,
  },
});

export const healthAddDependentRequest = ({
  handleClickNextStep,
}: {
  handleClickNextStep: () => void;
}) => ({
  type: IHealthDependentTypes.ADD_DEPENDENT_HEALTH_REQUEST,
  payload: {
    handleClickNextStep,
  },
});

export const healthAddDependentSuccess = () => ({
  type: IHealthDependentTypes.ADD_DEPENDENT_HEALTH_SUCCESS,
});

export const healthAddDependentFailure = (error: IHealthError) => ({
  type: IHealthDependentTypes.ADD_DEPENDENT_HEALTH_FAILURE,
  payload: {
    error,
  },
});

export const healthAddDependentReset = () => ({
  type: IHealthDependentTypes.ADD_DEPENDENT_HEALTH_RESET,
});
