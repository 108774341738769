import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { IAuthState } from 'store/modules/auth/types';
import { IState } from 'store';

import {
  getDeviceInfoRequest,
  logoutSuccess,
} from 'store/modules/auth/actions';

import api from 'services/api';
import Button from 'shared/Button';
import Text from 'shared/Tipografia/Text';
import ButtonLink from 'shared/Button/ButtonLink';
import { ButtonAlign } from 'shared/Button/Enum';
import Loader from 'shared/Loader';
import PopUpAlertBlock from 'shared/PopUpAlertBlock';
import Message from 'shared/Message';
import Icon from 'shared/Icon';

import { isMobile } from 'utils/general';

import {
  WrapperLoader,
  Container,
  ContentButtons,
  MessageContent,
  Panel,
  PanelHeader,
  PanelTitle,
  PanelContentPrimary,
  PanelContentSecundary,
  PanelInformationPrimary,
  PanelInformationSecundary,
  ButtonLinkStyle,
  ContainerPopUpAlert,
  ButtonClose,
} from './styles';

const CheckAccess: React.FC = () => {
  const [isPopUpAlert, setIsPopUpAlert] = useState<boolean>(false);

  const { loading, device } = useSelector<IState, IAuthState>(
    (state) => state.auth,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: { id: string } = useParams();

  async function handleBlock() {
    try {
      await api.get(`/bloquear-acesso/${id}`);

      dispatch(logoutSuccess());
      history.push('/blocked_access');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  function handlePopUpAlertBlock() {
    setIsPopUpAlert(true);
  }

  function handleOverFlowHidden() {
    const elementRoot = window.document.getElementById('root');

    if (isMobile() && elementRoot) {
      elementRoot.style.overflow = 'hidden';
    }
  }

  function handleOverFlowUnset() {
    const elementRoot = window.document.getElementById('root');

    if (isMobile() && elementRoot) {
      elementRoot.style.overflow = 'unset';
    }
  }

  setTimeout(() => {
    const cookieBanner = window.document.getElementById(
      'privacytools-banner-consent',
    );

    if (cookieBanner === null) {
      // eslint-disable-next-line no-console
      console.log('Cookie Banner Not Found!');
    } else {
      cookieBanner?.nodeName; // <- no error
      cookieBanner.style.display = 'none';
    }
  }, 1000);

  useEffect(() => {
    dispatch(getDeviceInfoRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    handleOverFlowHidden();

    return () => {
      handleOverFlowUnset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isPopUpAlert && (
        <ContainerPopUpAlert>
          <PopUpAlertBlock
            textPrimary="Tem certeza que deseja bloquear o seu acesso?"
            textSecundary="Para seguir com essa ação a sua conta será automaticamente bloqueada para proteger os seus dados cadastrais."
            visiblePopUp={isPopUpAlert}
            buttonPrimary="bloquear o acesso"
            buttonSecundary="voltar"
            handleClickPrimary={() => handleBlock()}
            handleClickSecundary={() => setIsPopUpAlert(false)}
          />
        </ContainerPopUpAlert>
      )}
      {loading ? (
        <WrapperLoader>
          <Loader />
        </WrapperLoader>
      ) : (
        <Container>
          <MessageContent>
            <Message type="warn">
              Para uma melhor experiência, aconselhamos a navegação pelo
              aplicativo em seu celular.
            </Message>
          </MessageContent>

          <Panel>
            <ButtonClose type="submit" onClick={() => history.push('/home')}>
              <Icon name="close" width="18" height="18" />
            </ButtonClose>
            <PanelHeader>
              <Text as="h3"> {device ? `${device?.name},` : '-'}</Text>
              <Text as="h3">
                Detectamos um novo acesso à sua conta através de:
              </Text>
            </PanelHeader>

            <PanelTitle>
              <Text as="disclaimer">DISPOSITIVO E NAVEGADOR</Text>
            </PanelTitle>
            <PanelContentPrimary>
              <Text as="p" color="#000">
                {device ? `${device?.so}, ${device?.browser}` : '-'}
              </Text>
            </PanelContentPrimary>

            <PanelTitle>
              <Text as="disclaimer">DATA E HORÁRIO DO ACESSO</Text>
            </PanelTitle>

            <PanelContentSecundary>
              <Text as="p" color="#000">
                {device?.hourAccess || '-'}
              </Text>
            </PanelContentSecundary>

            <PanelInformationPrimary>
              <Text as="h3">
                Se você não reconhece este acesso clique no botão abaixo:
              </Text>
            </PanelInformationPrimary>

            <ContentButtons>
              <Button
                align={ButtonAlign.CENTER}
                onClick={handlePopUpAlertBlock}
              >
                bloquear o acesso
              </Button>
            </ContentButtons>

            <PanelInformationSecundary>
              <Text as="h3" color="#4F4F4F">
                Se foi você, por favor, desconsidere esta mensagem.
              </Text>
            </PanelInformationSecundary>

            <ButtonLinkStyle>
              <ButtonLink onClick={() => history.push('/')}>
                Me leve para o RH Digital
              </ButtonLink>
            </ButtonLinkStyle>
          </Panel>
        </Container>
      )}
    </>
  );
};

export default CheckAccess;
