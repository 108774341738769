import IConfigurationData from 'models/accountConfig/configuration';
import { IConfigurationTypes, IConfigurationError } from './types';

export const getConfigurationRequest = () => ({
  type: IConfigurationTypes.USER_CONFIGURATION_REQUEST,
});

export const getConfigurationSuccess = (
  data: IConfigurationData | null,
  arrayNotification: string[],
) => ({
  type: IConfigurationTypes.USER_CONFIGURATION_SUCCESS,
  payload: {
    data,
    arrayNotification,
  },
});

export const getConfigurationFailure = (error: IConfigurationError) => ({
  type: IConfigurationTypes.USER_CONFIGURATION_FAILURE,
  payload: {
    error,
  },
});

export const configurationPutRequest = (arrayNotification: string[]) => ({
  type: IConfigurationTypes.USER_CONFIGURATION_PUT_REQUEST,
  payload: {
    arrayNotification,
  },
});

export const configurationPutSuccess = () => ({
  type: IConfigurationTypes.USER_CONFIGURATION_PUT_SUCCESS,
});

export const configurationPutFailure = (error: IConfigurationError) => ({
  type: IConfigurationTypes.USER_CONFIGURATION_PUT_FAILURE,
  payload: {
    error,
  },
});
