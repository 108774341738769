/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IExecutiveState, IExecutiveTypes } from './types';

const INITIAL_STATE: IExecutiveState = {
  data: {
    message: null,
  },
  loading: false,
  error: null,
};

const executive: Reducer<IExecutiveState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case IExecutiveTypes.EXECUTIVE_REQUEST: {
        draft.data = {
          message: action.payload.message,
        };
        draft.loading = true;
        draft.error = null;
        break;
      }
      case IExecutiveTypes.EXECUTIVE_SUCCESS: {
        draft.data = {
          message: null,
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      case IExecutiveTypes.EXECUTIVE_FAILURE: {
        draft.data = {
          message: null,
        };
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default executive;
