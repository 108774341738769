import { VacationRequestTypes, IVacationResponse } from './types';


interface IRequest {
  matricula: string;
  dataInicio?: string;
  dataFim?: string;
}
export const vacationRequestRequest = (params: IRequest) => {
  const {
    matricula,
    dataInicio,
    dataFim } = params;

  return {
    type: VacationRequestTypes.VACATION_REQUEST_REQUEST,
    payload: {
      matricula,
      dataInicio,
      dataFim
    },
  }
};

export const vacationRequestSuccess = (data: IVacationResponse[]) => ({
  type: VacationRequestTypes.VACATION_REQUEST_SUCCESS,
  payload: {
    data,
  },
});

export const vacationRequestFailure = () => ({
  type: VacationRequestTypes.VACATION_REQUEST_FAILURE,
});