import { IDownloadFileError, IDownloadFileTypes } from './types';

export const downloadFileRequest = ({
  requestNumber,
  fileName,
  fileType,
  actionNumber,
}: {
  requestNumber: string;
  fileName: string;
  fileType: string;
  actionNumber: string;
}) => ({
  type: IDownloadFileTypes.DOWNLOAD_FILE_REQUEST,
  payload: {
    requestNumber,
    fileName,
    fileType,
    actionNumber,
  },
});

export const downloadFileRequestActions = ({
  requestNumber,
  fileType,
  attachmentActions,
}: {
  requestNumber: string;
  fileType: string;
  attachmentActions: string;
}) => ({
  type: IDownloadFileTypes.DOWNLOAD_FILE_REQUEST_ACTIONS,
  payload: {
    requestNumber,
    fileType,
    attachmentActions,
  },
});

export const downloadFileSuccess = () => ({
  type: IDownloadFileTypes.DOWNLOAD_FILE_SUCCESS,
});

export const downloadFileFailure = (error: IDownloadFileError) => ({
  type: IDownloadFileTypes.DOWNLOAD_FILE_FAILURE,
  payload: {
    error,
  },
});
