/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { ICancelTransportState, ICancelTransportTypes } from './types';

const INITIAL_STATE: ICancelTransportState = {
  data: {
    file: null,
    type: null,
  },
  loading: false,
  error: null,
};

const newTransport: Reducer<ICancelTransportState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ICancelTransportTypes.CANCEL_TRANSPORT_SET_DATA: {
        draft.data = {
          file: action.payload.file,
          type: action.payload.type,
        };
        break;
      }
      case ICancelTransportTypes.CANCEL_TRANSPORT_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ICancelTransportTypes.CANCEL_TRANSPORT_SUCCESS: {
        draft.data = {
          file: null,
          type: null,
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      case ICancelTransportTypes.CANCEL_TRANSPORT_FAILURE: {
        draft.data = {
          file: null,
          type: null,
        };
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default newTransport;
