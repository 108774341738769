/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum InssRequestTypes {
  INSS_REQUEST_REQUEST = '@request/INSS_REQUEST_REQUEST',
  INSS_REQUEST_SUCCESS = '@request/INSS_REQUEST_SUCCESS',
  INSS_REQUEST_FAILURE = '@request/INSS_REQUEST_FAILURE',
  INSS_REQUEST_RESET = '@request/INSS_REQUEST_RESET',
}

/**
 * State Types
 */
export interface IInssRequesState {
  readonly loading: boolean;
  readonly error: boolean;
}
