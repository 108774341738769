/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IDentalDependentState, IDentalDependentTypes } from './types';

const INITIAL_STATE: IDentalDependentState = {
  data: {
    optionId: 'Casamento',
    dependents: [],
  },
  loading: false,
  error: null,
};

const dentalDependent: Reducer<IDentalDependentState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case IDentalDependentTypes.ADD_DEPENDENT_DENTAL_SET_DATA: {
        draft.data = action.payload.data;
        break;
      }
      case IDentalDependentTypes.ADD_DEPENDENT_DENTAL_REQUEST: {
        draft.data = {
          ...state.data,
          current: {
            ...state.data.current,
            fileOperator: action.payload.fileOperator,
          },
        };
        draft.loading = true;
        draft.error = null;
        break;
      }
      case IDentalDependentTypes.ADD_DEPENDENT_DENTAL_SUCCESS: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      case IDentalDependentTypes.ADD_DEPENDENT_DENTAL_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case IDentalDependentTypes.ADD_DEPENDENT_DENTAL_RESET: {
        draft.data = {
          optionId: 'Casamento',
          dateEvent: undefined,
          current: undefined,
          dependents: [],
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default dentalDependent;
