/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IPayment } from 'models/paycheck/payment';

/**
 * Actions Types
 */
export enum PaymentTypes {
  LOAD_PAYMENT_REQUEST = '@payment/LOAD_PAYMENT_REQUEST',
  LOAD_PAYMENT_SUCCESS = '@payment/LOAD_PAYMENT_SUCCESS',
  LOAD_PAYMENT_FAILURE = '@payment/LOAD_PAYMENT_FAILURE',
  PRINT_PAYMENT_REQUEST = '@payment/PRINT_PAYMENT_REQUEST',
  PRINT_PAYMENT_SUCCESS = '@payment/PRINT_PAYMENT_SUCCESS',
  PRINT_PAYMENT_FAILURE = '@payment/PRINT_PAYMENT_FAILURE',
}

/**
 * State Types
 */
export interface IPaymentState {
  readonly data: IPayment[];
  readonly disabled: boolean;
  readonly printing: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
