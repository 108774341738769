import styled from 'styled-components';

import { IMessage } from './index';

const backgroundColor = ({ type }: IMessage) => {
  switch (type) {
    case 'success':
      return 'rgb(138, 161, 92, 0.25)';
    case 'error':
      return 'rgb(196, 59, 81, 0.25)';
    case 'warn':
      return 'rgba(254, 202, 48, 0.25)';
    default:
      return 'rgb(0 0 0 / 0%);';
  }
};

const iconColor = ({ type }: IMessage) => {
  switch (type) {
    case 'success':
      return '#8AA15C';
    case 'error':
      return '#C43B51';
    case 'warn':
      return '#df8d2c';
    default:
      return '#004186;';
  }
};

const borderType = ({ type }: IMessage) => {
  switch (type) {
    case 'info':
      return '1px solid rgba(0, 65, 134, 0.25)';
    default:
      return 'none';
  }
};

export const Container = styled.div`
  width: 100%;
  background: ${backgroundColor};
  border-radius: 4px;
  display: flex;
  padding: 16px 13px;
  margin-bottom: 25px;
  border: ${borderType};
  box-sizing: border-box;
`;

export const IconContent = styled.div`
  margin-right: 10px;

  > svg > path {
    stroke: ${iconColor};
    stroke-width: 1.5;
  }
`;

export const Info = styled.div`
  margin-top: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;
