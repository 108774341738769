import { all, takeLatest, put, call } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import api from 'services/api';

import { ITrackCancelTypes, ITrackCancelError } from './types';

import {
  trackCancelRequest,
  trackCancelSuccess,
  trackCancelFailure,
} from './actions';

type ITrackCancelType = ReturnType<typeof trackCancelRequest>;

export function* tracksCancelRequest({ payload }: ITrackCancelType) {
  try {
    const { called, description, file } = payload;

    const formData = new FormData();
    formData.append('chamado', called);
    formData.append('descricao', String(description));
    for (let i = 0; i < file.length; i += 1) {
      formData.append('anexo', file[i]);
    }

    const { data }: AxiosResponse<ITrackCancelError> = yield call(
      api.post,
      '/portal-conecta/cancelar-chamados',
      formData,
    );

    if (data.success === false) {
      const response = {
        status: data.status,
        message: data.message,
        success: data.success,
      };
      yield put(trackCancelFailure(response));
    } else {
      yield put(trackCancelSuccess());
    }
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(trackCancelFailure(response));
      return;
    }

    yield put(
      trackCancelFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  } finally {
    const { handleClickNextStep } = payload;
    handleClickNextStep();
  }
}

export default all([
  takeLatest(ITrackCancelTypes.TRACK_CANCEL_REQUEST, tracksCancelRequest),
]);
