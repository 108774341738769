/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IAnnouncementsState, AnnouncementsTypes } from './types';

const INITIAL_STATE: IAnnouncementsState = {
  data: [],
  loading: false,
  error: false,
  totalPage: 1,
  currentPage: 1,
  id: '',
};

const announcements: Reducer<IAnnouncementsState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case AnnouncementsTypes.LOAD_ANNOUNCEMENTS_REQUEST: {
        draft.loading = true;
        break;
      }
      case AnnouncementsTypes.LOAD_ANNOUNCEMENTS_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        draft.error = false;
        draft.totalPage = action.payload.totalPage;
        draft.currentPage = action.payload.currentPage;
        draft.id = action.payload.id;
        break;
      }
      case AnnouncementsTypes.LOAD_ANNOUNCEMENTS_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case AnnouncementsTypes.LOAD_ANNOUNCEMENTS_RESET: {
        draft.data = [];
        draft.totalPage = 1;
        draft.loading = false;
        draft.error = false;
        break;
      }
      default:
        break;
    }
  });
};

export default announcements;
