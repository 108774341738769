/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum ITrackReopenTypes {
  TRACK_REOPEN_REQUEST = '@request/TRACK_REOPEN_REQUEST',
  TRACK_REOPEN_SUCCESS = '@request/TRACK_REOPEN_SUCCESS',
  TRACK_REOPEN_FAILURE = '@request/TRACK_REOPEN_FAILURE',
}

/**
 * State Types
 */
export interface ITrackReopenData {
  called: string;
  description: string;
  file?: File[];
}

export interface ITrackReopenError {
  status?: number;
  message?: string;
  success?: boolean;
}

export interface ITrackReopenState {
  readonly data: ITrackReopenData;
  readonly loading: boolean;
  readonly error: ITrackReopenError | null;
}
