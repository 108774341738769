/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { ITrackDetails } from 'models/request/track/details';

/**
 * Actions Types
 */
export enum ITrackDetailsTypes {
  TRACK_DETAILS_REQUEST = '@request/TRACK_DETAILS_REQUEST',
  TRACK_DETAILS_SUCCESS = '@request/TRACK_DETAILS_SUCCESS',
  TRACK_DETAILS_FAILURE = '@request/TRACK_DETAILS_FAILURE',
}

/**
 * State Types
 */
export interface ITrackDetailsError {
  status?: number;
  message?: string;
}

export interface ITrackDetailsState {
  readonly data: ITrackDetails;
  readonly loading: boolean;
  readonly error: ITrackDetailsError | null;
}
