/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import api from 'services/api';

import { IState } from 'store';
import { IChangeTransportTypes } from './types';

import {
  changeTransportRequest,
  changeTransportSuccess,
  changeTransportFailure,
} from './actions';

type IChangeTransportType = ReturnType<typeof changeTransportRequest>;

export function* changesTransportRequest({ payload }: IChangeTransportType) {
  try {
    const { data } = payload;

    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const matricula: string = yield select(getMatricula);

    const formData = new FormData();
    formData.append('matricula', matricula);
    formData.append('tipoSolicitacao', 'Alteração');
    formData.append('anexo', data.fileTransportVoucherFront ? data.fileTransportVoucherFront : '');
    formData.append('anexo', data.fileTransportVoucherBack ? data.fileTransportVoucherBack : '');
    formData.append('enderecoConfirmado', String(data.confirmedAddress));
    formData.append('chamadoEndereco', String(data.calledAddress));
    formData.append('unidadeConfirmada', String(data.unitConfirmed));
    formData.append('chamadoUnidade', String(data.calledUnit));
    formData.append('possuiVT', data.numberVT ? 'Sim' : 'Não')
    formData.append('numeroVT', String(data.numberVT));
    formData.append('itinerarioIda', JSON.stringify(data.itineraryGoing));
    formData.append('itinerarioVolta', JSON.stringify(data.itineraryBack));

    yield call(api.post, '/portal-conecta/vale-transporte', formData);

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `Transporte - Alteração de VT`,
    });

    yield put(changeTransportSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(changeTransportFailure(response));
      return;
    }

    yield put(
      changeTransportFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  } finally {
    const { handleClickNextStep } = payload;
    handleClickNextStep();
  }
}

export default all([
  takeLatest(IChangeTransportTypes.CHANGE_TRANSPORT_REQUEST, changesTransportRequest),
]);
