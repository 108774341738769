/* eslint-disable no-unused-vars */

import React from 'react';
import { useSelector } from 'react-redux';
import Icon from 'shared/Icon';
import theme from 'styles/theme';
import { IState } from 'store';
import { IChatState } from 'store/modules/chat/types';
import { handleChatButton } from 'utils/general';
import { Container } from './styles';

const FloatingChatButton: React.FC = () => {
  const { loaded } = useSelector<IState, IChatState>((state) => state.chat);

  // CHAT NICE - AGUARDANDO LIBERACAO
  // if (!loaded) {
  //   return null;
  // }

  return (
    <Container
      id="chatButtonFooter"
      className="chatButton"
      onClick={() => handleChatButton()}
    >
      <Icon
        name="chatCircle"
        color={theme.colors.white}
        width="30"
        height="30"
      />
      <p>Precisa de ajuda?</p>
    </Container>
  );
};

export default FloatingChatButton;
