import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from 'services/api';

import { IState } from 'store';

import IDental from 'models/benefits/dental';
import { getDentalSuccess, getDentalFailure } from './actions';

import { DentalTypes } from './types';

export function* getDental() {
  try {
    const getCdMatricula = (state: IState) => state.user.current?.cdMatricula;
    const cdMatricula: string = yield select(getCdMatricula);

    const { data }: AxiosResponse<IDental> = yield call(
      api.get,
      `/plano-dental/${cdMatricula}`,
    );

    yield put(getDentalSuccess(data));
  } catch (err) {
    if (!!err && !!err?.response && err.response?.status === 404) {
      yield put(getDentalFailure({ haveInsurance: false }));

      return;
    }

    yield put(getDentalFailure({ haveInsurance: null }));
  }
}

export default all([takeLatest(DentalTypes.GET_DENTAL_REQUEST, getDental)]);
