import styled from 'styled-components';
import { MAX_WIDTH_MOBILE } from 'utils/constants';

export const Container = styled.div`
  > div {
    padding: 125px 48px;
    > div {
      width: 320px;
      padding: 0px 18px 24px 18px;
      text-align: -webkit-center;

      @media (max-width: ${MAX_WIDTH_MOBILE}px) {
        margin: 0px 0px;
        width: auto;
        height: 328px;
      }
    }
  }

  h2 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;
    width: 100%;
    max-width: 230px;
  }

  p {
    font-style: normal;
    font-size: 14px;
    line-height: 125%;
    margin-bottom: 24px;
    width: 100%;
    max-width: 230px;
  }
`;

export const ModalContent = styled.div`
  > button {
    width: 100%;
  }

  > button:first-child {
    margin-bottom: 19px;

    :hover {
      background: #a80f04;
      border-color: #a80f04;
    }

    @media (max-width: ${MAX_WIDTH_MOBILE}px) {
      margin-bottom: 16px;
    }
  }
`;
