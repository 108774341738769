const theme = {
  colors: {
    background: '#F2F2F2',
    landingBackground: '#f1f6ff',
    white: '#FFFFFF',
    black: '#000000',

    textPrimary: '#4F4F4F',
    textSecondary: '#828282',
    hoverText: '#DF8D2C',
    divider: '#F2F2F2',
    separatorColor: '#e0e0e0',
    separatorColorSecondary: '#828282',
    backgroundBlue: '#0166B3',

    primary: '#004186',
    primaryHover: '#023266',
    secondary: '#CD4D9A',
    secondaryHover: '#B0347F',
    tertiary: '#91D8F6',
    tertiaryHover: '#71B8D7',
    quaternary: '#DF8D2C',
    quaternaryHover: '#C87514',
    quinary: '#C43B51',
    quinaryHover: '#AF2037',

    disabled: '#BDBDBD',
    disabledSecondary: '#e0e0e0',
    error: '#C43B51',
    success: '#8AA15C',
  },
};

export default theme;
