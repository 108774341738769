import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    background: ${({ theme }) => theme.colors.background};
  }

  html, body, #root {
    height: 100%;

    .be-frame-minimized {
      display: none !important;
      opacity: 0 !important;
    }
  }

  button {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    cursor: pointer;
  }
`;
