/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { ITrackChangeState, ITrackChangeTypes } from './types';

const INITIAL_STATE: ITrackChangeState = {
  data: {
    called: '',
    description: '',
    file: [],
  },
  loading: false,
  error: null,
};

const trackChange: Reducer<ITrackChangeState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITrackChangeTypes.TRACK_CHANGE_REQUEST: {
        draft.data = {
          called: action.payload.called,
          description: action.payload.description,
          file: action.payload.file,
        };
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ITrackChangeTypes.TRACK_CHANGE_SUCCESS: {
        draft.data = {
          called: '',
          description: '',
          file: [],
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      case ITrackChangeTypes.TRACK_CHANGE_FAILURE: {
        draft.data = {
          called: '',
          description: '',
          file: [],
        };
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default trackChange;
