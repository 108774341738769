/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IUserState, UserTypes } from './types';
import { AuthTypes } from '../auth/types';

const INITIAL_STATE: IUserState = {
  data: [],
  current: null,
  loading: false,
  error: null,
};

const user: Reducer<IUserState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case UserTypes.GET_USER_REQUEST: {
        draft.loading = true;
        break;
      }
      case UserTypes.GET_USER_SUCCESS: {
        draft.data = action.payload.data;
        draft.current = action.payload.current;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case UserTypes.GET_USER_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case UserTypes.BLOCK_USER_REQUEST: {
        draft.loading = true;
        break;
      }
      case UserTypes.BLOCK_USER_SUCCESS: {
        draft.data = [];
        draft.current = null;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case UserTypes.BLOCK_USER_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case UserTypes.SELECT_USER_CURRENT: {
        draft.current = action.payload.current;
        break;
      }
      case UserTypes.RESET_ERROR: {
        draft.error = null;
        break;
      }
      case AuthTypes.LOGOUT_SUCCESS: {
        draft.data = [];
        draft.current = null;
        draft.loading = false;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default user;
