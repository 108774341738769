/* eslint-disable import/no-duplicates */
import { differenceInDays } from 'date-fns';
import { AxiosResponse } from 'axios';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import api from 'services/api';
import { sortPerDate } from 'utils/general';
import { IState } from 'store';
import {
  IVacationGetRequest,
  IVacation,
  IPrintVacationRequest,
} from 'models/vacation';
import {
  loadVacationSuccess,
  loadVacationFailure,
  printVacationSuccess,
  printVacationFailure,
  printVacationRequest,
} from './actions';

import { VacationTypes } from './types';

type printVacationRequestType = ReturnType<typeof printVacationRequest>;

function formatDate(data: string): string {
  return `${data.substring(6, 4)}, ${data.substring(3, 2)}, ${data.substring(
    0,
    2,
  )}`;
}

function sortVacationData(newData: IVacation[]): IVacation[] {
  const sortedData = newData.slice().sort((itemA, itemB) => {
    const dateA = new Date(
      `${itemA.nrAnoMes.substring(0, 4)}, ${itemA.nrAnoMes.substring(4)}, 05`,
    );
    const dateB = new Date(
      `${itemB.nrAnoMes.substring(0, 4)}, ${itemB.nrAnoMes.substring(4)}, 05`,
    );

    return sortPerDate(dateA, dateB);
  });

  return sortedData;
}

export function* loadVacation() {
  try {
    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getMatricula);
    const { data }: AxiosResponse<IVacationGetRequest[]> = yield call(
      api.get,
      `/recibo-ferias/${inscription}`,
    );

    const newData: IVacation[] = data.map((vacation) => {
      const { dtinicioGozo, dtFinalGozo } = vacation;
      const vacationDays =
        differenceInDays(
          new Date(formatDate(dtFinalGozo)),
          new Date(formatDate(dtinicioGozo)),
        ) + 1;
      return {
        ...vacation,
        id: vacation?.nrAnoMes ? vacation.nrAnoMes : '-',
        title:
          typeof vacationDays === 'number' && vacationDays > 0
            ? `Férias (${vacationDays} dias)`
            : 'Férias',
        subTitle:
          vacation?.dtinicioGozo && vacation.dtFinalGozo
            ? `${vacation.dtinicioGozo} - ${vacation.dtFinalGozo}`
            : '-',
      };
    });

    yield put(loadVacationSuccess(sortVacationData(newData)));
  } catch (err) {
    if (!!err && !!err?.response && err.response?.status === 404) {
      yield put(loadVacationSuccess([]));

      return;
    }

    yield put(loadVacationFailure());
  }
}

export function* printVacation({ payload }: printVacationRequestType) {
  try {
    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getMatricula);

    const { competence } = payload;

    const { data }: AxiosResponse<IPrintVacationRequest> = yield call(
      api.post,
      '/recibo-ferias-pdf',
      {
        cdMatricula: inscription,
        competencia: competence,
      },
    );

    const { encodedFile, message } = data;

    // let fileName: string;

    if (message && message.toLowerCase().includes('erro')) {
      throw message;
    }

    const dataLayer = window.dataLayer || [];

    // const currentDate = format(new Date(), 'ddMMyy');
    // const currentTime = format(new Date(), 'hhmmss');

    if (competence && competence?.length > 1) {
      dataLayer.push({
        event: 'multDownloadVacation',
      });
    } else {
      dataLayer.push({
        event: 'downloadVacation',
        file: data.fileName,
      });
    }

    /* dataLayer.push({
      event:
        competence && competence?.length > 1
          ? 'multDownloadVacation'
          : 'downloadVacation',
      file: data.fileName,
    }); */

    // if (competence && competence?.length > 1) {
    //   // Many files
    //   const lastCompetence = competence[0];
    //   const firstCompetence = competence.pop();

    //   fileName = `Demonstrativo de Pagamento - ${inscription} - ${firstCompetence?.subTitle} - ${lastCompetence?.subTitle} - ${currentDate}${currentTime}.pdf`;

    //   dataLayer.push({
    //     event: 'multDownloadPayments',
    //   });
    // } else {
    //   // Only one file

    //   fileName = `Demonstrativo de Pagamento - ${inscription} - ${
    //     competence && competence[0].subTitle
    //   } - ${currentDate}${currentTime}.pdf`;
    //   dataLayer.push({
    //     event: 'downloadPayments',
    //     file: fileName, // Passar nome do arquivo baixado
    //   });
    // }

    const downloadLink = document.createElement('a');

    downloadLink.href = encodedFile;
    downloadLink.download = data.fileName;
    document.body.appendChild(downloadLink);

    downloadLink.click();
    document.body.removeChild(downloadLink);

    yield put(printVacationSuccess());
  } catch (err) {
    // TODO Remover quando o UX fizer uma tela para cenário de tratativa de erro
    // eslint-disable-next-line no-console
    console.log('Erro: ', err);
    yield put(printVacationFailure());
  }
}

export default all([
  takeLatest(VacationTypes.LOAD_VACATION_REQUEST, loadVacation),
  takeLatest(VacationTypes.PRINT_VACATION_REQUEST, printVacation),
]);
