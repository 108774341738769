import { takeLatest, put, call, all } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import api from 'services/api';

import { ITrackDetails } from 'models/request/track/details';

import {
  trackDetailsRequest,
  trackDetailsSuccess,
  trackDetailsFailure,
} from './actions';

import { ITrackDetailsTypes } from './types';

type ITrackDetailsType = ReturnType<typeof trackDetailsRequest>;

export function* tracksDetailsRequest({ payload }: ITrackDetailsType) {
  try {
    const { requestNumber } = payload;

    const { data }: AxiosResponse<ITrackDetails[]> = yield call(
      api.get,
      `/portal-conecta/consultar-detalhes-chamados/${requestNumber}`,
    );

    yield put(trackDetailsSuccess(data));
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(trackDetailsFailure(response));
    }
  }
}

export default all([
  takeLatest(ITrackDetailsTypes.TRACK_DETAILS_REQUEST, tracksDetailsRequest),
]);
