import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { IInssRequestResponse } from 'models/request/inss/request';
import api from 'services/api';
import { InssRequestTypes } from './types';

import {
  inssRequestFailure,
  inssRequestRequest,
  inssRequestSuccess,
} from './actions';

type inssRequestType = ReturnType<typeof inssRequestRequest>;

export function* inssRequest({ payload }: inssRequestType) {
  try {
    const { matricula, periodoCompetencia } = payload;

    const { data }: AxiosResponse<IInssRequestResponse> = yield call(
      api.post,
      '/portal-conecta/solicitacoes-cartas',
      {
        matricula,
        periodoCompetencia,
      },
    );

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `INSS - Solicitar Nova Carta`,
    });

    yield put(inssRequestSuccess(data));
  } catch (err) {
    yield put(inssRequestFailure());
  } finally {
    const { handleClickNextStep } = payload;

    handleClickNextStep();
  }
}

export default all([
  takeLatest(InssRequestTypes.INSS_REQUEST_REQUEST, inssRequest),
]);
