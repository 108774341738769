/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum UpdateAvailableTypes {
  UPDATE_AVAILABLE = '@update/UPDATE_AVAILABLE',
}

/**
 * State Types
 */
export interface IUpdateAvailableState {
  readonly update: boolean;
  readonly downloadUpdates: boolean;
}
