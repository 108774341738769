import React, { CSSProperties } from 'react';

import { Theme } from 'styles/styled';

import { SpanComponent, TextComponent } from './styles';

export interface IText {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'caption' | 'disclaimer';
  theme?: Theme;
  color?: string;
  htmlFor?: string | undefined;
  align?: 'left' | 'center' | 'right';
  fontWeight?: '100' | '300' | '400' | '500' | '700' | '900';
  pointer?:boolean;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
}

const Text: React.FC<IText> = ({
  as,
  color,
  align,
  fontWeight,
  pointer=false,
  children,
  style,
  className,
  onClick,
}) => {
  if (as === 'caption' || as === 'disclaimer') {
    return (
      <SpanComponent
        className={className}
        style={style}
        type={as}
        color={color}
        align={align}
        fontWeight={fontWeight}
        onClick={onClick}
        cursor={pointer ? 'pointer' : ''}
      >
        {children}
      </SpanComponent>
    );
  }

  return (
    <TextComponent
      className={className}
      as={as}
      color={color}
      align={align}
      fontWeight={fontWeight}
      onClick={onClick}
      cursor={pointer ? 'pointer' : null}
    >
      {children}
    </TextComponent>
  );
};

export default Text;
