import React from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'store';
import { IAuthState } from 'store/modules/auth/types';

import { IHeaderMenu } from 'models/menu';
import Icon from 'shared/Icon';
import { Container, WrapperButtons, ContentButton } from './styles';

const Header: React.FC<IHeaderMenu> = ({ buttonsLeft, buttonsRight }) => {
  const { signed } = useSelector<IState, IAuthState>((state) => state.auth);

  const filteredButtonsLeft = signed
    ? buttonsLeft
    : buttonsLeft?.slice().filter((item) => item.icon === 'profile');

  const filteredButtonsRight = signed
    ? buttonsRight
    : buttonsRight
        ?.slice()
        .filter((item) => item.icon === 'telephone' || item.icon === 'close');

  return (
    <Container>
      <WrapperButtons>
        {filteredButtonsLeft?.map(
          ({ id, icon, size, color, disabled, onClick }) => (
            <ContentButton key={id} isProfile={signed && icon === 'profile'}>
              <button
                disabled={disabled}
                type="button"
                className={`${icon} ${id}`}
                onClick={onClick}
              >
                <Icon
                  name={icon}
                  height={size}
                  width={size}
                  color={disabled !== true ? color : '#ccc'}
                />
              </button>
            </ContentButton>
          ),
        )}
      </WrapperButtons>

      <WrapperButtons>
        {filteredButtonsRight?.map(
          ({ id, icon, size, color, disabled, onClick }) => (
            <ContentButton
              key={id}
              className={icon}
              isProfile={icon === 'profile'}
            > 
              <button
                disabled={disabled}
                id={id}
                className={`${icon} ${id}`}
                type="button"
                onClick={onClick}
              >
                <Icon
                  name={icon}
                  id={id ? `icon_${id}` : ''}
                  height={size}
                  width={size}
                  color={disabled !== true ? color : '#ccc'}
                />
              </button>
            </ContentButton>
          ),
        )}
      </WrapperButtons>
    </Container>
  );
};

export default Header;
