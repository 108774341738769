/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IInssRequesState, InssRequestTypes } from './types';

const INITIAL_STATE: IInssRequesState = {
  loading: false,
  error: false,
};

const inssRequest: Reducer<IInssRequesState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case InssRequestTypes.INSS_REQUEST_REQUEST: {
        draft.loading = true;
        break;
      }
      case InssRequestTypes.INSS_REQUEST_SUCCESS: {
        draft.loading = false;
        draft.error = false;
        break;
      }
      case InssRequestTypes.INSS_REQUEST_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case InssRequestTypes.INSS_REQUEST_RESET: {
        draft.loading = false;
        draft.error = false;
        break;
      }
      default:
        break;
    }
  });
};

export default inssRequest;
