import {
  ITransportData,
  ITransportError,
  ITransportDuplicateTypes,
} from './types';

export const transportDuplicateSetData = (data: ITransportData) => ({
  type: ITransportDuplicateTypes.TRANSPORT_DUPLICATE_SET_DATA,
  payload: {
    data,
  },
});

export const transportDuplicateRequest = ({
  type,
  attachment,
  handleClickNextStep,
}: {
  type: 'Avaria' | 'Perda' | 'Roubo' | 'Outros';
  attachment?: File;
  handleClickNextStep: () => void;
}) => ({
  type: ITransportDuplicateTypes.TRANSPORT_DUPLICATE_REQUEST,
  payload: {
    type,
    attachment,
    handleClickNextStep,
  },
});

export const transportDuplicateSuccess = () => ({
  type: ITransportDuplicateTypes.TRANSPORT_DUPLICATE_SUCCESS,
});

export const transportDuplicateFailure = (error: ITransportError) => ({
  type: ITransportDuplicateTypes.TRANSPORT_DUPLICATE_FAILURE,
  payload: {
    error,
  },
});

export const transportDuplicateReset = () => ({
  type: ITransportDuplicateTypes.TRANSPORT_DUPLICATE_RESET,
});
