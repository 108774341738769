import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import wordpressApi from 'services/api/wordpress';

import { IFaq, IFaqRequest } from 'models/faq';
import { ICategory } from 'models/faq/category';
import { FaqTypes } from './types';

import { loadFaqFailure, loadFaqSuccess } from './actions';

export function* loadFaq() {
  try {
    const { data: categories }: AxiosResponse<ICategory[]> = yield call(
      wordpressApi.get,
      '/categoria_faq?per_page=100',
    );
    const { data: faq }: AxiosResponse<IFaqRequest[]> = yield call(
      wordpressApi.get,
      '/faq?per_page=100',
    );

    let i = 0;
    let y = -1;

    const data: IFaq[] = categories.map((category) => {
      const currentFaq = faq.filter((item) =>
        item.categoria_faq.includes(category.id),
      );

      const newFaq: IFaq = {
        id: i += 1,
        title: category.name,
        categoryId: category.id,
        subitens: currentFaq.map((item) => {
          y += 1;

          return {
            id: y,
            title: item.acf.rdsl_post_subtitle,
            description: item.acf.rdsl_post_content || [],
          };
        }),
      };

      return newFaq;
    });

    yield put(loadFaqSuccess(data));
  } catch (err) {
    yield put(loadFaqFailure());
  }
}

export default all([takeLatest(FaqTypes.LOAD_FAQ_REQUEST, loadFaq)]);
