/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IResearchFormState, ResearchFormTypes } from './types';

const INITIAL_STATE: IResearchFormState = {
  data: {
    acf: '',
    id: 0,
    link: '',
    slug: 'string',
    title: {
      rendered: '',
    },
  },
  loading: false,
  error: false,
  researchFormRoute: '',
  researchFormExit: false,
};

const researchForm: Reducer<IResearchFormState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ResearchFormTypes.LOAD_RESEARCH_FORM_REQUEST: {
        draft.loading = true;
        draft.researchFormRoute = action.payload.researchFormRoute;
        break;
      }
      case ResearchFormTypes.LOAD_RESEARCH_FORM_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case ResearchFormTypes.LOAD_RESEARCH_FORM_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case ResearchFormTypes.LOAD_RESEARCH_FORM_EXIT: {
        draft.researchFormExit = true;
        break;
      }
      default:
        break;
    }
  });
};

export default researchForm;
