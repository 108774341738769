import React, { HTMLAttributes } from 'react';

import Icon from 'shared/Icon';
import Spinner from 'shared/Icon/Spinner';
import theme from 'styles/theme';

import { ButtonLinkComponent } from './styles';

export interface IButtonLink extends HTMLAttributes<HTMLButtonElement> {
  color?: string;
  textDecoration?: 'overline' | 'line-through' | 'underline' | 'none';
  iconName?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const ButtonLink: React.FC<IButtonLink> = ({
  color = theme.colors.primary,
  textDecoration,
  iconName,
  isLoading,
  disabled,
  children,
  ...rest
}) => {
  return (
    <ButtonLinkComponent
      type="button"
      color={color}
      textDecoration={textDecoration}
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading && <Spinner color={color} size="17" />}

      {!isLoading && iconName && (
        <Icon
          name={iconName}
          height="17px"
          width="17px"
          color={disabled ? '#BDBDBD' : color}
        />
      )}
      {children}
    </ButtonLinkComponent>
  );
};

export default ButtonLink;
