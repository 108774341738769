import React from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'store';
import { IUpdateAvailableState } from 'store/modules/updateAvailable/types';

import MainHeader from 'shared/Header/MainHeader';
import UpdateAvailable from 'shared/UpdateAvailable';

import { Container } from './styles';

const Empty: React.FC = ({ children }) => {
  const { update, downloadUpdates } = useSelector<
    IState,
    IUpdateAvailableState
  >((state) => state.updateAvailable);

  return (
    <>
      {update && (
        <UpdateAvailable update={update} downloadUpdates={downloadUpdates} />
      )}
      <Container update={update}>
        <MainHeader />
        {children}
      </Container>
    </>
  );
};

export default Empty;
