/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { ITrackDetailsState, ITrackDetailsTypes } from './types';

const INITIAL_STATE: ITrackDetailsState = {
  data: {
    status: 0,
    message: '',
    anexosChamado: [],
    registros: [
      {
        numeroAcao: '',
        responsavel: '',
        comentario: '',
        dataHoraSolicitacao: '',
        anexoAcao: [],
      },
    ],
  },
  loading: false,
  error: null,
};

const trackDetails: Reducer<ITrackDetailsState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITrackDetailsTypes.TRACK_DETAILS_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ITrackDetailsTypes.TRACK_DETAILS_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case ITrackDetailsTypes.TRACK_DETAILS_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default trackDetails;
