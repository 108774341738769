import { all, takeLatest, put, call } from 'redux-saga/effects';

import api from 'services/api';
import { InssSendTypes } from './types';

import { inssSendRequest, inssSendSuccess, inssSendFailure } from './actions';

type inssSendType = ReturnType<typeof inssSendRequest>;

export function* inssSend({ payload }: inssSendType) {
  try {
    const {
      matricula,
      periodoCompetencia,
      file,
      handleClickNextStep,
    } = payload;

    const attachment = new FormData();

    attachment.append('matricula', matricula);
    attachment.append('periodoCompetencia', periodoCompetencia);
    attachment.append('anexo', file);

    yield call(api.post, '/portal-conecta/enviar-nova-carta', attachment);

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `INSS - Enviar Nova Carta`,
    });

    yield put(inssSendSuccess());
    handleClickNextStep();
  } catch (err:any) {
    const { handleClickNextStep } = payload;

    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(inssSendFailure(response));
      return;
    }

    yield put(
      inssSendFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
    handleClickNextStep();
  }
}

export default all([takeLatest(InssSendTypes.INSS_SEND_REQUEST, inssSend)]);
