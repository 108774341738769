import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import persistedReducers from './persistReducers';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

import { ILandingState } from './modules/landing/types';
import { IAuthState } from './modules/auth/types';
import { IUserState } from './modules/user/types';
import { IFaqState } from './modules/faq/types';
import { IHealthState } from './modules/benefits/health/types';
import { IDentalState } from './modules/benefits/dental/types';
import { IPaymentState } from './modules/paycheck/payment/types';
import { IVacationState } from './modules/paycheck/vacation/types';
import { IAnnualIncomeState } from './modules/paycheck/annualIncome/types';
import { ITimeSheetState } from './modules/timeSheet/types';
import { IHealthDuplicateState } from './modules/request/health/duplicate/types';
import { IHealthDependentState } from './modules/request/health/dependent/types';
import { IInssRequesState } from './modules/request/inss/request/types';
import { IInssSendState } from './modules/request/inss/send/types';
import { IEmploymentRequestState } from './modules/request/employment/request/types';
import { IDentalDuplicateState } from './modules/request/dental/duplicate/types';
import { IDentalDependentState } from './modules/request/dental/dependent/types';
import { ITransportDuplicateState } from './modules/request/transport/types';
import { IErrorNetworkState } from './modules/errorNetWork/types';
import { INewTransportState } from './modules/request/transport/new/types';
import { IChangeTransportState } from './modules/request/transport/change/types';
import { ICancelTransportState } from './modules/request/transport/cancel/types';
import { ITrackReopenState } from './modules/request/track/reopen/types';
import { ITrackCancelState } from './modules/request/track/cancel/types';
import { ITrackChangeState } from './modules/request/track/change/types';
import { ITrackGetState } from './modules/request/track/get/types';
import { ITrackDetailsState } from './modules/request/track/details/types';
import { IDownloadFileState } from './modules/request/track/downloadFile/types';
import { IExecutiveState } from './modules/request/executive/types';
import { IAnnouncementsState } from './modules/announcements/types';
import { IResearchFormState } from './modules/researchForm/types';
import { IUpdateAvailableState } from './modules/updateAvailable/types';
import { IConfigurationState } from './modules/accountConfig/configuration/types';
import { INotificationState } from './modules/notification/types';
import { IChatState } from './modules/chat/types';
import { IDependentsState } from './modules/dependents/types';
import { IVacationRequestState } from './modules/request/vacation/request/types';

export interface IState {
  landing: ILandingState;
  auth: IAuthState;
  user: IUserState;
  faq: IFaqState;
  health: IHealthState;
  dental: IDentalState;
  payment: IPaymentState;
  vacation: IVacationState;
  annualIncome: IAnnualIncomeState;
  timeSheet: ITimeSheetState;
  healthDuplicate: IHealthDuplicateState;
  healthDependent: IHealthDependentState;
  inssRequest: IInssRequesState;
  inssSend: IInssSendState;
  employmentRequest: IEmploymentRequestState;
  dentalDuplicate: IDentalDuplicateState;
  dentalDependent: IDentalDependentState;
  transportDuplicate: ITransportDuplicateState;
  errorNetwork: IErrorNetworkState;
  transportNew: INewTransportState;
  transportChange: IChangeTransportState;
  transportCancel: ICancelTransportState;
  trackReopen: ITrackReopenState;
  trackCancel: ITrackCancelState;
  trackChange: ITrackChangeState;
  trackGet: ITrackGetState;
  trackDetails: ITrackDetailsState;
  trackDownloadFile: IDownloadFileState;
  executive: IExecutiveState;
  announcements: IAnnouncementsState;
  researchForm: IResearchFormState;
  updateAvailable: IUpdateAvailableState;
  configuration: IConfigurationState;
  notification: INotificationState;
  dependents: IDependentsState;
  chat: IChatState;
  vacationRequest: IVacationRequestState;
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore(
  persistedReducers(rootReducer),
  composeWithDevTools(applyMiddleware(...middlewares)),
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
