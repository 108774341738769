/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IHealthDependentState, IHealthDependentTypes } from './types';

const INITIAL_STATE: IHealthDependentState = {
  data: {
    optionId: 'Casamento',
    dependents: [],
  },
  loading: false,
  error: null,
};

const healthDependent: Reducer<IHealthDependentState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case IHealthDependentTypes.ADD_DEPENDENT_HEALTH_SET_DATA: {
        draft.data = action.payload.data;
        break;
      }
      case IHealthDependentTypes.ADD_DEPENDENT_HEALTH_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case IHealthDependentTypes.ADD_DEPENDENT_HEALTH_SUCCESS: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      case IHealthDependentTypes.ADD_DEPENDENT_HEALTH_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case IHealthDependentTypes.ADD_DEPENDENT_HEALTH_RESET: {
        draft.data = {
          optionId: 'Casamento',
          dateEvent: undefined,
          current: undefined,
          dependents: [],
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default healthDependent;
