/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';

import {
  DESKTOP_MAIN_HEADER_HEIGHT,
  MAX_WIDTH_TABLET,
  MIN_WIDTH_DESKTOP,
  MOBILE_MAIN_HEADER_HEIGHT,
} from 'utils/constants';

interface IContentButton {
  isProfile: boolean;
  isChat: boolean;
  isMenu: boolean;
  isMobile: boolean;
  disabled: boolean;
}

interface IRow {
  height?: number;
  paddingLeft?: number;
  isSelected?: boolean;
  isOpen?: boolean;
  isPath?: boolean;
}

interface IButton {
  isSelected?: boolean;
  isOpen?: boolean;
}

export const Container = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: ${DESKTOP_MAIN_HEADER_HEIGHT}px;
  background: ${({ theme }) => theme.colors.primary};
  justify-content: space-between;
  color: white;

  padding-left: 70px;
  padding-right: 76px;

  .linkHome {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 77px;
    height: 48px;
    align-self: center;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    padding-left: 20px;
    padding-right: 26px;
    height: ${MOBILE_MAIN_HEADER_HEIGHT}px;

    .logo {
      width: 46px;
      height: 28px;
      min-width: 46px;
    }
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }
  }

  p {
    margin: 0px 6px;
  }

  .icon-menu {
    margin-right: 0px;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    p {
      display: none;
    }

    div {
      margin-right: 17px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const ContentButton = styled.div<IContentButton>`
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: ${({ disabled }) => disabled!==true ? 'pointer' : 'default'};
    outline: inherit;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ isProfile }) =>
    isProfile &&
    css`
      svg {
        border-radius: 50%;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.quaternary};
      }
    `}

  ${({ isMobile, isMenu, isChat }) =>
    ((isMobile && isMenu) || isChat) &&
    css`
      display: none;
    `}

    .ellipse-icon-alert {
    position: absolute;
    overflow: hidden;
    top: -20px;
    right: 148px;
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    ${({ isMobile }) =>
      isMobile
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }

  @media (min-width: ${MIN_WIDTH_DESKTOP}px) {
    .icon-chatCircle {
      /* AGUARDANDO LIBERACAO CHAT NICE */
      *display: none;
    }
  }
`;

export const ContainerEllipse = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 25px;
  right: 147px;

  span {
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    top: 3px;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    top: 17px;
    right: 93px;

    span {
      position: absolute;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;
      top: 2px;
    }
  }
`;

export const Ellipse = styled.img`
  width: 20px;
  height: 20px;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    width: 19px;
    height: 19px;
  }
`;

export const Row = styled.div<IRow>`
  width: 100%;
  height: ${({ height }) => height || 58}px;
  padding: 18px 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 14px;
    color: ${({ theme, isPath }) =>
      isPath ? theme.colors.quaternary : theme.colors.white};
  }

  svg {
    margin-left: 11px;
    transition: transform 0.5s;
    transform: rotate(
      ${({ isSelected, isOpen }) => (isSelected && isOpen ? -180 : 0)}deg
    );
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1000px;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0px 20px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    display: none;
  }
`;

export const WrapperUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0px 6px;

  p {
    text-align: center;
  }

  @media (min-width: ${MIN_WIDTH_DESKTOP}px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: inline-block;
  margin-left: 12px;

  &:last-child {
    margin-right: 12px;
  }
`;

export const Button = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;

  text-transform: uppercase;
`;

export const DropDown = styled.div`
  position: absolute;
  top: 64px;

  min-width: 245px;
  max-height: 184px;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  z-index: 5;
  overflow: hidden;
`;

export const SubButton = styled.button<IButton>`
  display: none;
  padding: 12px 16px;
  border: none;
  background-color: transparent;

  transition: background-color 1s;

  text-transform: uppercase;
  cursor: pointer;

  &:first-child {
    padding-top: 24px;
  }

  &:last-child {
    padding-bottom: 24px;
  }

  &:hover {
    background-color: #fafafa;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `};

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.quaternary};
    `}

  h4 {
    font-size: 14px;
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.quaternary : theme.colors.textPrimary};
  }
`;
