import { IFaq } from 'models/faq';
import { FaqTypes } from './types';

export const loadFaqRequest = () => ({
  type: FaqTypes.LOAD_FAQ_REQUEST,
});

export const loadFaqSuccess = (data: IFaq[]) => ({
  type: FaqTypes.LOAD_FAQ_SUCCESS,
  payload: {
    data,
  },
});

export const loadFaqFailure = () => ({ type: FaqTypes.LOAD_FAQ_FAILURE });
