export const SITE_NAME = 'RH Digital';

export const CD_FILIAL_EXECUTIVE = '01310060';
export const CD_FILIAL_HOSPITAL_SAO_LUCAS = '0000000000';
// export const CD_FILIAL_HOSPITAL_SAO_LUCAS = '04J40001';

// Acessos Rápidos
export const URL_VACANCIES =
  'https://trabalheconosco.vagas.com.br/colaboradoresrededor';
export const URL_PORTAL_TO_KNOW = process.env?.REACT_APP_URL_ACAD_SABER || '';
export const URL_PARTNERSHIP_CLUB = 'https://rededor.clubeben.com.br';
export const URL_MANAGENT_PROGRAM_AVAILABLE = 'https://www.wowlife.com.br';

// Forms
export const URL_FORM_DI = process.env.REACT_APP_URL_FORM_DI ?? '';

// Breakpoints
export const MIN_WIDTH_MOBILESE = 375;
export const MAX_WIDTH_MOBILESE = 375;
export const MIN_WIDTH_MOBILEXS = 375;
export const MAX_WIDTH_MOBILEXS = 414;
export const MIN_WIDTH_MOBILE = 414.2;
export const MAX_WIDTH_MOBILE = 599;
export const MAX_WIDTH_TABLET = 1025;
export const MIN_WIDTH_DESKTOP = 1025;

export const UP_HEADER_HEIGHT = 40;

export const MOBILE_MAIN_HEADER_HEIGHT = 64;
export const DESKTOP_MAIN_HEADER_HEIGHT = 80;

export const MOBILE_HEADER_HEIGHT =
  UP_HEADER_HEIGHT + MOBILE_MAIN_HEADER_HEIGHT;
export const DESKTOP_HEADER_HEIGHT =
  UP_HEADER_HEIGHT + DESKTOP_MAIN_HEADER_HEIGHT;

export const TOKEN_JWT_STORAGE = 'rh-digital:tokenJWT';

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const ACEEPT_FILES =
  'application/pdf, image/jpeg, image/jpg, image/bmp, image/png, capture=environment';

// Manutenção
export const IN_MAINTENANCE = process.env.REACT_APP_IN_MAINTENANCE === 'true';
export const CONECTA_OFFLINE = process.env.REACT_APP_CONECTA_OFFLINE === 'true';
