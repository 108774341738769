/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum ICancelTransportTypes {
  CANCEL_TRANSPORT_SET_DATA = '@request/CANCEL_TRANSPORT_SET_DATA',
  CANCEL_TRANSPORT_REQUEST = '@request/CANCEL_TRANSPORT_REQUEST',
  CANCEL_TRANSPORT_SUCCESS = '@request/CANCEL_TRANSPORT_SUCCESS',
  CANCEL_TRANSPORT_FAILURE = '@request/CANCEL_TRANSPORT_FAILURE',
}

/**
 * State Types
 */

export interface ICancelTransportData {
  file?: File | null;
  type?: string | null;
}

export interface ITransportError {
  status?: number;
  message?: string;
}

export interface ICancelTransportState {
  readonly data?: ICancelTransportData;
  readonly loading?: boolean;
  readonly error?: ITransportError | null;
}
