import { ITrackDetailsError, ITrackDetailsTypes } from './types';
import { ITrackDetails } from '../../../../../models/request/track/details';

export const trackDetailsRequest = ({
  requestNumber,
}: {
  requestNumber: string;
}) => ({
  type: ITrackDetailsTypes.TRACK_DETAILS_REQUEST,
  payload: {
    requestNumber,
  },
});

export const trackDetailsSuccess = (data: ITrackDetails[]) => ({
  type: ITrackDetailsTypes.TRACK_DETAILS_SUCCESS,
  payload: {
    data,
  },
});

export const trackDetailsFailure = (error: ITrackDetailsError) => ({
  type: ITrackDetailsTypes.TRACK_DETAILS_FAILURE,
  payload: {
    error,
  },
});
