import React, { HTMLAttributes } from 'react';

import Icon from 'shared/Icon';
import Spinner from 'shared/Icon/Spinner';
import theme from 'styles/theme';

import { LinkComponent } from './styles';

export interface ILink extends HTMLAttributes<HTMLAnchorElement> {
  href: string;
  color?: string;
  textDecoration?: 'overline' | 'line-through' | 'underline' | 'none';
  iconName?: string;
  loading?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
}

const Link: React.FC<ILink> = ({
  href,
  color = theme.colors.primary,
  textDecoration,
  iconName,
  loading,
  target = '_self',
  ...rest
}) => {
  return (
    <>
      {loading && <Spinner color={color} size="17" />}

      {!loading && iconName && (
        <Icon name={iconName} height="17px" width="17px" color={color} />
      )}

      <LinkComponent
        href={href}
        color={color}
        textDecoration={textDecoration}
        target={target}
        {...rest}
      />
    </>
  );
};

export default Link;
