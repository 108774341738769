/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import IAnnualIncome from 'models/paycheck/annualIncome';

/**
 * Actions Types
 */
export enum AnnualIncomeTypes {
  LOAD_ANNUAL_INCOME_REQUEST = '@annualIncome/LOAD_ANNUAL_INCOME_REQUEST',
  LOAD_ANNUAL_INCOME_SUCCESS = '@annualIncome/LOAD_ANNUAL_INCOME_SUCCESS',
  LOAD_ANNUAL_INCOME_FAILURE = '@annualIncome/LOAD_ANNUAL_INCOME_FAILURE',
  PRINT_ANNUAL_INCOME_REQUEST = '@annualIncome/PRINT_ANNUAL_INCOME_REQUEST',
  PRINT_ANNUAL_INCOME_SUCCESS = '@annualIncome/PRINT_ANNUAL_INCOME_SUCCESS',
  PRINT_ANNUAL_INCOME_FAILURE = '@annualIncome/PRINT_ANNUAL_INCOME_FAILURE',
}

/**
 * State Types
 */
export interface IAnnualIncomeState {
  readonly data: IAnnualIncome | null;
  readonly disabled: boolean;
  readonly printing: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
