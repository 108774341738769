import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from 'services/api';

import { IState } from 'store';

import { IHealth } from 'models/benefits/health';

import { getHealthSuccess, getHealthFailure } from './actions';
import { HealthTypes } from './types';

export function* getHealth() {
  try {
    const getCdMatricula = (state: IState) => state.user.current?.cdMatricula;
    const cdMatricula: string = yield select(getCdMatricula);

    const { data }: AxiosResponse<IHealth> = yield call(
      api.get,
      `/plano-medico/${cdMatricula}`,
    );

    yield put(getHealthSuccess(data));
  } catch (err) {
    if (!!err && !!err?.response && err.response?.status === 404) {
      yield put(getHealthFailure({ haveInsurance: false }));

      return;
    }

    yield put(getHealthFailure({ haveInsurance: null }));
  }
}

export default all([takeLatest(HealthTypes.GET_HEALTH_REQUEST, getHealth)]);
