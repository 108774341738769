import styled from 'styled-components';
import backgroundImage from 'assets/images/background.jpg';
import { MAX_WIDTH_MOBILE } from 'utils/constants';

export const WrapperLoader = styled.div`
  padding-top: 80px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 112px 29px 29px;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  p {
    font-size: 16px;
  }

  span {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    padding: 64px 0px 0px 0px;
    max-height: 100%;
    height: 100%;
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  text-align: center;
  button {
    &:first-child {
      margin: 0px 0px 24px 0px;
      height: 48px;
      width: 100%;
      font-size: 14px;
      text-align: center;
      background: #de1306;
      border: none;

      :hover {
        background: #a80f04;
        border-color: #a80f04;
      }

      @media (max-width: ${MAX_WIDTH_MOBILE}px) {
        width: 100%;
      }
    }
  }
`;
export const MessageContent = styled.div`
  max-width: 360px;
  margin-top: 13px;
  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    display: none;
  }
`;

export const Panel = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  padding: 30px;
  height: fit-content;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 4px 4px 0 0;
  border-bottom: 7px solid ${({ theme }) => theme.colors.primary};
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.16);

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    max-width: 100%;
    max-height: 100%;
    height: 100vh;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const PanelHeader = styled.div`
  margin-bottom: 30px;
  width: 260px;

  h3 {
    line-height: 135%;
    font-size: 18px;
    margin-bottom: 20px;
  }

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-bottom: 43px;
    width: 237px;
    height: 72px;
    h3 {
      font-size: 16px;
    }
  }
`;

export const PanelTitle = styled.div`
  line-height: 135%;
`;

export const PanelContentPrimary = styled.div`
  margin-bottom: 23px;
  line-height: 135%;

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-bottom: 18px;

    p {
      font-size: 14px;
    }
  }
`;

export const PanelContentSecundary = styled.div`
  margin-bottom: 23px;
  line-height: 135%;

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-bottom: 40px;

    p {
      font-size: 14px;
    }
  }
`;

export const PanelInformationPrimary = styled.div`
  width: 260px;
  margin-bottom: 24px;
  h3 {
    color: #4f4f4f;
  }
`;

export const PanelInformationSecundary = styled.div`
  margin-bottom: 17px;
  width: 260px;
  span {
    color: #4f4f4f;
  }

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    margin-bottom: 28px;
  }
`;

export const ButtonLinkStyle = styled.div`
  margin-bottom: 32.8px;

  > button {
    font-size: 14px !important;
  }

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    > button {
      font-size: 14px !important;
    }
  }
`;

export const ContainerPopUpAlert = styled.div``;

export const ButtonClose = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;

  background: transparent;
  border: none;

  @media (max-width: ${MAX_WIDTH_MOBILE}px) {
    right: 30px;
    top: 30px;
  }
`;
