/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { ILandingContent } from 'models/landing';

/**
 * Actions Types
 */
export enum LandingTypes {
  SET_FLOATING_BALLOON = '@landing/SET_FLOATING_BALLOON',
  SET_DEFERRED_PROMPT = '@landing/SET_DEFERRED_PROMPT',
  GET_LANDING_CONTENT_REQUEST = '@landing/GET_LANDING_CONTENT_REQUEST',
  GET_LANDING_CONTENT_SUCCESS = '@landing/GET_LANDING_CONTENT_SUCCESS',
  GET_LANDING_CONTENT_FAILURE = '@landing/GET_LANDING_CONTENT_FAILURE',
}

/**
 * State Types
 */

export interface IError {
  status: number;
  message: string;
}

export interface ILandingState {
  readonly showFloatingBalloon: boolean;
  readonly deferredPrompt: any;
  readonly loading: boolean;
  readonly data: ILandingContent[];
  readonly error: IError | null;
}
