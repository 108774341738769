/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IVacationRequestState, VacationRequestTypes } from './types';

const INITIAL_STATE: IVacationRequestState = {
  data: [],
  loading: false,
  error: false,
};

const VacationRequest: Reducer<IVacationRequestState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case VacationRequestTypes.VACATION_REQUEST_REQUEST: {
        draft.loading = true;
        break;
      }
      case VacationRequestTypes.VACATION_REQUEST_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case VacationRequestTypes.VACATION_REQUEST_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case VacationRequestTypes.VACATION_REQUEST_RESET: {
        draft.loading = false;
        draft.error = false;
        break;
      }
      default:
        break;
    }
  });
};

export default VacationRequest;
