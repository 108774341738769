import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import api from 'services/api';

import { MONTHS } from 'utils/constants';
import { sortPerDate } from 'utils/general';

import {
  IPrintTimeSheetRequest,
  ITimeSheet,
  ITimeSheetRequest,
} from 'models/timeSheet';

import { IState } from 'store';
import { TimeSheetTypes } from './types';

import {
  loadTimeSheetFailure,
  loadTimeSheetSuccess,
  printTimeSheetFailure,
  printTimeSheetRequest,
  printTimeSheetSuccess,
} from './actions';

type printTimeSheetRequestType = ReturnType<typeof printTimeSheetRequest>;

function sortTimeSheetData(newData: ITimeSheet[]): ITimeSheet[] {
  const sortedData = newData.slice().sort((itemA, itemB) => {
    const dateA = new Date(`${itemA.anoPonto}-${itemA.mesPonto}-05`);
    const dateB = new Date(`${itemB.anoPonto}-${itemB.mesPonto}-05`);

    return sortPerDate(dateA, dateB);
  });

  return sortedData;
}

export function* loadTimeSheet() {
  try {
    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getMatricula);

    const { data }: AxiosResponse<ITimeSheetRequest[]> = yield call(
      api.get,
      `/espelho-ponto/${inscription}`,
    );

    const newData: ITimeSheet[] = data.map((timeSheet) => ({
      ...timeSheet,
      id:
        timeSheet.mesPonto && timeSheet.anoPonto
          ? `${timeSheet.mesPonto}/${timeSheet.anoPonto}`
          : '-',
      title:
        timeSheet.mesPonto && timeSheet.anoPonto
          ? `${MONTHS[Number(timeSheet.mesPonto) - 1]} ${timeSheet.anoPonto}`
          : 'Espelho de Ponto - Dados inconsistentes',
      anoPonto: timeSheet.anoPonto ? String(timeSheet.anoPonto) : '-',
      mesPonto: timeSheet.mesPonto ? String(timeSheet.mesPonto) : '-',
      saldoAnterior: timeSheet.saldoAnterior
        ? String(timeSheet.saldoAnterior)
        : '-',
      credito: timeSheet.credito ? String(timeSheet.credito) : '-',
      debito: timeSheet.debito ? String(timeSheet.debito) : '-',
      saldoAtual: timeSheet.saldoAtual ? String(timeSheet.saldoAtual) : '-',
    }));

    yield put(loadTimeSheetSuccess(sortTimeSheetData(newData)));
  } catch (err) {
    if (!!err && !!err?.response && err.response?.status === 404) {
      yield put(loadTimeSheetSuccess([]));

      return;
    }

    yield put(loadTimeSheetFailure());
  }
}

export function* printTimeSheet({ payload }: printTimeSheetRequestType) {
  try {
    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const inscription: string = yield select(getMatricula);

    const { competence } = payload;

    const { data }: AxiosResponse<IPrintTimeSheetRequest> = yield call(
      api.post,
      '/espelho-ponto-pdf',
      {
        cdMatricula: inscription,
        competencia: competence,
      },
    );

    const { encodedFile, message } = data;

    let fileName: string;

    if (message && message.toLowerCase().includes('erro')) {
      throw message;
    }

    const dataLayer = window.dataLayer || [];

    const currentDate = format(new Date(), 'ddMMyy');
    const currentTime = format(new Date(), 'hhmmss');

    if (competence && competence.length > 1) {
      // Many files
      const lastCompetence = competence[0];
      const firstCompetence = competence.pop();

      fileName = `Espelho de Ponto - ${inscription} - ${firstCompetence?.title} - ${lastCompetence?.title} - ${currentDate}${currentTime}.pdf`;

      dataLayer.push({
        event: 'multDownloadTimeSheet',
      });
    } else {
      // Only one file

      fileName = `Espelho de Ponto - ${inscription} - ${
        competence && competence[0]?.title
      } - ${currentDate}${currentTime}.pdf`;

      dataLayer.push({
        event: 'downloadTimeSheet',
        file: fileName, // Passar nome do arquivo baixado
      });
    }

    const downloadLink = document.createElement('a');

    downloadLink.href = encodedFile;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);

    downloadLink.click();
    document.body.removeChild(downloadLink);

    yield put(printTimeSheetSuccess());
  } catch (err) {
    // TODO Remover quando o UX fizer uma tela para cenário de tratativa de erro
    // eslint-disable-next-line no-console
    console.log('Erro: ', err);
    yield put(printTimeSheetFailure());
  }
}

export default all([
  takeLatest(TimeSheetTypes.LOAD_TIME_SHEET_REQUEST, loadTimeSheet),
  takeLatest(TimeSheetTypes.PRINT_TIME_SHEET_REQUEST, printTimeSheet),
]);
