/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum IDownloadFileTypes {
  DOWNLOAD_FILE_REQUEST = '@request/DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_REQUEST_ACTIONS = '@request/DOWNLOAD_FILE_REQUEST_ACTIONS',
  DOWNLOAD_FILE_SUCCESS = '@request/DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE = '@request/DOWNLOAD_FILE_FAILURE',
}

/**
 * State Types
 */
export interface IDownloadFileError {
  status?: number;
  message?: string;
}

export interface IDownloadFileState {
  readonly loading: boolean;
  readonly error: IDownloadFileError | null;
}
