/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IDentalDependent } from 'models/request/dental/duplicate';

/**
 * Actions Types
 */
export enum IDentalDuplicateTypes {
  DENTAL_DUPLICATE_SET_DATA = '@request/DENTAL_DUPLICATE_SET_DATA',
  DENTAL_DUPLICATE_REQUEST = '@request/DENTAL_DUPLICATE_REQUEST',
  DENTAL_DUPLICATE_SUCCESS = '@request/DENTAL_DUPLICATE_SUCCESS',
  DENTAL_DUPLICATE_FAILURE = '@request/DENTAL_DUPLICATE_FAILURE',
  DENTAL_DUPLICATE_RESET = '@request/DENTAL_DUPLICATE_RESET',
}

/**
 * State Types
 */

export interface IDentalData {
  dependents?: IDentalDependent[];
  optionId?: string;
}

export interface IDentalError {
  status?: number;
  message?: string;
}

export interface IDentalDuplicateState {
  readonly data: IDentalData | null;
  readonly loading: boolean;
  readonly error: IDentalError | null;
}
