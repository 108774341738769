/* eslint-disable no-unused-vars */
import axios, { AxiosResponse } from 'axios';

import { IState, store } from 'store';
import { logoutRequest } from 'store/modules/auth/actions';

import history from 'services/history';
import { errorNetwork } from 'store/modules/errorNetWork/actions';
import { TOKEN_JWT_STORAGE } from 'utils/constants';
import { isProductionEnv } from 'utils/general';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    client_id: process.env.REACT_APP_CLIENT_ID,
    'Content-Type': 'application/json',
    'x-origin-channel': '1',
    'x-origin-application': '1',
  },
});

export async function checkEmployeeConecta(cpf: string): Promise<{ isExists: boolean, error: boolean | null }> {
  // if (isProductionEnv() || ) {
    return { isExists: true, error: false }; //Desabilitar Producao
  // }
  // try {
  //   const { data, status }: any = await api.post(
  //     '/portal-conecta/verificar-cadastro',
  //     { cpf },
  //   );
  //   const error = status !== 200;
  //   return { isExists: (!error && data?.existeCadastro === true), error };
  // } catch (e: any) {
  //   const error = e?.response.status ? e?.response.status !== 404 : null;
  //   return { isExists: false, error };
  // }
}

function checkToken() {
  // Tenta buscar a data de expiração do localStorage
  let tokenJWT = window.localStorage.getItem(TOKEN_JWT_STORAGE);

  // SE não conseguir capturar do localStorage, tenta do sessionStorage
  if (!tokenJWT) {
    tokenJWT = window.sessionStorage.getItem(TOKEN_JWT_STORAGE);
  }

  // Se não retornou de nenhum dos dois, faz logout
  if (!tokenJWT) {
    store.dispatch(logoutRequest());
  }
}

const responseHandler = (response: AxiosResponse<unknown>) => {
  const { auth }: IState = store.getState();

  if (auth.signed) {
    checkToken();
  }

  return response;
};

const errorHandler = (error: {
  response: { data: { message: string }; status: number };
}) => {
  if (String(error) === 'Error: Network Error') {
    store.dispatch(errorNetwork({ errorNetworkState: true }));
  }

  const { auth }: IState = store.getState();
  const { status } = error.response;
  const { message } = error.response.data;

  if (
    (status === 401 || status === 403) &&
    message !== 'Usuário não possui celular cadastrado'
  ) {
    // Se o usuário tiver sido bloqueado, faz logout se estiver logado e manda para tela de usuário bloqueado
    if (
      message.toLocaleLowerCase() === 'usuário bloqueado' ||
      message.toLocaleLowerCase() === 'usuario bloqueado'
    ) {
      if (auth.signed) {
        store.dispatch(logoutRequest('blockedAccess'));
      } else {
        history.push('/blocked_access');
      }
      return Promise.reject(error);
    }

    store.dispatch(logoutRequest('tokenExpired'));
  }

  return Promise.reject(error);
};

api.interceptors.response.use(
  (response) => responseHandler(response),
  async (error) => errorHandler(error),
);

export default api;
