import { IDevice } from 'models/user/device';
import { IError, AuthTypes } from './types';

export const setRememberMe = ({ isRememberMe }: { isRememberMe: boolean }) => ({
  type: AuthTypes.SET_REMEMBER_ME,
  payload: {
    isRememberMe,
  },
});

export type TokenMethodType = 'sms' | 'email';

export const setSendTokenMethod = ({
  sendTokenMethod,
}: {
  sendTokenMethod: TokenMethodType;
}) => ({
  type: AuthTypes.SET_SEND_TOKEN_METHOD,
  payload: {
    sendTokenMethod,
  },
});

export const sendTokenRequest = ({
  cpf,
  resendToken = false,
  method,
  handleNextStep,
  captcha,
}: {
  cpf: string;
  resendToken?: boolean;
  method?: TokenMethodType;
  handleNextStep: () => void;
  captcha: string | null | undefined;
}) => ({
  type: AuthTypes.SEND_TOKEN_REQUEST,
  payload: {
    cpf,
    resendToken,
    method,
    handleNextStep,
    captcha,
  },
});

export const setCurrentStep = (step: number) => ({
  type: AuthTypes.CURRENT_STEP,
  payload: {
    step,
  },
});

export const setIsPhoneUpdate = (isPhoneUpdate: boolean) => ({
  type: AuthTypes.PHONE_UPDATE,
  payload: {
    isPhoneUpdate,
  },
});

export const setIsPhoneUpdateSupport = (isPhoneUpdateSupport: boolean) => ({
  type: AuthTypes.PHONE_UPDATE_SUPPORT,
  payload: {
    isPhoneUpdateSupport,
  },
});

export const sendTokenSuccess = ({
  cel,
  email,
}: {
  cel?: string;
  email?: string;
}) => ({
  type: AuthTypes.SEND_TOKEN_SUCCESS,
  payload: {
    ...(cel ? { cel } : {}),
    ...(email ? { email } : {}),
  },
});

export const sendTokenFailure = (error: IError) => ({
  type: AuthTypes.SEND_TOKEN_FAILURE,
  payload: {
    error,
  },
});

export const checkTokenRequest = ({ code }: { code: string }) => ({
  type: AuthTypes.CHECK_TOKEN_REQUEST,
  payload: {
    code,
  },
});

export const checkTokenSuccess = (data: {
  tokenJWT: string;
  idDevice: string;
  acceptedPrivacy: boolean;
}) => ({
  type: AuthTypes.CHECK_TOKEN_SUCCESS,
  payload: data,
});

export const checkTokenFailure = (error: IError) => ({
  type: AuthTypes.CHECK_TOKEN_FAILURE,
  payload: {
    error,
  },
});

export const addPrivacyRequest = () => ({
  type: AuthTypes.ADD_PRIVACY_REQUEST,
});

export const addPrivacySuccess = () => ({
  type: AuthTypes.ADD_PRIVACY_SUCCESS,
});

export const addPrivacyFailure = (error: IError) => ({
  type: AuthTypes.ADD_PRIVACY_FAILURE,
  payload: {
    error,
  },
});

export const validateToken = () => ({
  type: AuthTypes.VALIDATE_TOKEN,
});

export const logoutRequest = (reason?: 'tokenExpired' | 'blockedAccess') => ({
  type: AuthTypes.LOGOUT_REQUEST,
  payload: {
    reason,
  },
});

export const logoutSuccess = () => ({
  type: AuthTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = () => ({ type: AuthTypes.LOGOUT_FAILURE });

export const resetError = () => ({ type: AuthTypes.RESET_ERROR });

export const getDeviceInfoRequest = (deviceId: string) => ({
  type: AuthTypes.GET_DEVICE_INFO_REQUEST,
  payload: {
    deviceId,
  },
});

export const getDeviceInfoSuccess = (deviceInfo: IDevice) => ({
  type: AuthTypes.GET_DEVICE_INFO_SUCCESS,
  payload: {
    deviceInfo,
  },
});

export const getDeviceInfoFailure = (error: string) => ({
  type: AuthTypes.GET_DEVICE_INFO_FAILURE,
  payload: {
    error,
  },
});

export const resetAuth = () => ({
  type: AuthTypes.RESET_AUTH,
});

export interface SendPhoneUpdateRequestProps {
  celular: string;
  telAlternativo: string;
  nomeCompleto: string;
  cpf: string;
  matricula: string;
  dataNasc: Date | string;
  nomeMae: string;
  anexoDoc?: any;
  anexoDocSelf?: any;
  anexo?: any;
}

export const sendPhoneUpdateRequest = (datas: SendPhoneUpdateRequestProps) => ({
  type: AuthTypes.PHONE_UPDATE_REQUEST,
  payload: datas,
});

export const sendPhoneUpdateSuccess = () => ({
  type: AuthTypes.PHONE_UPDATE_SUCCESS,
});
export const sendPhoneUpdateFailure = (error: IError) => ({
  type: AuthTypes.PHONE_UPDATE_FAILURE,
  payload: {
    error,
  },
});
