import { all, takeLatest, put, call } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import api from 'services/api';

import {
  downloadFileRequest,
  downloadFileRequestActions,
  downloadFileSuccess,
  downloadFileFailure,
} from './actions';

import { IDownloadFileTypes } from './types';

type IDownloadFileType = ReturnType<typeof downloadFileRequest>;

export function* downloadFile({ payload }: IDownloadFileType) {
  try {
    const { requestNumber, fileName, fileType, actionNumber } = payload;

    const formData = new FormData();
    formData.append('chamado', requestNumber);
    formData.append('nomeArquivo', fileName);
    formData.append('tipoAnexo', fileType);
    formData.append('numeroAcao', actionNumber);

    const { data }: AxiosResponse = yield call(
      api.post,
      '/portal-conecta/download-anexo-chamados',
      formData,
    );

    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/octet-stream;base64,${data.response.returnData}`;
    downloadLink.download = fileName;
    downloadLink.click();

    yield put(downloadFileSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(downloadFileFailure(response));
    }
  }
}

type IDownloadFileActionType = ReturnType<typeof downloadFileRequestActions>;

export function* downloadFileActions({ payload }: IDownloadFileActionType) {
  try {
    const { requestNumber, fileType, attachmentActions } = payload;

    const formData = new FormData();
    formData.append('nomeArquivo', attachmentActions);
    formData.append('tipoAnexo', fileType);
    formData.append('chamado', requestNumber);

    const { data }: AxiosResponse = yield call(
      api.post,
      '/portal-conecta/download-anexo-chamados',
      formData,
    );

    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/octet-stream;base64,${data.response.returnData}`;
    downloadLink.download = attachmentActions;
    downloadLink.click();

    yield put(downloadFileSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(downloadFileFailure(response));
    }
  }
}

export default all([
  takeLatest(IDownloadFileTypes.DOWNLOAD_FILE_REQUEST, downloadFile),
  takeLatest(
    IDownloadFileTypes.DOWNLOAD_FILE_REQUEST_ACTIONS,
    downloadFileActions,
  ),
]);
