/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 * Actions Types
 */
export enum ITrackCancelTypes {
  TRACK_CANCEL_REQUEST = '@request/TRACK_CANCEL_REQUEST',
  TRACK_CANCEL_SUCCESS = '@request/TRACK_CANCEL_SUCCESS',
  TRACK_CANCEL_FAILURE = '@request/TRACK_CANCEL_FAILURE',
}

/**
 * State Types
 */
export interface ITrackCancelData {
  called: string;
  description: string;
  file?: File[];
}

export interface ITrackCancelError {
  status?: number;
  message?: string;
  success?: boolean;
}

export interface ITrackCancelState {
  readonly data: ITrackCancelData;
  readonly loading: boolean;
  readonly error: ITrackCancelError | null;
}
