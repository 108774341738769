import INotificationData from 'models/notification';
import { INotificationTypes, INotificationError } from './types';

export const getNotificationAlertRequest = () => ({
  type: INotificationTypes.NOTIFICATION_ALERT_GET_REQUEST,
});

export const getNotificationAlertSuccess = (
  dataNotificationAlert: INotificationData | null,
) => ({
  type: INotificationTypes.NOTIFICATION_ALERT_GET_SUCCESS,
  payload: {
    dataNotificationAlert,
  },
});

export const getNotificationAlertFailure = (error: INotificationError) => ({
  type: INotificationTypes.NOTIFICATION_ALERT_GET_FAILURE,
  payload: {
    error,
  },
});

export const getAllNotificationRequest = () => ({
  type: INotificationTypes.NOTIFICATION_ALL_GET_REQUEST,
});

export const getAllNotificationSuccess = ({
  numberClosedNotification,
}: {
  numberClosedNotification: number;
}) => ({
  type: INotificationTypes.NOTIFICATION_ALL_GET_SUCCESS,
  payload: {
    numberClosedNotification,
  },
});

export const getAllNotificationFailure = (error: INotificationError) => ({
  type: INotificationTypes.NOTIFICATION_ALL_GET_FAILURE,
  payload: {
    error,
  },
});

export const getNotificationRequest = ({
  page,
  limit,
}: {
  page: number;
  limit: number;
}) => ({
  type: INotificationTypes.NOTIFICATION_GET_REQUEST,
  payload: {
    page,
    limit,
  },
});

export const getNotificationSuccess = (
  dataNotification: INotificationData | null,
) => ({
  type: INotificationTypes.NOTIFICATION_GET_SUCCESS,
  payload: {
    dataNotification,
  },
});

export const getNotificationFailure = (error: INotificationError) => ({
  type: INotificationTypes.NOTIFICATION_GET_FAILURE,
  payload: {
    error,
  },
});

export const getNotificationReset = () => ({
  type: INotificationTypes.NOTIFICATION_GET_RESET,
});

export const putNotificationOpen = ({
  idNotification,
}: {
  idNotification: string;
}) => ({
  type: INotificationTypes.NOTIFICATION_PUT_OPEN_REQUEST,
  payload: {
    idNotification,
  },
});

export const putNotificationSuccess = () => ({
  type: INotificationTypes.NOTIFICATION_PUT_OPEN_SUCCESS,
});

export const putNotificationFailure = (error: INotificationError) => ({
  type: INotificationTypes.NOTIFICATION_PUT_OPEN_FAILURE,
  payload: {
    error,
  },
});
