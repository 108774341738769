/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateAvailable } from 'store/modules/updateAvailable/actions';
import Text from 'shared/Tipografia/Text';
import Button from 'shared/Button';
import { saveVersion } from 'utils/general';
import { Container } from './styles';

interface IUpdateAvailable {
  update: boolean;
  downloadUpdates: boolean;
}

const UpdateAvailable: React.FC<IUpdateAvailable> = ({
  update,
  downloadUpdates,
}) => {
  const dispatch = useDispatch();

  function handleReload() {
    dispatch(updateAvailable({ update: false, downloadUpdates: false }));
    saveVersion();
    window.location.reload();
  }

  return (
    <Container id="containerUpdateAvailable">
      {update && downloadUpdates && (
        <Text as="disclaimer">
          Baixando uma nova versão do aplicativo. Aguarde...
        </Text>
      )}
      {update && !downloadUpdates && (
        <>
          <Text as="disclaimer">Atualização concluída.</Text>
          <Button onClick={() => handleReload()}>CLIQUE AQUI</Button>
        </>
      )}
    </Container>
  );
};

export default UpdateAvailable;
