/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

/**
 * Actions Types
 */
export enum ChatTypes {
  CHAT_LOADED = '@chat/CHAT_LOADED',
  CHAT_TOKEN = '@chat/CHAT_TOKEN',
  CHAT_OPEN_WINDOW = '@chat/CHAT_OPEN_WINDOW',
}


export enum ChatChannels {
  //@ts-ignore
  Default = process.env.REACT_APP_CHAT_BRAND_HASH,
  //@ts-ignore
  Executive = process.env.REACT_APP_CHAT_BRAND_HASH_EXECUTIVE,
}


/**
 * State Types
 */
export interface IChatState {
  readonly loaded: boolean;
  readonly token: ChatChannels;
  readonly openWindow: boolean;
}
