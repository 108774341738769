import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IState } from 'store';
import { IUpdateAvailableState } from 'store/modules/updateAvailable/types';
import { IAuthState } from 'store/modules/auth/types';
import MainHeader from 'shared/Header/MainHeader';
import UpHeader from 'shared/Header/UpHeader';
import UpdateAvailable from 'shared/UpdateAvailable';

import { Container, Wrapper } from './styles';

const Auth: React.FC = ({ children }) => {
  const { update, downloadUpdates } = useSelector<
    IState,
    IUpdateAvailableState
  >((state) => state.updateAvailable);

  const { isPhoneUpdate } = useSelector<IState, IAuthState>(
    (state) => state.auth,
  );

  useEffect(() => {
    function setCustomViewportProperty() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setCustomViewportProperty();
  });

  return (
    <Container update={update} isPhoneUpdate={isPhoneUpdate}>
      {update && (
        <UpdateAvailable update={update} downloadUpdates={downloadUpdates} />
      )}
      {isPhoneUpdate === false && <UpHeader />}
      <MainHeader />
      <Wrapper update={update} isPhoneUpdate={isPhoneUpdate}>
        {children}
      </Wrapper>
    </Container>
  );
};

export default Auth;
