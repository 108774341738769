/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IAuthState, AuthTypes } from './types';

const INITIAL_STATE: IAuthState = {
  currentStep: 0,
  isPhoneUpdate: false,
  isPhoneUpdateSupport: false,
  cpf: '',
  cel: '',
  email: '',
  tokenJWT: '',
  idDevice: '',
  acceptedPrivacy: false,
  rememberMe: false,
  signed: false,
  tokenSubmitted: false,
  validatedSMS: false,
  addingPrivacy: false,
  loadingToken: false,
  loading: false,
  error: null,
  device: null,
  loadingPhoneUpdate: false,
  phoneUpdateSuccess: false,
};

const auth: Reducer<IAuthState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case AuthTypes.SET_REMEMBER_ME: {
        draft.rememberMe = action.payload.isRememberMe;
        break;
      }
      case AuthTypes.CURRENT_STEP: {
        draft.currentStep = action.payload.step;
        break;
      }
      case AuthTypes.PHONE_UPDATE: {
        draft.isPhoneUpdate = action.payload.isPhoneUpdate;
        draft.error = null;
        break;
      }
      case AuthTypes.PHONE_UPDATE_SUPPORT: {
        draft.isPhoneUpdate = true;
        draft.isPhoneUpdateSupport = action.payload.isPhoneUpdateSupport;
        draft.error = null;
        break;
      }
      case AuthTypes.PHONE_UPDATE_LOADING: {
        draft.loadingPhoneUpdate = action.payload.loadingPhoneUpdate;
        draft.error = null;
        break;
      }
      case AuthTypes.SEND_TOKEN_REQUEST: {
        draft.cpf = action.payload.cpf;
        draft.loadingToken = true;
        break;
      }
      case AuthTypes.SEND_TOKEN_SUCCESS: {
        draft.cel = action.payload.cel;
        draft.email = action.payload.email;
        draft.tokenSubmitted = true;
        draft.loadingToken = false;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case AuthTypes.SEND_TOKEN_FAILURE: {
        draft.cpf = '';
        draft.loadingToken = false;
        draft.error = action.payload.error;
        break;
      }
      case AuthTypes.CHECK_TOKEN_REQUEST: {
        draft.loading = true;
        break;
      }
      case AuthTypes.CHECK_TOKEN_SUCCESS: {
        draft.tokenJWT = action.payload.tokenJWT;
        draft.idDevice = action.payload.idDevice;
        draft.acceptedPrivacy = action.payload.acceptedPrivacy;
        draft.signed = true;
        draft.loadingToken = false;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case AuthTypes.CHECK_TOKEN_FAILURE: {
        draft.validatedSMS = false;
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case AuthTypes.CHECK_PRIVACY_REQUEST: {
        draft.loadingToken = true;
        break;
      }
      case AuthTypes.CHECK_PRIVACY_SUCCESS: {
        draft.acceptedPrivacy = action.payload.acceptedPrivacy;
        draft.loadingToken = false;
        draft.error = null;
        break;
      }
      case AuthTypes.CHECK_PRIVACY_FAILURE: {
        draft.acceptedPrivacy = false;
        draft.loadingToken = false;
        draft.error = action.payload.error;
        break;
      }
      case AuthTypes.ADD_PRIVACY_REQUEST: {
        draft.addingPrivacy = true;
        break;
      }
      case AuthTypes.ADD_PRIVACY_SUCCESS: {
        draft.acceptedPrivacy = true;
        draft.addingPrivacy = false;
        draft.error = null;
        break;
      }
      case AuthTypes.ADD_PRIVACY_FAILURE: {
        draft.addingPrivacy = false;
        draft.error = action.payload.error;
        break;
      }
      case AuthTypes.VALIDATE_TOKEN: {
        draft.validatedSMS = true;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case AuthTypes.LOGOUT_REQUEST: {
        break;
      }
      case AuthTypes.LOGOUT_SUCCESS: {
        draft.currentStep = 0;
        draft.cpf = '';
        draft.cel = '';
        draft.tokenJWT = '';
        draft.idDevice = '';
        draft.acceptedPrivacy = false;
        draft.rememberMe = false;
        draft.signed = false;
        draft.tokenSubmitted = false;
        draft.validatedSMS = false;
        draft.addingPrivacy = false;
        draft.loadingToken = false;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case AuthTypes.LOGOUT_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case AuthTypes.GET_DEVICE_INFO_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case AuthTypes.GET_DEVICE_INFO_SUCCESS: {
        draft.device = action.payload.deviceInfo;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case AuthTypes.GET_DEVICE_INFO_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case AuthTypes.PHONE_UPDATE_SUCCESS: {
        draft.phoneUpdateSuccess = true;
        draft.loadingPhoneUpdate = false;
        break;
      }
      case AuthTypes.PHONE_UPDATE_FAILURE: {
        draft.loadingPhoneUpdate = false;
        draft.error = action.payload.error;
        break;
      }
      case AuthTypes.RESET_ERROR: {
        draft.loadingToken = false;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case AuthTypes.RESET_AUTH: {
        draft.currentStep = 0;
        draft.isPhoneUpdate = false;
        draft.isPhoneUpdateSupport = false;
        draft.loadingPhoneUpdate = false;
        draft.phoneUpdateSuccess = false;
        draft.cpf = '';
        draft.cel = '';
        draft.tokenJWT = '';
        draft.idDevice = '';
        draft.acceptedPrivacy = false;
        draft.rememberMe = false;
        draft.signed = false;
        draft.tokenSubmitted = false;
        draft.validatedSMS = false;
        draft.addingPrivacy = false;
        draft.loadingToken = false;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case AuthTypes.SET_SEND_TOKEN_METHOD: {
        draft.sendTokenMethod = action.payload.sendTokenMethod;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default auth;
