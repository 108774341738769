/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import api from 'services/api';

import { IState } from 'store';
import { ICancelTransportData, ICancelTransportTypes } from './types';

import {
  cancelTransportRequest,
  cancelTransportSuccess,
  cancelTransportFailure,
} from './actions';

type ICancelTransportType = ReturnType<typeof cancelTransportRequest>;

export function* cancelTransport({ payload }: ICancelTransportType) {
  try {
    const { type } = payload;

    const getData = (state: IState) => state.transportCancel.data;
    const data: ICancelTransportData = yield select(getData);

    const defineDescricaoMotivo = () => {
      switch (type) {
        case 'Perda':
          return 'Perda do cartão';
        case 'Avaria':
          return 'Quebra ou Avaria';
        case 'Roubo':
          return 'Roubo ou Furto';
        case 'Outros':
          return 'Não desejo mais VT';
        default:
          return '';
      }
    };

    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const matricula: string = yield select(getMatricula);

    const formData = new FormData();

    formData.append('matricula', matricula);
    formData.append('motivo', defineDescricaoMotivo());
    formData.append('anexo', data.file || '');

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `Saúde - Segunda Via - ${
        type !== 'Outros'
          ? defineDescricaoMotivo()
          : `Outros - ${defineDescricaoMotivo()}`
      }`,
    });

    yield call(api.delete, '/portal-conecta/vale-transporte', {
      data: formData,
    });
    yield put(cancelTransportSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        message: err.response?.data.message,
      };

      yield put(cancelTransportFailure(response));
      return;
    }

    yield put(
      cancelTransportFailure({
        message: 'Erro interno de servidor',
      }),
    );
  } finally {
    const { handleClickNextStep } = payload;
    handleClickNextStep();
  }
}

export default all([
  takeLatest(ICancelTransportTypes.CANCEL_TRANSPORT_REQUEST, cancelTransport),
]);
