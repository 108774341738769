import { AxiosResponse } from 'axios';
import { all, put, takeLatest, call } from 'redux-saga/effects';

import { 
  IAnnualIncome,
  IAnnualIncomeRequest,
  IPrintAnnualIncomeRequest
} from 'models/paycheck/annualIncome';

import api from 'services/api';

import { 
  loadAnnualIncomeSuccess, 
  loadAnnualIncomeFailure, 
  printAnnualIncomeRequest,
  printAnnualIncomeSuccess,
  printAnnualIncomeFailure } from './actions';

import { AnnualIncomeTypes } from './types';

type printAnnualIncomeRequestType = ReturnType<typeof printAnnualIncomeRequest>;

function sortAnnualIncomeData(newData: IAnnualIncome[]): IAnnualIncome[] {
  const sortedData = newData.sort((itemA, itemB) => {
    return itemB.nrAnoBase - itemA.nrAnoBase;
  });

  return sortedData;
}

export function* loadAnnualIncome() {
  try {
    const { data }: AxiosResponse<IAnnualIncomeRequest[]> = yield call(
      api.get,
      `/informe-rendimento`,
    );

    const newData : IAnnualIncome[] = data.map((annualIncome) => ({
      ...annualIncome,
      id: `${annualIncome.nrAnoBase}${annualIncome.cdCNPJPagador}`,
      title: `ANO BASE ${annualIncome.nrAnoBase}`,
      subTitle: `${annualIncome.nmEmpresaPagador}`
    }));

    yield put(loadAnnualIncomeSuccess(sortAnnualIncomeData(newData)));
  } catch (err: any) {
    if (!!err && !!err?.response && err.response?.status === 404) {
      yield put(loadAnnualIncomeSuccess([]));
      return;
    }

    yield put(loadAnnualIncomeFailure());
  }
}

export function* printAnnualIncome({ payload }: printAnnualIncomeRequestType) {
  try {
    const { data }: AxiosResponse<IPrintAnnualIncomeRequest[]> = yield call(
      api.post,
      '/informe-rendimento-pdf',
      payload,
    );

    const dataLayer = window.dataLayer || [];

    data.forEach((item, index) => {
        const { encodedFile, message } = item;

        if (message && message.toLowerCase().includes('erro')) {
          throw message;
        }

        dataLayer.push({
          event: 'downloadIncome',
          file: item.fileName,
        });

        const downloadLink = document.createElement('a');

        downloadLink.href = `data:application/octet-stream;base64,${encodedFile}`;
        downloadLink.download = item.fileName;
        document.body.appendChild(downloadLink);

        downloadLink.click();
        document.body.removeChild(downloadLink);
    })
    
    yield put(printAnnualIncomeSuccess());

  } catch (err) {
    // TODO Remover quando o UX fizer uma tela para cenário de tratativa de erro
    // eslint-disable-next-line no-console
    console.log('Erro: ', err);
    yield put(printAnnualIncomeFailure());
  }
}

export default all([
  takeLatest(AnnualIncomeTypes.LOAD_ANNUAL_INCOME_REQUEST, loadAnnualIncome),
  takeLatest(AnnualIncomeTypes.PRINT_ANNUAL_INCOME_REQUEST, printAnnualIncome),
]);
