import { ITrackReopenError, ITrackReopenTypes } from './types';

export const trackReopenRequest = ({
  called,
  description,
  file,
  handleClickNextStep,
}: {
  called: string;
  description: string | undefined;
  file: File[];
  handleClickNextStep: () => void;
}) => ({
  type: ITrackReopenTypes.TRACK_REOPEN_REQUEST,
  payload: {
    called,
    description,
    file,
    handleClickNextStep,
  },
});

export const trackReopenSuccess = () => ({
  type: ITrackReopenTypes.TRACK_REOPEN_SUCCESS,
});

export const trackReopenFailure = (error: ITrackReopenError) => ({
  type: ITrackReopenTypes.TRACK_REOPEN_FAILURE,
  payload: {
    error,
  },
});
