/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IAnnouncements } from 'models/announcements';
/**
 * Actions Types
 */
export enum AnnouncementsTypes {
  LOAD_ANNOUNCEMENTS_REQUEST = '@announcements/LOAD_ANNOUNCEMENTS_REQUEST',
  LOAD_ANNOUNCEMENTS_SUCCESS = '@announcements/LOAD_ANNOUNCEMENTS_SUCCESS',
  LOAD_ANNOUNCEMENTS_FAILURE = '@announcements/LOAD_ANNOUNCEMENTS_FAILURE',
  LOAD_ANNOUNCEMENTS_RESET = '@announcements/LOAD_ANNOUNCEMETS_RESET',
}

/**
 * State Types
 */
export interface IAnnouncementsState {
  readonly data: IAnnouncements[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly totalPage: number;
  readonly currentPage: number;
  readonly id: string;
}
