/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IFaqState, FaqTypes } from './types';

const INITIAL_STATE: IFaqState = {
  data: [],
  loading: false,
  error: false,
};

const faq: Reducer<IFaqState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case FaqTypes.LOAD_FAQ_REQUEST: {
        draft.loading = true;
        break;
      }
      case FaqTypes.LOAD_FAQ_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case FaqTypes.LOAD_FAQ_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
        break;
    }
  });
};

export default faq;
