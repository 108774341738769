/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IDentalDuplicateState, IDentalDuplicateTypes } from './types';

const INITIAL_STATE: IDentalDuplicateState = {
  data: null,
  loading: false,
  error: null,
};

const dentalDuplicate: Reducer<IDentalDuplicateState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case IDentalDuplicateTypes.DENTAL_DUPLICATE_SET_DATA: {
        draft.data = action.payload.data;
        break;
      }
      case IDentalDuplicateTypes.DENTAL_DUPLICATE_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case IDentalDuplicateTypes.DENTAL_DUPLICATE_SUCCESS: {
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case IDentalDuplicateTypes.DENTAL_DUPLICATE_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case IDentalDuplicateTypes.DENTAL_DUPLICATE_RESET: {
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default dentalDuplicate;
