/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { ItineraryRequest } from 'models/request/transport';
/**
 * Actions Types
 */
export enum INewTransportTypes {
  NEW_TRANSPORT_SET_DATA = '@request/NEW_TRANSPORT_SET_DATA',
  NEW_TRANSPORT_ADDRESS_OR_UNIT = '@request/NEW_TRANSPORT_ADDRESS_OR_UNIT',
  NEW_TRANSPORT_ITINERARY = '@request/NEW_TRANSPORT_ITINERARY',
  NEW_TRANSPORT_REQUEST = '@request/NEW_TRANSPORT_REQUEST',
  NEW_TRANSPORT_SUCCESS = '@request/NEW_TRANSPORT_SUCCESS',
  NEW_TRANSPORT_FAILURE = '@request/NEW_TRANSPORT_FAILURE',
}

/**
 * State Types
 */

export interface INewTransportData {
  fileTransportVoucherFront: File | null;
  numberVT: string | null;
  fileTransportVoucherBack: File | null;
  confirmedAddress: 'Sim' | 'Não';
  calledAddress?: string | null;
  unitConfirmed: 'Sim' | 'Não';
  calledUnit?: string | null;
  itineraryGoing: ItineraryRequest[] | [];
  itineraryBack: ItineraryRequest[] | [];
}

export interface ITransportError {
  message?: string;
}

export interface INewTransportState {
  readonly data: INewTransportData;
  readonly loading: boolean;
  readonly error: ITransportError | null;
}
