import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';

export default (reducers: Reducer) => {
  const persistedReducers = persistReducer(
    {
      key: 'rh-digital',
      storage,
      whitelist: ['auth', 'user'],
    },
    reducers,
  );

  return persistedReducers;
};
