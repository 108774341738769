/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import wordpressApi from 'services/api/wordpress';

import { ResearchFormTypes } from './types';

import {
  loadResearchFormRequest,
  loadResearchFormFailure,
  loadResearchFormSuccess,
} from './actions';

type ILoadResearchForm = ReturnType<typeof loadResearchFormRequest>;

export function* loadResearchForm({ payload }: ILoadResearchForm) {
  const { researchFormRoute } = payload;

  try {
    const { data }: AxiosResponse<any[]> = yield call(
      wordpressApi.get,
      `/pesquisas?slug=${researchFormRoute}`,
    );

    const newData = data[0];

    if (data.length > 0) {
      yield put(loadResearchFormSuccess(newData));
    } else {
      yield put(loadResearchFormFailure());
    }
  } catch (err) {
    yield put(loadResearchFormFailure());
  }
}

export default all([
  takeLatest(ResearchFormTypes.LOAD_RESEARCH_FORM_REQUEST, loadResearchForm),
]);
