import { ErrorNetworkTypes } from './types';

export const errorNetwork = ({
  errorNetworkState,
}: {
  errorNetworkState: boolean;
}) => ({
  type: ErrorNetworkTypes.ERROR_NETWORK,
  payload: {
    errorNetworkState,
  },
});
