/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { ITrackReopenState, ITrackReopenTypes } from './types';

const INITIAL_STATE: ITrackReopenState = {
  data: {
    called: '',
    description: '',
    file: [],
  },
  loading: false,
  error: null,
};

const trackReopen: Reducer<ITrackReopenState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITrackReopenTypes.TRACK_REOPEN_REQUEST: {
        draft.data = {
          called: action.payload.called,
          description: action.payload.description,
          file: action.payload.file,
        };
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ITrackReopenTypes.TRACK_REOPEN_SUCCESS: {
        draft.data = {
          called: '',
          description: '',
          file: [],
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      case ITrackReopenTypes.TRACK_REOPEN_FAILURE: {
        draft.data = {
          called: '',
          description: '',
          file: [],
        };
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default trackReopen;
