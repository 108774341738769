import styled from 'styled-components';
import { MIN_WIDTH_DESKTOP, MAX_WIDTH_TABLET } from 'utils/constants';

interface IContainer {
  noNotification: boolean;
}

interface IContent {
  numberNotifications: boolean;
}

export const Container = styled.div`
  background: #ffffff;
  border-top: solid 2px #bdbdbd;

  @media (min-width: ${MIN_WIDTH_DESKTOP}px) {
    width: 394px;
    height: ${(props: IContainer) => (!props.noNotification ? 'auto' : 'auto')};
    max-height: ${(props: IContainer) =>
      !props.noNotification ? '170px' : '447px'};
    z-index: 6;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.16);
    position: absolute;
    border-bottom: solid 4px #004186;
    top: 53px;
    right: 160px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    margin-top: 64px;
    width: 100%;
    height: ${(props: IContainer) =>
      !props.noNotification ? '100vh' : '100%'};
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 13px 22px 21px;
  background: #ffffff;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    position: fixed;
    width: 100%;
    z-index: 4;
    border-bottom: 1px solid #e0e0e0;
    padding: 28px 27px 21px 28px;
  }
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.separatorColor};
`;

export const Content = styled.div`
  @media (min-width: ${MIN_WIDTH_DESKTOP}px) {
    height: 'auto';
    max-height: 320px;
    overflow: auto;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    width: 100%;
    height: auto;
    margin-top: 70px;
    margin-bottom: ${(props: IContent) =>
      props.numberNotifications ? '20px' : '0px'};
    padding-bottom: ${(props: IContent) =>
      props.numberNotifications ? '25px' : '0px'};
  }
`;

export const ContainerButton = styled.div`
  @media (min-width: ${MIN_WIDTH_DESKTOP}px) {
    margin: 20px 0px 16px 51px;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    background: ${({ theme }) => theme.colors.white};
    padding: 17px 0px 16px 54px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    border-top: 2px solid #e0e0e0;
    border-bottom: 3px solid #004186;
  }
`;
