/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IHealthDependent } from 'models/request/health/dependent';

/**
 * Actions Types
 */
export enum IHealthDependentTypes {
  ADD_DEPENDENT_HEALTH_SET_DATA = '@request/ADD_DEPENDENT_HEALTH_SET_DATA',
  ADD_DEPENDENT_HEALTH_REQUEST = '@request/ADD_DEPENDENT_HEALTH_REQUEST',
  ADD_DEPENDENT_HEALTH_SUCCESS = '@request/ADD_DEPENDENT_HEALTH_SUCCESS',
  ADD_DEPENDENT_HEALTH_FAILURE = '@request/ADD_DEPENDENT_HEALTH_FAILURE',
  ADD_DEPENDENT_HEALTH_RESET = '@request/ADD_DEPENDENT_HEALTH_RESET',
}

/**
 * State Types
 */

export interface IHealthData {
  optionId: string;
  dateEvent?: string;
  current?: IHealthDependent;
  dependents: IHealthDependent[];
}

export interface IHealthError {
  status?: number;
  message?: string;
}

export interface IHealthDependentState {
  readonly data: IHealthData;
  readonly loading: boolean;
  readonly error: IHealthError | null;
}
