/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResearchFormTypes } from './types';

export const loadResearchFormRequest = (researchFormRoute: string) => ({
  type: ResearchFormTypes.LOAD_RESEARCH_FORM_REQUEST,
  payload: {
    researchFormRoute,
  },
});

export const loadResearchFormSuccess = (data: any[]) => ({
  type: ResearchFormTypes.LOAD_RESEARCH_FORM_SUCCESS,
  payload: {
    data,
  },
});

export const loadResearchFormFailure = () => ({
  type: ResearchFormTypes.LOAD_RESEARCH_FORM_FAILURE,
});

export const loadResearchFormExit = () => ({
  type: ResearchFormTypes.LOAD_RESEARCH_FORM_EXIT,
});
