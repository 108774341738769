/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { LandingTypes, ILandingState } from './types';

const INITIAL_STATE: ILandingState = {
  showFloatingBalloon: false,
  deferredPrompt: null,
  data: [],
  loading: false,
  error: null,
};

const landing: Reducer<ILandingState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LandingTypes.SET_FLOATING_BALLOON: {
        draft.showFloatingBalloon = action.payload.showFloatingBalloon;
        break;
      }
      case LandingTypes.SET_DEFERRED_PROMPT: {
        draft.deferredPrompt = action.payload.deferredPrompt;
        break;
      }
      case LandingTypes.GET_LANDING_CONTENT_REQUEST: {
        draft.loading = true;
        break;
      }
      case LandingTypes.GET_LANDING_CONTENT_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case LandingTypes.GET_LANDING_CONTENT_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default landing;
