import styled from 'styled-components';
import { MAX_WIDTH_TABLET, MOBILE_MAIN_HEADER_HEIGHT } from 'utils/constants';

interface IUpdateAvailable {
  update: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;

  background: linear-gradient(
      -359.15deg,
      -rgba(209, 206, 206, 0.5) -21.77%,
      -rgba(209, 206, 206, 0) 40.6% -
    ),
    -linear-gradient(-118.58deg, -rgba(242, 242, 242, 0.96) 43.74%, -rgba(
            242,
            242,
            242,
            0
          ) 96.41% -),
    -#f2f2f2;

  > div:nth-child(2) {
    display: none;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    > div:nth-child(2) {
      display: flex;
      margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;
    }
  }
`;

export const DesktopHeader = styled.div`
  margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;

  width: 100%;
  padding: 41px 219px 40px 73px;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  background: transparent;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    width: 100px;
    height: 40px;
    background: ${({ theme }) => theme.colors.white};

    > p {
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 10px;
      line-height: 12px;
      width: 100%;
    }
  }

  > button:hover {
    > p {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  height: fit-content;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    padding-top: ${MOBILE_MAIN_HEADER_HEIGHT}px;
  }
`;
