import styled from 'styled-components';

interface IUpdateAvailable {
  update: boolean;
}

export const Container = styled.div`
  #containerHeader {
    margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;
  }

  > div:nth-child(2) {
    margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;
  }
`;
