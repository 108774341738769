/* eslint-disable prefer-template */
/* eslint-disable no-unneeded-ternary */

import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as LinkNav, useLocation, RouteProps } from 'react-router-dom';

import { updateAvailable } from 'store/modules/updateAvailable/actions';
import { IUpdateAvailableState } from 'store/modules/updateAvailable/types';

import { IState } from 'store';
import { IAuthState } from 'store/modules/auth/types';
import { IUserState } from 'store/modules/user/types';
import { INotificationState } from 'store/modules/notification/types';
import { IHeader, ISubLink } from 'models/header';
import theme from 'styles/theme';
import Text from 'shared/Tipografia/Text';
import ContentNotification from 'shared/ContentNotification';

import ellipse from 'assets/images/ellipse.svg';

import * as serviceWorker from '../../../serviceWorkerRegistration';
import Icon from '../../Icon';

import {
  Container,
  Links,
  Wrapper,
  ContainerEllipse,
  Ellipse,
  Row,
  Button,
  DropDown,
  SubButton,
  WrapperButtons,
  ContentButton,
} from './styles';

interface ILocation {
  tab?: string;
  location: RouteProps['location'];
  children: RouteProps['children'];
}

const MainHeader: React.FC<IHeader> = ({
  links = [],
  buttons = [],
  isOpenNotification,
  setIsOpenNotification,
  alertNotification,
  setAlertNotification,
}) => {
  const [idLinkOpened, setIdLinkOpened] = useState<string | null>(null);
  const [isOpenSubLink, setIsOpenSubLink] = useState<boolean>(false);
  const [, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const isMobile: boolean = width <= 1024;

  const { signed } = useSelector<IState, IAuthState>((state) => state.auth);

  const { data } = useSelector<IState, IUserState>((state) => state.user);

  const { update, downloadUpdates } = useSelector<
    IState,
    IUpdateAvailableState
  >((state) => state.updateAvailable);

  const { dataNotificationAlert, numberClosedNotification } = useSelector<
    IState,
    INotificationState
  >((state) => state.notification);

  const dispatch = useDispatch();
  const location = useLocation<ILocation>();
  const containerRef = useRef<HTMLDivElement>(null);

  function checkIsPath(id: string): boolean {
    return location.pathname.split('/')[1] === id;
  }

  function handleLink(
    id: string,
    subLinks: ISubLink[],
    onClick: () => void = () => null,
  ) {
    if (subLinks.length > 0) {
      setIdLinkOpened((state) => {
        setIsOpenSubLink(state !== id ? true : !isOpenSubLink);
        return id;
      });
      return;
    }

    setIdLinkOpened(null);
    setIsOpenSubLink(false);
    onClick();
  }

  function handleSubLink(onClickSub: () => void = () => null) {
    setIsOpenSubLink(false);
    onClickSub();
  }

  function onSWUpdate(registration: ServiceWorkerRegistration) {
    setWaitingWorker(registration.waiting);

    if (!update && !downloadUpdates) {
      dispatch(updateAvailable({ update: true, downloadUpdates: true }));

      setTimeout(() => {
        dispatch(updateAvailable({ update: true, downloadUpdates: false }));
      }, 10000);
    }
  }

  function handleIsCloseNotification() {
    if (setIsOpenNotification) {
      setIsOpenNotification(false);
    }
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    document.addEventListener('click', (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpenSubLink(false);
      }
    });
  }, []);

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  window.addEventListener('beforeunload', (event) => {
    event.preventDefault();

    if (sessionStorage.getItem('rh-digital:openApplication') === 'true') {
      sessionStorage.removeItem('rh-digital:openApplication');
      localStorage.removeItem('rh-digital:openApplication');
    }
  });

  useEffect(() => {
    if (update && !downloadUpdates) {
      dispatch(updateAvailable({ update: false, downloadUpdates: false }));
      window.location.reload();
    }

    if (setIsOpenNotification) {
      setIsOpenNotification(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    if (
      data &&
      dataNotificationAlert &&
      setAlertNotification &&
      data.length > 0 &&
      dataNotificationAlert.docs.length > 0
    ) {
      const { createdAt } = dataNotificationAlert.docs[0];
      const { cdCPF } = data[0].documentos;

      const createdAtLocalStorage = localStorage.getItem(
        'rh-digital:createdAtNotification',
      );

      const cpfBase64LocalStorage = localStorage.getItem(
        'rh-digital:userBase64',
      );

      const cdCpfBase64 = window.btoa(cdCPF);

      if (
        createdAtLocalStorage === null ||
        new Date(createdAt) > new Date(String(createdAtLocalStorage)) ||
        cpfBase64LocalStorage !== cdCpfBase64
      ) {
        setAlertNotification(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNotificationAlert]);

  return (
    <Container id="containerHeader">
      {signed ? (
        <LinkNav className="linkHome" to="/home">
          <Icon
            className="logo"
            name="logoRedeDor"
            color={theme.colors.white}
            width="100%"
            height="100%"
          />
        </LinkNav>
      ) : (
        <Icon
          className="logo"
          name="logoRedeDor"
          color={theme.colors.white}
          width="100%"
          height="100%"
        />
      )}

      <Links ref={containerRef} className="menu-options">
        {links.map(({ id, name, subLinks = [], onClick, isVisible }) => {
          if (isVisible !== false) {
            return (
              <Wrapper key={id}>
                <Button
                  id={'itemMenuHeader' + id}
                  className="itemMenuHeader"
                  type="button"
                  onClick={() => {
                    handleLink(id, subLinks, onClick);
                  }}
                >
                  <Row
                    className="itemMenuHeaderRow"
                    isPath={checkIsPath(id)}
                    isSelected={idLinkOpened === id}
                    isOpen={isOpenSubLink && idLinkOpened === id}
                  >
                    <Text as="h4" fontWeight="400" pointer>
                      {name}
                    </Text>
                    {subLinks.length > 0 && (
                      <Icon
                        name="pullDownP"
                        color={
                          checkIsPath(id)
                            ? theme.colors.quaternary
                            : theme.colors.white
                        }
                        width="10"
                        height="5"
                      />
                    )}
                  </Row>
                </Button>

                <DropDown>
                  {subLinks.map(
                    ({
                      id: idSub,
                      name: nameSub,
                      onClick: onClickSub,
                      isVisible,
                    }) => {
                      if (isVisible !== false) {
                        return (
                          <SubButton
                            key={idSub}
                            type="button"
                            onClick={() => handleSubLink(onClickSub)}
                            isSelected={location.state?.tab === idSub}
                            isOpen={isOpenSubLink && idLinkOpened === id}
                          >
                            <Text as="h4" align="left" fontWeight="400">
                              {nameSub}
                            </Text>
                          </SubButton>
                        );
                      }
                      return null;
                    },
                  )}
                </DropDown>
              </Wrapper>
            );
          }
          return null;
        })}
      </Links>

      <WrapperButtons>
        {/* Desktop */}
        {buttons.map(
          ({
            id,
            icon,
            size,
            color,
            isMobile: isMobileButton,
            disabled,
            onClick,
          }) => (
            <ContentButton
              key={id}
              className={`icon-${icon}`}
              isProfile={id === 'account_config'}
              isChat={id === 'chat'}
              isMenu={id === 'menu'}
              isMobile={!!isMobileButton}
              disabled={disabled === true}
            >
              <button
                disabled={disabled === true}
                type="button"
                id={`icon-${icon} ${id === 'chat' ? 'chatButton' : ''}`}
                onClick={() => disabled !== true && onClick()}
              >
                <Icon
                  name={icon}
                  id={`icon_${id ? id : ''}`}
                  height={size}
                  width={size}
                  color={disabled !== true ? color : theme.colors.disabled}
                />
                {icon === 'alert' &&
                  alertNotification &&
                  numberClosedNotification > 0 && (
                    <ContainerEllipse>
                      <Ellipse src={ellipse} alt="ellipse" />
                      <Text as="disclaimer">
                        {numberClosedNotification < 99
                          ? numberClosedNotification
                          : '+99'}
                      </Text>
                    </ContainerEllipse>
                  )}
              </button>
            </ContentButton>
          ),
        )}
      </WrapperButtons>
      {!isMobile && isOpenNotification && (
        <ContentNotification
          handleIsCloseNotification={handleIsCloseNotification}
          data={dataNotificationAlert}
        />
      )}
    </Container>
  );
};

export default MainHeader;
