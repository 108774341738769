/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, RouteProps } from 'react-router-dom';

import { IState } from 'store';
import { ISubLink } from 'models/header';
import { IMenu, IHeaderMenu } from 'models/menu';
import { IAuthState } from 'store/modules/auth/types';
import { IUserState } from 'store/modules/user/types';
import { logoutRequest } from 'store/modules/auth/actions';
import Text from 'shared/Tipografia/Text';
import Icon from 'shared/Icon';

import { handleChatButton } from 'utils/general';

import theme from 'styles/theme';
import Link from 'shared/Tipografia/Link';
import Header from './Header';

import { version } from '../../../package.json';

import {
  Container,
  Content,
  Wrapper,
  ShadowVertical,
  Row,
  Links,
  WrapperLinks,
  WrapperName,
  Button,
  SubWrapper,
  SubButton,
  Footer,
} from './styles';

interface ILocation {
  tab?: string;
  location: RouteProps['location'];
  children: RouteProps['children'];
}

const Menu: React.FC<IMenu> = ({ isOpen, handleMenu, links = [] }) => {
  const location = useLocation<ILocation>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [idLinkOpened, setIdLinkOpened] = useState<string>(
    location.pathname.split('/')[1],
  );
  const [isOpenSubLink, setIsOpenSubLink] = useState<boolean>(false);

  const { signed } = useSelector<IState, IAuthState>((state) => state.auth);
  const { current } = useSelector<IState, IUserState>((state) => state.user);


  const headerMenu: IHeaderMenu = {
    buttonsLeft: [
      {
        id: 'account_config',
        icon: 'profile',
        color: theme.colors.white,
        size: '20',
        onClick: () => {
          handleMenu();
          history.push({
            pathname: '/account_config',
            state: { tab: 'account_config' },
          });
        },
      },
      {
        id: 'exit',
        icon: 'exit',
        color: theme.colors.white,
        size: '20',
        onClick: () => {
          localStorage.removeItem('rh-digital:openApplication');
          localStorage.removeItem('rh-digital:popUpOpen');
          sessionStorage.removeItem('rh-digital:openApplication');
          dispatch(logoutRequest());
        },
      },
    ],
    buttonsRight: [
      {
        id: 'chatButton',
        icon: 'chatCircle',
        color: theme.colors.white,
        size: '20',
        onClick: () => {
          handleMenu();
          handleChatButton();
        },
      },
      {
        id: 'telephoneButton',
        icon: 'telephone',
        color: theme.colors.white,
        size: '20',
        onClick: () => {
          document.location.href = 'tel:08003345000';
        },
      },
      {
        id: 'faq',
        icon: 'helpCircle',
        color: theme.colors.white,
        size: '20',
        onClick: () => {
          handleMenu();
          history.push('/faq');
        },
      },
      {
        id: 'menu_close',
        icon: 'close',
        color: theme.colors.white,
        size: '20',
        onClick: handleMenu,
      },
    ],
  };

  function handleLink(
    id: string,
    subLinks: ISubLink[],
    onClick: (params?: any) => void = () => null,
  ) {
    if (subLinks.length > 0) {
      setIdLinkOpened((state) => {
        setIsOpenSubLink(state !== id ? true : !isOpenSubLink);
        return id;
      });
      return;
    }

    setIdLinkOpened(id);
    setIsOpenSubLink(false);
    handleMenu();
    onClick();
  }

  function handleSubLink(id: string, onClick: () => void = () => null) {
    setIdLinkOpened(id);
    handleMenu();
    onClick();
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'unset';
    setTimeout(() => {
      setIdLinkOpened(location.pathname.split('/')[1]);
    }, 300);
  }, [isOpen, location.pathname]);

  return (
    <Container isOpen={isOpen}>
      <Header {...headerMenu} />

      <Content>
        <Wrapper>
          <Row height={85} paddingLeft={27}>
            <Text as="h3" color={theme.colors.white} fontWeight="500">
              {signed ? current?.nmNome : 'Seja bem-vindo!'}
            </Text>
          </Row>

          <Links className="menu-options">
            {links.map(
              ({ id, name, iconName, subLinks = [], onClick, isVisible }) => {
                if (isVisible !== false) {
                  return (
                    <WrapperLinks key={id}>
                      <Button
                        id={'itemMenu' + id}
                        className="itemMenu"
                        type="button"
                        isSelected={idLinkOpened === id}
                        onClick={() => {
                          handleLink(id, subLinks, onClick);
                        }}
                      >
                        <Row
                          className="itemMenuRow"
                          paddingLeft={16}
                          isSelected={idLinkOpened === id}
                          isOpen={isOpenSubLink && idLinkOpened === id}
                        >
                          <WrapperName>
                            {iconName && (
                              <Icon
                                name={iconName}
                                color={
                                  idLinkOpened === id
                                    ? theme.colors.quaternary
                                    : theme.colors.white
                                }
                                height="22"
                                width="22"
                              />
                            )}
                            <Text as="h4">{name}</Text>
                          </WrapperName>
                          {subLinks.length > 0 && (
                            <Icon
                              name="pullDownP"
                              color={
                                idLinkOpened === id
                                  ? theme.colors.quaternary
                                  : theme.colors.white
                              }
                              width="10"
                              height="10"
                            />
                          )}
                        </Row>
                      </Button>

                      <SubWrapper isOpen={isOpenSubLink && idLinkOpened === id}>
                        {subLinks?.map(
                          ({
                            id: idSub,
                            name: nameSub,
                            onClick: onClickSub,
                            isVisible,
                          }) => {
                            if (isVisible !== false) {
                              return (
                                <SubButton
                                  key={idSub}
                                  type="button"
                                  isSelected={location.state?.tab === idSub}
                                  onClick={() => handleSubLink(id, onClickSub)}
                                >
                                  <Text as="p">{nameSub}</Text>
                                </SubButton>
                              );
                            }
                            return null;
                          },
                        )}
                      </SubWrapper>
                    </WrapperLinks>
                  );
                }
                return null;
              },
            )}
          </Links>
        </Wrapper>

        <ShadowVertical />
      </Content>

      <Footer>
        <Icon
          name="logoRedeDor"
          color={theme.colors.white}
          width="78"
          height="76"
        />
        <Link
          href="https://dpo.privacytools.com.br/policy-view/NjVGErqo1/1/policy/pt_BR?s=1680026544140"
          color={theme.colors.white}
          target="_blank"
          textDecoration="none"
        >
          Aviso de Privacidade
        </Link>
        <Text as="disclaimer">Versão {version}</Text>
      </Footer>
    </Container>
  );
};

export default Menu;
