import { IHealthData, IHealthError, IHealthDuplicateTypes } from './types';

export const healthDuplicateSetData = (data: IHealthData) => ({
  type: IHealthDuplicateTypes.HEALTH_DUPLICATE_SET_DATA,
  payload: {
    data,
  },
});

export const healthDuplicateRequest = ({
  type,
  attachment,
  handleClickNextStep,
}: {
  type: 'Avaria' | 'Perda' | 'Roubo' | 'Outros';
  attachment?: File;
  handleClickNextStep: () => void;
}) => ({
  type: IHealthDuplicateTypes.HEALTH_DUPLICATE_REQUEST,
  payload: {
    type,
    attachment,
    handleClickNextStep,
  },
});

export const healthDuplicateSuccess = () => ({
  type: IHealthDuplicateTypes.HEALTH_DUPLICATE_SUCCESS,
});

export const healthDuplicateFailure = (error: IHealthError) => ({
  type: IHealthDuplicateTypes.HEALTH_DUPLICATE_FAILURE,
  payload: {
    error,
  },
});

export const healthDuplicateReset = () => ({
  type: IHealthDuplicateTypes.HEALTH_DUPLICATE_RESET,
});
