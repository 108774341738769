import styled from 'styled-components';

import { IButtonLink } from './index';

export const ButtonLinkComponent = styled.button<IButtonLink>`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme, color }) => color || theme.colors.primary};
  text-decoration: ${({ textDecoration }) => textDecoration};

  display: flex;
  align-items: center;
  justify-content: center;
  width: auto !important;
  transition: color 1s;

  background: none;
  border: none;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: ${({ theme, color }) => color || theme.colors.primaryHover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
`;
