import { ItineraryRequest } from 'models/request/transport';

import {
  ITransportError,
  INewTransportTypes,
  INewTransportData,
} from './types';

export const addNewFileTransport = (
  file: File | null,
  type: 'front' | 'back',
  numberVT?: string | null,
) => ({
  type: INewTransportTypes.NEW_TRANSPORT_SET_DATA,
  payload: {
    file,
    type,
    numberVT,
  },
});

export const newConfirmedAddressOrUnit = (
  confirmed: 'Sim' | 'Não',
  callNumber?: string | null,
  typeCall?: 'address' | 'unit',
) => ({
  type: INewTransportTypes.NEW_TRANSPORT_ADDRESS_OR_UNIT,
  payload: {
    confirmed,
    callNumber,
    typeCall,
  },
});

export const newItinerary = (
  typeItinerary: 'ida' | 'volta',
  itinerary: ItineraryRequest[],
) => ({
  type: INewTransportTypes.NEW_TRANSPORT_ITINERARY,
  payload: {
    typeItinerary,
    itinerary,
  },
});

export const addNewTransportRequest = ({
  data,
  handleClickNextStep,
}: {
  data: INewTransportData;
  handleClickNextStep: () => void;
}) => ({
  type: INewTransportTypes.NEW_TRANSPORT_REQUEST,
  payload: {
    data,
    handleClickNextStep,
  },
});

export const addNewTransportSuccess = () => ({
  type: INewTransportTypes.NEW_TRANSPORT_SUCCESS,
});

export const addNewTransportFailure = (error: ITransportError) => ({
  type: INewTransportTypes.NEW_TRANSPORT_FAILURE,
  payload: {
    error,
  },
});
