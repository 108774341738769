import axios from 'axios';

const wordpressApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_WORDPRESS,
  headers: {
    client_id: process.env.REACT_APP_CLIENT_ID,
  },
});

export default wordpressApi;
