/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import {
  DESKTOP_MAIN_HEADER_HEIGHT,
  MAX_WIDTH_TABLET,
  MOBILE_MAIN_HEADER_HEIGHT,
} from 'utils/constants';

interface IUpdateAvailable {
  update: boolean;
  openNotification: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  #containerHeader {
    margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;
  }

  > div:nth-child(3) {
    margin-top: ${(props: IUpdateAvailable) => (props.update ? 65 : 0)}px;
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    > div:nth-child(3) {
      margin-top: ${(props: IUpdateAvailable) =>
        props.update && props.openNotification
          ? 130
          : props.update || props.openNotification
          ? 62
          : 0}px;
    }
  }
`;

export const Wrapper = styled.div`
  padding-top: ${DESKTOP_MAIN_HEADER_HEIGHT}px;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    padding-top: ${MOBILE_MAIN_HEADER_HEIGHT}px;
  }
`;
