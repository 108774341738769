export const formatCpf = (value: string): string => {
  return value
    ? value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    : ''; // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const formatRg = (value: string): string => {
  const regex = new RegExp('^0+(?!$)', 'g');
  return value
    ? value
        .replace(regex, '')
        .replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')
    : '';
};

export const formatCel = (value: string): string => {
  return value
    ? value.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
    : '';
};
export const formatPis = (value: string): string => {
  return value
    ? value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d{5})(\d{2})(\d{1})$/, '$1.$2.$3-$4')
    : '';
};

export const formatTel = (value: string): string => {
  const regex = new RegExp('^0+(?!$)', 'g');

  if (value) {
    if (value.replace(regex, '').length === 11) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    }
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
  }
  return '';
};

export const formatCep = (value: string): string => {
  return value
    ? value.replace(/\D/g, '').replace(/(\d{5})(\d{3})$/, '$1-$2')
    : '';
};

export const formatDate = (value: string): string => {
  return value ? value.replace(new RegExp('[/]', 'gi'), '.') : '';
};

export const formatFirstLetterUppercase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatCapitalize = (string: string) => {
  const stringLowerCase = string.toLowerCase();

  const stringCapitalize = stringLowerCase.split(' ');

  for (let i = 0; i < stringCapitalize.length; i += 1) {
    stringCapitalize[i] =
      stringCapitalize[i].charAt(0).toUpperCase() +
      stringCapitalize[i].slice(1);
  }

  return stringCapitalize.join(' ');
};

export function capitalizeText(text: string): string {
  const exceptions: string[] = ['de', 'do', 'da', 'dos', 'das', 'e'];
  const words: string[] = text.split(' ');

  const capitalizedWords: string[] = words.map(
    (word: string, index: number) => {
      const lowercaseWord: string = word.toLowerCase();
      if (index === 0 || !exceptions.includes(lowercaseWord)) {
        return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
      }
      return lowercaseWord;
    },
  );

  return capitalizedWords.join(' ');
}

export function shortenName(name: string) {
  if(name.length <= 35) {
    return name
  }
  const [firstName, ...lastName] = name.split(' ');
  const shortName = lastName.map((item) => {
    const capsLetter = item.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
    if (!capsLetter) return item;
    return capsLetter[0];
  });
  return [firstName, ...shortName].join(' ');
}