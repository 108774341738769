/* eslint-disable no-restricted-syntax */
import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { IState } from 'store';

import api from 'services/api';
import { ITransportDuplicateState, ITransportDuplicateTypes } from './types';

import {
  transportDuplicateRequest,
  transportDuplicateSuccess,
  transportDuplicateFailure,
} from './actions';

type ITransportDuplicateType = ReturnType<typeof transportDuplicateRequest>;

export function* transportDuplicate({ payload }: ITransportDuplicateType) {
  try {
    const { type, attachment } = payload;

    // const getCpf = (state: IState) => state.auth.cpf;
    // const cpf: string = yield select(getCpf);

    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const matricula: string = yield select(getMatricula);

    const getTransport = (state: IState) => state.transportDuplicate;
    const { data }: ITransportDuplicateState = yield select(getTransport);

    const defineOtherReason = () => {
      switch (data?.optionId) {
        case '1':
          return '30 dias de plano, mas não recebeu a carteirinha';
        case '2':
          return 'Carteirinha com nome incorreto';
        case 'Roubo':
          return 'Roubo ou furto';
        case '3':
          return 'Alterar nome';
        default:
          return '';
      }
    };

    const defineDescricaoMotivo = () => {
      switch (type) {
        case 'Perda':
          return 'Perda do cartão';
        case 'Avaria':
          return 'Quebra ou avaria';
        case 'Roubo':
          return 'Roubo ou furto';
        case 'Outros':
          return defineOtherReason();
        default:
          return '';
      }
    };

    const formData = new FormData();

    // formData.append('cpf', cpf);
    formData.append('matricula', matricula);
    formData.append('tipoMotivo', type);
    // formData.append('tipoPlano', 'PM');
    // formData.append('nomeSolicitanteCarteirinha', dependent.name);
    formData.append('descricaoMotivo', defineDescricaoMotivo());

    if (attachment) {
      formData.append('anexo', attachment);
    }

    yield call(api.post, 'portal-conecta/vale-transporte', formData);

    yield put(transportDuplicateSuccess());
  } catch (err) {
    if (err && err?.response) {
      const response = {
        status: err.response?.status,
        message: err.response?.data.message,
      };

      yield put(transportDuplicateFailure(response));
      return;
    }

    yield put(
      transportDuplicateFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  } finally {
    const { handleClickNextStep } = payload;

    handleClickNextStep();
  }
}

export default all([
  takeLatest(
    ITransportDuplicateTypes.TRANSPORT_DUPLICATE_REQUEST,
    transportDuplicate,
  ),
]);
