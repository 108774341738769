import { IHealth } from 'models/benefits/health';
import { HealthTypes } from './types';

export const getHealthRequest = () => ({
  type: HealthTypes.GET_HEALTH_REQUEST,
});

export const getHealthSuccess = (data: IHealth | null) => ({
  type: HealthTypes.GET_HEALTH_SUCCESS,
  payload: {
    data,
  },
});

export const getHealthFailure = ({
  haveInsurance,
}: {
  haveInsurance: boolean | null;
}) => ({
  type: HealthTypes.GET_HEALTH_FAILURE,
  payload: {
    haveInsurance,
  },
});
