import React, { Suspense, useEffect, useState } from 'react';

import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import { IState } from 'store';
import { IUserState } from 'store/modules/user/types';
import { IAuthState } from 'store/modules/auth/types';
import { IChatState } from 'store/modules/chat/types';
import { IResearchFormState } from 'store/modules/researchForm/types';
import { showChatButton } from 'utils/general';
import { CONECTA_OFFLINE, IN_MAINTENANCE } from 'utils/constants';

//@ts-ignore
interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isPrivacy?: boolean;
  isGlobal?: boolean;
  title: string;
  layout: React.ComponentType;
  component: React.ComponentType<RouteComponentProps>;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isPrivacy = false,
  isGlobal = false,
  title,
  layout: Layout,
  component: Component,
  ...rest
}) => {
  const [restPath, setRestPath] = useState<string>('');
  const [restPathName, setRestPathName] = useState<string>('');
  const history = useHistory();

  const { signed, acceptedPrivacy } = useSelector<IState, IAuthState>(
    (state) => state.auth,
  );

  const { data } = useSelector<IState, IUserState>((state) => state.user);

  const { researchFormExit } = useSelector<IState, IResearchFormState>(
    (state) => state.researchForm,
  );

  const { openWindow } = useSelector<IState, IChatState>((state) => state.chat);

  useEffect(() => {
    showChatButton();
  }, [rest.path]);

  useEffect(() => {
    if (rest.path === '/pesquisas/:slug') {
      setRestPath(String(rest.path));
      setRestPathName(String(rest.location?.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('rh-digital:openApplication') === 'true') {
      localStorage.setItem('rh-digital:openApplication', 'true');
    }
  }, [data, signed]);

  // Redirect de manutenção
  if (rest.path !== '/' && rest.path !== '/maintenance' && IN_MAINTENANCE) {
    return <Redirect to={{ pathname: '/maintenance' }} />;
  }

  // Redirect para pesquisa
  if (
    !isGlobal &&
    signed &&
    restPath === '/pesquisas/:slug' &&
    !researchFormExit &&
    (!isPrivate || (acceptedPrivacy && isPrivacy))
  ) {
    return <Redirect to={restPathName} />;
  }

  // Redirect para autenticação
  if (!isGlobal && !signed && isPrivate) {
    return <Redirect to="/auth" />;
  }

  // Redirect conecta offline
  if (CONECTA_OFFLINE && rest.path?.toString().startsWith('/request/')) {
    return <Redirect to={{ pathname: '/request' }} />;
  }

  // Redirect para home
  /*if (!isGlobal && signed && (!isPrivate || (acceptedPrivacy && isPrivacy))) {
    if (rest.path === '/auth') {
      const query = openWindow ? '?chat=open' : '';
      setTimeout(() => {
        window.location.href = `/home${query}`;
      }, 1e3);
      return null;
    }

    return <Redirect to="/home" />;
  }
  */

  if (!isGlobal && signed && (!isPrivate || (acceptedPrivacy && isPrivacy))) {
    if (rest.path === '/auth') {
      const query = openWindow ? '?chat=open' : '';
      setTimeout(() => {
        history.push(`/home${query}`);
      }, 1e3);
      return null;
    }

    return <Redirect to="/home" />;
  }

  document.title = `${title} - RH Digital`;

  return (
    <ReactDOMRoute
      {...rest}
      render={(props) => (
        <>
          {/*@ts-ignore*/}
          <Layout {...props}>
            <Suspense fallback={null}>
              {/*@ts-ignore*/}
              <Component {...props} />
            </Suspense>
          </Layout>
        </>
      )}
    />
  );
};

export default Route;
