/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';

import { MAX_WIDTH_TABLET } from 'utils/constants';

interface IContainer {
  isOpen: boolean;
}

interface IRow {
  height?: number;
  paddingLeft?: number;
  isSelected?: boolean;
  isOpen?: boolean;
}

interface IButton {
  isSelected?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;

  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0px;
  right: ${({ isOpen }) => (isOpen ? '0px' : '-100vw')};
  z-index: 5;
  transition: right 0.5s ease;
  background: linear-gradient(
      168.86deg,
      rgba(145, 216, 246, 0) 38.39%,
      rgba(145, 216, 246, 0.5) 107.23%
    ),
    #004186;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.16);

  @media (min-width: ${MAX_WIDTH_TABLET}px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: block;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 170px);

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ShadowVertical = styled.div`
  width: 20px;

  background: rgba(0, 44, 92, 0.2);
`;

export const Row = styled.div<IRow>`
  width: 100%;
  height: ${({ height }) => height || 58}px;
  padding: 18px;
  padding-left: ${({ paddingLeft }) => paddingLeft || 24}px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.quaternary : theme.colors.white};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  }

  > svg {
    margin-right: 12px;
    transition: transform 0.4s ease;
    transform: rotate(
      ${({ isSelected, isOpen }) => (isSelected && isOpen ? 0 : -90)}deg
    );
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperLinks = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const WrapperName = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 24px;
  }
`;

export const Button = styled.button<IButton>`
  position: relative;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.primaryHover};
  overflow: hidden;

  text-transform: uppercase;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.quaternary};
      background: linear-gradient(
          275.29deg,
          rgba(0, 65, 134, 0.123) 24.33%,
          rgba(0, 65, 134, 0) 79.77%
        ),
        ${({ theme }) => theme.colors.white};
      border-left: 8px solid ${({ theme }) => theme.colors.tertiary};
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.16);
      transition: border-left 0.4s ease;

      &:after {
        content: '';
        position: absolute;
        width: 21px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 44, 92, 0.2);
      }
    `}
`;

export const SubWrapper = styled.div<IContainer>`
  overflow: hidden;
  max-height: 500px;
  transition: max-height 0.4s ease-in-out;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      max-height: 0px;
      pointer-events: none;
    `}
`;

export const SubButton = styled.button<IButton>`
  display: block;
  width: 100%;
  padding: 14px;
  border: none;
  background-color: transparent;
  margin-left: 89px;

  text-transform: uppercase;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.quaternary};
    `}

  p {
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.quaternary : theme.colors.white};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  }
`;

export const Footer = styled.div`
  height: 106px;
  padding: 0px 24px 0px 27px;
  border-top: 1px solid ${({ theme }) => theme.colors.primaryHover};

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 12px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #fff;
  }
`;
