/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';

import { ILink } from './index';

export const LinkComponent = styled.a<ILink>`
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: ${({ theme, color }) => color || theme.colors.primary};
  text-decoration: ${({ textDecoration }) => textDecoration};
  white-space: nowrap;

  transition: color 1s;

  &:hover {
    color: ${({ theme, color }) => color || theme.colors.primaryHover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.disabledSecondary};
  }
`;
