import React from 'react';

import Icon from 'shared/Icon';
import Text from 'shared/Tipografia/Text';
import theme from 'styles/theme';

import { Container, Content, Header } from './styles';

interface IModal {
  title?: string | React.ReactNode;
  visible: boolean;
  handleClose?(): void;
  buttonClose?: boolean;
  styleContainer?: React.CSSProperties;
  styleContent?: React.CSSProperties;
}

const Modal: React.FC<IModal> = ({
  title,
  visible,
  handleClose,
  buttonClose = true,
  children,
  styleContainer,
  styleContent,
}) => {
  return (
    <Container
      visible={visible}
      style={styleContainer}
      className="ModalContainer"
    >
      <Content style={styleContent} className="ModalContent">
        <Header className="ModalHeader">
          <Text as="h3" fontWeight="300" className="ModalTitle">
            {title}
          </Text>
        </Header>

        {buttonClose && (
          <button className="closeButton" type="button" onClick={handleClose}>
            <Icon
              name="close"
              width="18"
              height="18"
              color={theme.colors.error}
            />
          </button>
        )}

        {children}
      </Content>
    </Container>
  );
};

export default Modal;
