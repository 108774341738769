/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum ButtonSizes {
  SMALL = '30',
  DEFAULT = '48',
}

export enum ButtonAlign {
  CENTER = 'center',
  RIGHT = 'right',
  LEFT = 'left',
}
