import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import wordpressApi from 'services/api/wordpress';

import { ILandingContent, ILandingContentRequest } from 'models/landing';
import { LandingTypes } from './types';
import { getLandingContentFailure, getLandingContentSuccess } from './actions';

export function* getLandingContent() {
  try {
    const { data }: AxiosResponse<ILandingContentRequest[]> = yield call(
      wordpressApi.get,
      '/pages?slug=landing-rh-digital',
    );

    const newData: ILandingContent[] = data.map((content) => ({
      ...content,
      id: content.id,
      publishedDate: content.date,
      modifiedAt: content.modified,
      title: content.title.rendered,
    }));

    yield put(getLandingContentSuccess(newData));
  } catch (error) {
    if (error && error?.response) {
      const response = {
        status: error.response?.status,
        message: error.response?.data.message,
      };

      yield put(getLandingContentFailure(response));
      return;
    }

    yield put(
      getLandingContentFailure({
        status: 500,
        message: 'Erro interno de servidor',
      }),
    );
  }
}

export default all([
  takeLatest(LandingTypes.GET_LANDING_CONTENT_REQUEST, getLandingContent),
]);
