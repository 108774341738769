import { IDentalData, IDentalError, IDentalDuplicateTypes } from './types';

export const dentalDuplicateSetData = (data: IDentalData) => ({
  type: IDentalDuplicateTypes.DENTAL_DUPLICATE_SET_DATA,
  payload: {
    data,
  },
});

export const dentalDuplicateRequest = ({
  type,
  attachment,
  handleClickNextStep,
}: {
  type: 'Avaria' | 'Perda' | 'Roubo' | 'Outros';
  attachment?: File;
  handleClickNextStep: () => void;
}) => ({
  type: IDentalDuplicateTypes.DENTAL_DUPLICATE_REQUEST,
  payload: {
    type,
    attachment,
    handleClickNextStep,
  },
});

export const dentalDuplicateSuccess = () => ({
  type: IDentalDuplicateTypes.DENTAL_DUPLICATE_SUCCESS,
});

export const dentalDuplicateFailure = (error: IDentalError) => ({
  type: IDentalDuplicateTypes.DENTAL_DUPLICATE_FAILURE,
  payload: {
    error,
  },
});

export const dentalDuplicateReset = () => ({
  type: IDentalDuplicateTypes.DENTAL_DUPLICATE_RESET,
});
