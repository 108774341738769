import React from 'react';

import Modal from 'shared/Modal';
import Button from 'shared/Button';
import Text from 'shared/Tipografia/Text';

import { Container, ModalContent } from './styles';

interface IPopUpAlertBlock {
  textPrimary: string;
  textSecundary: string;
  visiblePopUp: boolean;
  buttonPrimary: string;
  buttonSecundary: string;
  handleClickPrimary(): void;
  handleClickSecundary(): void;
}

const PopUpAlertBlock: React.FC<IPopUpAlertBlock> = ({
  textPrimary,
  textSecundary,
  visiblePopUp,
  buttonPrimary,
  buttonSecundary,
  handleClickPrimary,
  handleClickSecundary,
}) => {
  return (
    <Container className="Container">
      <Modal visible={visiblePopUp} buttonClose={false}>
        <Text as="h2" fontWeight="300">
          {textPrimary}
        </Text>
        <Text as="p" fontWeight="400">
          {textSecundary}
        </Text>
        <ModalContent>
          <Button color="#de1306" onClick={() => handleClickPrimary()}>
            {buttonPrimary}
          </Button>
          <Button transparent onClick={() => handleClickSecundary()}>
            {buttonSecundary}
          </Button>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default PopUpAlertBlock;
