import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import { IState } from 'store';

import { IEmploymentRequestResponse } from 'models/request/employment/request';
import api from 'services/api';
import { EmploymentRequestTypes } from './types';

import { employmentRequestFailure, employmentRequestSuccess } from './actions';

export function* employmentRequest() {
  try {
    const getMatricula = (state: IState) => state.user.current?.cdMatricula;
    const matricula: string = yield select(getMatricula);

    const { data }: AxiosResponse<IEmploymentRequestResponse> = yield call(
      api.post,
      '/portal-conecta/vinculo-empregaticio',
      {
        matricula,
      },
    );

    const dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: 'solicitationFormSubmitted',
      formName: `Vínculo Empregatício - Solicitar Nova Carta`,
    });

    yield put(employmentRequestSuccess(data));
  } catch (err) {
    yield put(employmentRequestFailure());
  }
}

export default all([
  takeLatest(
    EmploymentRequestTypes.EMPLOYMENT_REQUEST_REQUEST,
    employmentRequest,
  ),
]);
