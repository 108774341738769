import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { format } from 'date-fns';
import { putNotificationOpen } from 'store/modules/notification/actions';

import Text from 'shared/Tipografia/Text';

import { MONTHS } from 'utils/constants';

import {
  Container,
  TitleNotification,
  TitleNoNotifications,
  InformationNotification,
  DateNotification,
  Separator,
} from './styles';

interface ICardContentNotification {
  id: string;
  type: string;
  subtype?: string;
  month?: string;
  year?: string;
  createdAt?: string;
  opened?: boolean;
}

const CardContentNotification: React.FC<ICardContentNotification> = ({
  id,
  type,
  subtype,
  month,
  year,
  createdAt,
  opened,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleRedirect() {
    dispatch(putNotificationOpen({ idNotification: id }));

    if (type === 'ContraCheque') {
      history.push('/paycheck');
    }

    if (type === 'FolhaPonto') {
      history.push('/time_sheet');
    }
  }

  function handleDefinedSubtypePayment() {
    switch (subtype) {
      case '131':
        return <Text as="disclaimer">13º salário (parcela 1)</Text>;
      case '132':
        return <Text as="disclaimer">13º salário (parcela 2)</Text>;
      default:
        return <Text as="disclaimer">Folha Salarial</Text>;
    }
  }

  switch (type) {
    case 'ContraCheque':
      return (
        <Container onClick={() => handleRedirect()} opened={opened}>
          <TitleNotification>
            <Text as="disclaimer">
              Seu <b>Demonstrativo de Pagamento</b> está disponível
            </Text>
          </TitleNotification>
          <InformationNotification>
            <Text as="disclaimer">
              {' '}
              Competência: {`${MONTHS[Number(month) - 1]}/${year}`}
            </Text>
            <br />
            {handleDefinedSubtypePayment()}
          </InformationNotification>
          <DateNotification>
            <Text as="disclaimer">
              {format(new Date(String(createdAt)), 'dd/MM/yyyy - HH:mm')}
            </Text>
          </DateNotification>
          <Separator />
        </Container>
      );
    case 'FolhaPonto':
      return (
        <Container onClick={() => handleRedirect()} opened={opened}>
          <TitleNotification>
            <Text as="disclaimer">
              Seu <b>Espelho de Ponto</b> está disponível
            </Text>
          </TitleNotification>
          <InformationNotification>
            <Text as="disclaimer">
              Competência: {`${MONTHS[Number(month) - 1]}/${year}`}
            </Text>
          </InformationNotification>
          <DateNotification>
            <Text as="disclaimer">
              {format(new Date(String(createdAt)), 'dd/MM/yyyy - HH:mm')}
            </Text>
          </DateNotification>
          <Separator />
        </Container>
      );
    case 'noNotifications':
      return (
        <Container type={type} opened>
          <TitleNoNotifications>
            <Text as="disclaimer">
              Infelizmente, não há notificações a serem exibidas no momento.
            </Text>
          </TitleNoNotifications>
        </Container>
      );
    default:
      return null;
  }
};

export default CardContentNotification;
