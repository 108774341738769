import { ITimeSheet } from 'models/timeSheet';
import { TimeSheetTypes } from './types';

export const loadTimeSheetRequest = () => ({
  type: TimeSheetTypes.LOAD_TIME_SHEET_REQUEST,
});

export const loadTimeSheetSuccess = (data: ITimeSheet[]) => ({
  type: TimeSheetTypes.LOAD_TIME_SHEET_SUCCESS,
  payload: {
    data,
  },
});

export const loadTimeSheetFailure = () => ({
  type: TimeSheetTypes.LOAD_TIME_SHEET_FAILURE,
});

export const printTimeSheetRequest = ({
  competence,
}: {
  competence?: {
    ano?: string;
    mes?: string;
    title?: string;
  }[];
}) => ({
  type: TimeSheetTypes.PRINT_TIME_SHEET_REQUEST,
  payload: {
    competence,
  },
});

export const printTimeSheetSuccess = () => ({
  type: TimeSheetTypes.PRINT_TIME_SHEET_SUCCESS,
});

export const printTimeSheetFailure = () => ({
  type: TimeSheetTypes.PRINT_TIME_SHEET_FAILURE,
});
