// eslint-disable-next-line no-shadow
/* eslint-disable no-unused-vars */
export enum ITrackGetTypes {
  TRACK_GET_REQUEST = '@request/TRACK_GET_REQUEST',
  TRACK_GET_SUCCESS = '@request/TRACK_GET_SUCCESS',
  TRACK_GET_FAILURE = '@request/TRACK_GET_FAILURE',
  TRACK_GET_RESET = '@request/TRACK_GET_RESET',
}

export interface IResponseComplete {
  categoria: string;
  solicitacao: string;
  subCategoria: string;
  datatAbertura: string;
  numeroChamado: string;
  status: string;
  isFerias?:boolean;
}
export interface ITrackGetData {
  message: string;
  paginaAtual: string;
  responseComplete: IResponseComplete[];
  status: number;
  totalPaginas: number;
  totalSolicitações: number;
}

export interface ITrackGetError {
  status?: number;
  message?: string;
  success?: boolean;
}

export interface ITrackGetState {
  readonly data: ITrackGetData;
  readonly loading: boolean;
  readonly error: ITrackGetError | null;
}
