/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from 'redux';

import landing from './landing/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import faq from './faq/reducer';
import chat from './chat/reducer';

// Benefícios
import health from './benefits/health/reducer';
import dental from './benefits/dental/reducer';

// Demonstrativos
import payment from './paycheck/payment/reducer';
import vacation from './paycheck/vacation/reducer';
import annualIncome from './paycheck/annualIncome/reducer';

// Folha de Ponto
import timeSheet from './timeSheet/reducer';

// Solicitações
import healthDuplicate from './request/health/duplicate/reducer';
import healthDependent from './request/health/dependent/reducer';
import inssRequest from './request/inss/request/reducer';
import inssSend from './request/inss/send/reducer';
import employmentRequest from './request/employment/request/reducer';
import dentalDuplicate from './request/dental/duplicate/reducer';
import dentalDependent from './request/dental/dependent/reducer';
import transportDuplicate from './request/transport/reducer';
import transportNew from './request/transport/new/reducer';
import transportChange from './request/transport/change/reducer';
import transportCancel from './request/transport/cancel/reducer';
import trackReopen from './request/track/reopen/reducer';
import trackCancel from './request/track/cancel/reducer';
import trackChange from './request/track/change/reducer';
import trackGet from './request/track/get/reducer';
import trackDetails from './request/track/details/reducer';
import trackDownloadFile from './request/track/downloadFile/reducer';
import executive from './request/executive/reducer';

// Sem conexão
import errorNetwork from './errorNetWork/reducer';

// Atualização da Aplicação
import updateAvailable from './updateAvailable/reducer';

// Comunicados
import announcements from './announcements/reducer';

// Pesquisa
import researchForm from './researchForm/reducer';

// Configurações
import configuration from './accountConfig/configuration/reducer';

// Notificações
import notification from './notification/reducer';

// Status de Ferias
import vacationRequest from './request/vacation/request/reducer';

import { UserTypes } from './user/types';
import { AuthTypes } from './auth/types';
import dependents from './dependents/reducer';

const rootReducer = combineReducers({
  landing,
  auth,
  faq,
  user,
  health,
  dental,
  payment,
  vacation,
  annualIncome,
  timeSheet,
  healthDuplicate,
  healthDependent,
  inssRequest,
  inssSend,
  employmentRequest,
  dentalDuplicate,
  dentalDependent,
  transportDuplicate,
  errorNetwork,
  transportNew,
  transportChange,
  transportCancel,
  trackReopen,
  trackCancel,
  trackChange,
  trackGet,
  trackDetails,
  trackDownloadFile,
  executive,
  announcements,
  researchForm,
  updateAvailable,
  configuration,
  notification,
  chat,
  dependents,
  vacationRequest
});

export default (state: any, action: any) =>
  rootReducer(
    action.type === UserTypes.BLOCK_USER_SUCCESS ||
      action.type === AuthTypes.LOGOUT_SUCCESS
      ? {
        auth: state?.auth,
        user: state?.user,
      }
      : state,
    action,
  );
