/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IDownloadFileState, IDownloadFileTypes } from './types';

const INITIAL_STATE: IDownloadFileState = {
  loading: false,
  error: null,
};

const trackDownloadFile: Reducer<IDownloadFileState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case IDownloadFileTypes.DOWNLOAD_FILE_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case IDownloadFileTypes.DOWNLOAD_FILE_REQUEST_ACTIONS: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case IDownloadFileTypes.DOWNLOAD_FILE_SUCCESS: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      case IDownloadFileTypes.DOWNLOAD_FILE_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default trackDownloadFile;
