/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  isChrome,
  isSafari,
  isEdge,
  isIOS,
  isAndroid,
} from 'react-device-detect';
import { IState } from 'store';
import { ILandingState } from 'store/modules/landing/types';
import { IUpdateAvailableState } from 'store/modules/updateAvailable/types';
import {
  setDeferredPrompt,
  setFloatingBalloon,
} from 'store/modules/landing/actions';

import { IHeader, ILink } from 'models/header';
import { IMenu } from 'models/menu';

import MainHeader from 'shared/Header/MainHeader';
import Menu from 'shared/Menu';
import Icon from 'shared/Icon';
import Button from 'shared/Button';
import Text from 'shared/Tipografia/Text';
import FloatingBalloon from 'shared/FloatingBalloon';
import UpdateAvailable from 'shared/UpdateAvailable';
import { handleChatButton } from 'utils/general';
import theme from 'styles/theme';
import { Container, DesktopHeader, Wrapper } from './styles';

const Landing: React.FC = ({ children }) => {
  const { showFloatingBalloon, deferredPrompt } = useSelector<
    IState,
    ILandingState
  >((state) => state.landing);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const { update, downloadUpdates } = useSelector<
    IState,
    IUpdateAvailableState
  >((state) => state.updateAvailable);

  const history = useHistory();
  const dispatch = useDispatch();

  function handleInstallClick() {
    if (isIOS) {
      dispatch(setFloatingBalloon({ showFloatingBalloon: true }));
      return;
    }
    if (isAndroid && !isChrome && !isEdge) {
      history.push('/auth');
    }
    if (isIOS && !isSafari && !isChrome) {
      history.push('/auth');
    }

    // Show the install prompt
    deferredPrompt.prompt();
  }

  function handleCloseFloatingBalloon() {
    dispatch(setFloatingBalloon({ showFloatingBalloon: false }));
  }

  function handleClickGoToLogin() {
    history.push('/auth');
  }


  const links: ILink[] = [
    {
      id: 'installApp',
      name: 'Instale o novo app',
      iconName: 'download',
      onClick: () => handleInstallClick(),
    },
  ];

  const headerProps: IHeader = {
    buttons: [
      {
        id: 'chat',
        icon: 'chatCircle',
        color: theme.colors.white,
        size: '20',
        isMobile: true,
        onClick: () => handleChatButton(),
      },
      {
        id: 'menu',
        icon: 'menu',
        color: theme.colors.white,
        size: '20',
        isMobile: true,
        onClick: () => setIsOpenMenu(!isOpenMenu),
      },
    ],
  };

  const menuProps: IMenu = {
    links,
    isOpen: isOpenMenu,
    handleMenu: () => setIsOpenMenu(!isOpenMenu),
  };

  useEffect(() => {
    function initializeDeferredPrompt() {
      window.addEventListener('beforeinstallprompt', (e: any) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        dispatch(setDeferredPrompt(e));
      });
    }

    initializeDeferredPrompt();
  }, [dispatch]);

  return (
    <>
      {update && (
        <UpdateAvailable update={update} downloadUpdates={downloadUpdates} />
      )}
      <Container update={update}>
        <DesktopHeader update={update}>
          <Icon name="logoRhDigitalDefault" width="207" height="38" />
          <Button onClick={handleClickGoToLogin}>
            <p>Entre</p>
          </Button>
        </DesktopHeader>

        <MainHeader {...headerProps} />
        <Menu {...menuProps} />

        <Wrapper>{children}</Wrapper>

        <FloatingBalloon
          visible={showFloatingBalloon}
          closeBalloon={handleCloseFloatingBalloon}
        >
          <Text as="disclaimer">
            Clique no ícone <Icon name="shareiOS" /> do seu navegador e
            selecione a opção: <span>Adicionar à tela de início.</span>
          </Text>
        </FloatingBalloon>
      </Container>
    </>
  );
};

export default Landing;
