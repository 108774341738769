/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { ITransportDependent } from 'models/request/transport';

/**
 * Actions Types
 */
export enum ITransportDuplicateTypes {
  TRANSPORT_DUPLICATE_SET_DATA = '@request/TRANSPORT_DUPLICATE_SET_DATA',
  TRANSPORT_DUPLICATE_REQUEST = '@request/TRANSPORT_DUPLICATE_REQUEST',
  TRANSPORT_DUPLICATE_SUCCESS = '@request/TRANSPORT_DUPLICATE_SUCCESS',
  TRANSPORT_DUPLICATE_FAILURE = '@request/TRANSPORT_DUPLICATE_FAILURE',
  TRANSPORT_DUPLICATE_RESET = '@request/TRANSPORT_DUPLICATE_RESET',
}

/**
 * State Types
 */

export interface ITransportData {
  dependents?: ITransportDependent[];
  optionId?: string;
}

export interface ITransportError {
  status?: number;
  message?: string;
}

export interface ITransportDuplicateState {
  readonly data: ITransportData | null;
  readonly loading: boolean;
  readonly error: ITransportError | null;
}
