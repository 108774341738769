/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IUser } from 'models/user';

/**
 * Actions Types
 */
export enum UserTypes {
  GET_USER_REQUEST = '@user/GET_USER_REQUEST',
  GET_USER_SUCCESS = '@user/GET_USER_SUCCESS',
  GET_USER_FAILURE = '@user/GET_USER_FAILURE',
  BLOCK_USER_REQUEST = '@auth/BLOCK_USER_REQUEST',
  BLOCK_USER_SUCCESS = '@auth/BLOCK_USER_SUCCESS',
  BLOCK_USER_FAILURE = '@auth/BLOCK_USER_FAILURE',
  SELECT_USER_CURRENT = '@user/SELECT_USER_CURRENT',
  RESET_ERROR = '@user/RESET_ERROR',
}

export interface IError {
  status: number;
  message: string;
}

/**
 * State Types
 */
export interface IUserState {
  readonly data: IUser[];
  readonly current: IUser | null;
  readonly loading: boolean;
  readonly error: IError | null;
}
