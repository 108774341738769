/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';

import { IErrorNetworkState, ErrorNetworkTypes } from './types';

const INITIAL_STATE: IErrorNetworkState = {
  messageError: null,
};

const errorNetwork: Reducer<IErrorNetworkState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ErrorNetworkTypes.ERROR_NETWORK: {
        draft.messageError = true;
        break;
      }
      default:
        break;
    }
  });
};

export default errorNetwork;
