import styled from 'styled-components';

import { MAX_WIDTH_TABLET } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  position: fixed;
  height: 65px;
  width: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0px;

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    z-index: 5;
    padding: 21px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
  }

  button {
    width: 120px;
    height: 37px;
    background-color: #fff;
    color: #004186;
    margin-left: 40px;
    font-size: 11px;
  }
`;
