import React from 'react';

import { useHistory } from 'react-router-dom';

import Icon from '../../Icon';
import { Container, DivBack, TextBack } from './styles';

const UpHeader: React.FC = () => {
  const history = useHistory();

  return (
    <Container className="containerHeaderButtonPageInitial">
      <DivBack onClick={() => history.push('/')}>
        <Icon name="pullDownP" height="7.5" width="12" />
        <TextBack>
          Voltar para <span>página inicial</span>
        </TextBack>
      </DivBack>
    </Container>
  );
};

export default UpHeader;
