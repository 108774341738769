/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IDentalState, DentalTypes } from './types';

const INITIAL_STATE: IDentalState = {
  data: null,
  haveInsurance: null,
  disabled: false,
  loading: false,
  error: false,
};

const dental: Reducer<IDentalState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case DentalTypes.GET_DENTAL_REQUEST: {
        draft.loading = true;
        break;
      }
      case DentalTypes.GET_DENTAL_SUCCESS: {
        draft.data = action.payload.data;
        draft.haveInsurance = true;
        draft.disabled = action.payload.data === null;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case DentalTypes.GET_DENTAL_FAILURE: {
        draft.data = null;
        draft.haveInsurance = action.payload.haveInsurance;
        draft.disabled = false;
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
        break;
    }
  });
};

export default dental;
