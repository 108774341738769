import React, { useEffect } from 'react';
import { Balloon, BalloonTip, Container, Content } from './styles';

interface IFloatingBalloon {
  visible: boolean;
  closeBalloon: () => void;
}

const FloatingBalloon: React.FC<IFloatingBalloon> = ({
  visible,
  closeBalloon,
  children,
}) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'unset';
  }, [visible]);

  return (
    <Container visible={visible} onClick={closeBalloon}>
      <Balloon visible={visible}>
        <Content>{children}</Content>
        <BalloonTip />
      </Balloon>
    </Container>
  );
};

export default FloatingBalloon;
