import { UpdateAvailableTypes } from './types';

export const updateAvailable = ({
  update,
  downloadUpdates,
}: {
  update: boolean;
  downloadUpdates: boolean;
}) => ({
  type: UpdateAvailableTypes.UPDATE_AVAILABLE,
  payload: {
    update,
    downloadUpdates,
  },
});
