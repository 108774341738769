import { ITrackCancelError, ITrackCancelTypes } from './types';

export const trackCancelRequest = ({
  called,
  description,
  file,
  handleClickNextStep,
}: {
  called: string;
  description: string | undefined;
  file: File[];
  handleClickNextStep: () => void;
}) => ({
  type: ITrackCancelTypes.TRACK_CANCEL_REQUEST,
  payload: {
    called,
    description,
    file,
    handleClickNextStep,
  },
});

export const trackCancelSuccess = () => ({
  type: ITrackCancelTypes.TRACK_CANCEL_SUCCESS,
});

export const trackCancelFailure = (error: ITrackCancelError) => ({
  type: ITrackCancelTypes.TRACK_CANCEL_FAILURE,
  payload: {
    error,
  },
});
