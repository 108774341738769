/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { ITrackGetState, ITrackGetTypes } from './types';

const INITIAL_STATE: ITrackGetState = {
  data: {
    message: '',
    paginaAtual: '',
    responseComplete: [],
    status: 0,
    totalPaginas: 0,
    totalSolicitações: 0,
  },
  loading: false,
  error: null,
};

const trackGet: Reducer<ITrackGetState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ITrackGetTypes.TRACK_GET_REQUEST: {
        draft.loading = true;
        break;
      }
      case ITrackGetTypes.TRACK_GET_SUCCESS: {
        draft.data = action.payload.data;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case ITrackGetTypes.TRACK_GET_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case ITrackGetTypes.TRACK_GET_RESET: {
        draft.data = {
          message: '',
          paginaAtual: '',
          responseComplete: [],
          status: 0,
          totalPaginas: 0,
          totalSolicitações: 0,
        };
        draft.loading = false;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default trackGet;
