import { IEmploymentRequestResponse } from 'models/request/employment/request';
import { EmploymentRequestTypes } from './types';

export const employmentRequestRequest = () => ({
  type: EmploymentRequestTypes.EMPLOYMENT_REQUEST_REQUEST,
});

export const employmentRequestSuccess = (
  response: IEmploymentRequestResponse,
) => ({
  type: EmploymentRequestTypes.EMPLOYMENT_REQUEST_SUCCESS,
  payload: {
    response,
  },
});

export const employmentRequestFailure = () => ({
  type: EmploymentRequestTypes.EMPLOYMENT_REQUEST_FAILURE,
});

export const employmentRequestReset = () => ({
  type: EmploymentRequestTypes.EMPLOYMENT_REQUEST_RESET,
});
