/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUser } from 'models/user';
import { IError, UserTypes } from './types';

export const getUserRequest = () => ({
  type: UserTypes.GET_USER_REQUEST,
});

export const getUserSuccess = (data: IUser[], current: IUser | null) => ({
  type: UserTypes.GET_USER_SUCCESS,
  payload: {
    data,
    current,
  },
});

export const getUserFailure = (error: IError) => ({
  type: UserTypes.GET_USER_FAILURE,
  payload: {
    error,
  },
});

export const blockUserRequest = ({ cpf }: { cpf: string }) => ({
  type: UserTypes.BLOCK_USER_REQUEST,
  payload: {
    cpf,
  },
});

export const blockUserSuccess = () => ({
  type: UserTypes.BLOCK_USER_SUCCESS,
});

export const blockUserFailure = (error: IError) => ({
  type: UserTypes.BLOCK_USER_FAILURE,
  payload: {
    error,
  },
});

export const selectUserCurrent = (current: IUser) => ({
  type: UserTypes.SELECT_USER_CURRENT,
  payload: {
    current,
  },
});

export const resetError = () => ({ type: UserTypes.RESET_ERROR });
