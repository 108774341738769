/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import INotificationData from 'models/notification';

/**
 * Actions Types
 */
export enum INotificationTypes {
  NOTIFICATION_ALERT_GET_REQUEST = '@notification/NOTIFICATION_ALERT_GET_REQUEST',
  NOTIFICATION_ALERT_GET_SUCCESS = '@notification/NOTIFICATION_ALERT_GET_SUCCESS',
  NOTIFICATION_ALERT_GET_FAILURE = '@notification/NOTIFICATION_ALERT_GET_FAILURE',
  NOTIFICATION_ALL_GET_REQUEST = '@notification/NOTIFICATION_ALL_GET_REQUEST',
  NOTIFICATION_ALL_GET_SUCCESS = '@notification/NOTIFICATION_ALL_GET_SUCCESS',
  NOTIFICATION_ALL_GET_FAILURE = '@notification/NOTIFICATION_ALL_GET_FAILURE',
  NOTIFICATION_GET_REQUEST = '@notification/NOTIFICATION_GET_REQUEST',
  NOTIFICATION_GET_SUCCESS = '@notification/NOTIFICATION_GET_SUCCESS',
  NOTIFICATION_GET_FAILURE = '@notification/NOTIFICATION_GET_FAILURE',
  NOTIFICATION_GET_RESET = '@notification/NOTIFICATION_GET_RESET',
  NOTIFICATION_PUT_OPEN_REQUEST = '@notification/NOTIFICATION_PUT_OPEN_REQUEST',
  NOTIFICATION_PUT_OPEN_SUCCESS = '@notification/NOTIFICATION_PUT_OPEN_SUCCESS',
  NOTIFICATION_PUT_OPEN_FAILURE = '@notification/NOTIFICATION_PUT_OPEN_FAILURE',
}
/**
 * State Types
 */

export interface INotificationError {
  status?: number;
  message?: string;
}

export interface INotificationState {
  readonly dataNotificationAlert: INotificationData | null;
  readonly dataNotification: INotificationData | null;
  readonly page: number;
  readonly limit: number;
  readonly numberClosedNotification: number;
  readonly loading: boolean;
  readonly idNotification: string;
  readonly errorNotificationAlert: INotificationError | null;
  readonly errorNotification: INotificationError | null;
  readonly errorNotificationPut: INotificationError | null;
}
