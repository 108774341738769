/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { IVacation } from 'models/vacation';

/**
 * Actions Types
 */
export enum VacationTypes {
  LOAD_VACATION_REQUEST = '@vacation/LOAD_VACATION_REQUEST',
  LOAD_VACATION_SUCCESS = '@vacation/LOAD_VACATION_SUCCESS',
  LOAD_VACATION_FAILURE = '@vacation/LOAD_VACATION_FAILURE',
  PRINT_VACATION_REQUEST = '@vacation/PRINT_VACATION_REQUEST',
  PRINT_VACATION_SUCCESS = '@vacation/PRINT_VACATION_SUCCESS',
  PRINT_VACATION_FAILURE = '@vacation/PRINT_VACATION_FAILURE',
}

/**
 * State Types
 */
export interface IVacationState {
  readonly data: IVacation[];
  readonly disabled: boolean;
  readonly printing: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
