/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IConfigurationTypes, IConfigurationState } from './types';

const INITIAL_STATE: IConfigurationState = {
  data: null,
  arrayNotification: [],
  loading: false,
  error: null,
};

const configuration: Reducer<IConfigurationState> = (
  state = INITIAL_STATE,
  action,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case IConfigurationTypes.USER_CONFIGURATION_REQUEST: {
        draft.loading = true;
        break;
      }
      case IConfigurationTypes.USER_CONFIGURATION_SUCCESS: {
        draft.data = action.payload.data;
        draft.arrayNotification = action.payload.arrayNotification;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case IConfigurationTypes.USER_CONFIGURATION_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case IConfigurationTypes.USER_CONFIGURATION_PUT_REQUEST: {
        draft.arrayNotification = action.payload.arrayNotification;
        break;
      }
      case IConfigurationTypes.USER_CONFIGURATION_PUT_SUCCESS: {
        draft.error = null;
        break;
      }
      case IConfigurationTypes.USER_CONFIGURATION_PUT_FAILURE: {
        draft.error = action.payload.error;
        break;
      }
      default:
        break;
    }
  });
};

export default configuration;
