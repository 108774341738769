import React from 'react';

import Spinner from 'shared/Icon/Spinner';
import theme from 'styles/theme';

import { LoaderSizes } from './Enum';

import { Container } from './styles';

interface ILoader {
  size?: LoaderSizes;
  color?: string;
}

const Loader: React.FC<ILoader> = ({
  size = LoaderSizes.DEFAULT,
  color = theme.colors.primary,
}) => {
  return (
    <Container>
      <Spinner size={size} color={color} />
    </Container>
  );
};

export default Loader;
