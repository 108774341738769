import styled from 'styled-components';

import { MAX_WIDTH_TABLET } from 'utils/constants';

interface IContainer {
  opened?: boolean;
  type?: string;
}

export const Container = styled.div`
  padding-top: 24px;
  width: 100%;
  height: auto;
  cursor: ${(props: IContainer) =>
    props.type === 'noNotifications' ? 'default' : 'pointer'};
  background: ${(props: IContainer) => (props.opened ? '#ffffff' : '#f2f2f2')};
`;

export const TitleNotification = styled.div`
  margin-left: 52px;
  margin-right: 58px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 135%;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  b {
    font-weight: 700;
    font-style: normal;
    line-height: 135%;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    margin-left: 54px;
    margin-right: 55px;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 125%;
    }

    b {
      font-weight: 700;
      font-style: normal;
      line-height: 125%;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const TitleNoNotifications = styled.div`
  margin-left: 52px;
  margin-right: 58px;
  margin-bottom: 25px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 135%;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    margin-left: 54px;
    margin-right: 55px;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 125%;
    }
  }
`;

export const InformationNotification = styled.div`
  margin-top: 15px;
  margin-left: 52px;
  margin-right: 58px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 135%;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    margin-left: 54px;
    margin-right: 55px;

    span {
      font-size: 12px;
      line-height: 125%;
    }
  }
`;

export const DateNotification = styled.div`
  margin-top: 15px;
  margin-left: 52px;
  margin-right: 58px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 135%;
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    margin-left: 54px;
    margin-right: 55px;

    span {
      font-weight: bold;
      font-size: 12px;
      line-height: 125%;
    }
  }
`;

export const Separator = styled.div`
  height: 1px;
  margin-top: 23px;
  background: ${({ theme }) => theme.colors.separatorColor};

  @media (max-width: ${MAX_WIDTH_TABLET}px) {
    margin-top: 27px;
  }
`;
