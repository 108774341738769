/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { IInssSendState, InssSendTypes } from './types';

const INITIAL_STATE: IInssSendState = {
  loading: false,
  error: null,
};

const inssSend: Reducer<IInssSendState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case InssSendTypes.INSS_SEND_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case InssSendTypes.INSS_SEND_SUCCESS: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      case InssSendTypes.INSS_SEND_FAILURE: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case InssSendTypes.INSS_SEND_RESET: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      default:
        break;
    }
  });
};

export default inssSend;
