import { IMessage } from 'models/executive/index';
import { IExecutiveError, IExecutiveTypes } from './types';

export const executiveRequest = ({
  message,
  handleClickNextStep,
}: {
  message: IMessage | null;
  handleClickNextStep: () => void;
}) => ({
  type: IExecutiveTypes.EXECUTIVE_REQUEST,
  payload: {
    message,
    handleClickNextStep,
  },
});

export const executiveSuccess = () => ({
  type: IExecutiveTypes.EXECUTIVE_SUCCESS,
});

export const executiveFailure = (error: IExecutiveError) => ({
  type: IExecutiveTypes.EXECUTIVE_FAILURE,
  payload: {
    error,
  },
});
