import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import landing from './landing/sagas';
import faq from './faq/sagas';
import health from './benefits/health/sagas';
import dental from './benefits/dental/sagas';
import payment from './paycheck/payment/sagas';
import vacation from './paycheck/vacation/sagas';
import annualIncome from './paycheck/annualIncome/sagas';
import timeSheet from './timeSheet/sagas';
import healthDuplicate from './request/health/duplicate/sagas';
import healthDependent from './request/health/dependent/sagas';
import inssRequest from './request/inss/request/sagas';
import inssSend from './request/inss/send/sagas';
import employmentRequest from './request/employment/request/sagas';
import dentalDuplicate from './request/dental/duplicate/sagas';
import dentalDependent from './request/dental/dependent/sagas';
import transportDuplicate from './request/transport/sagas';
import transportNew from './request/transport/new/sagas';
import transportChange from './request/transport/change/sagas';
import transportCancel from './request/transport/cancel/sagas';
import trackReopen from './request/track/reopen/sagas';
import trackCancel from './request/track/cancel/sagas';
import trackChange from './request/track/change/sagas';
import trackGet from './request/track/get/sagas';
import trackDetails from './request/track/details/sagas';
import trackDownloadFile from './request/track/downloadFile/sagas';
import executive from './request/executive/sagas';
import announcements from './announcements/sagas';
import researchForm from './researchForm/sagas';
import configuration from './accountConfig/configuration/sagas';
import notification from './notification/sagas';
import dependents from './dependents/sagas';
import vacationRequest from './request/vacation/request/sagas';

export default function* rootSaga() {
  //@ts-ignore
  return yield all([
    auth,
    user,
    landing,
    faq,
    health,
    dental,
    payment,
    vacation,
    annualIncome,
    timeSheet,
    healthDuplicate,
    healthDependent,
    inssRequest,
    inssSend,
    employmentRequest,
    dentalDuplicate,
    dentalDependent,
    transportDuplicate,
    transportNew,
    transportChange,
    transportCancel,
    trackReopen,
    trackCancel,
    trackChange,
    trackGet,
    trackDetails,
    trackDownloadFile,
    executive,
    announcements,
    researchForm,
    configuration,
    notification,
    dependents,
    vacationRequest
  ]);
}
