/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import styled, { css } from 'styled-components';

interface IContentButton {
  isProfile?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 64px;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-left: 28px;
  padding-right: 26px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryHover};
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const ContentButton = styled.div<IContentButton>`
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ isProfile }) =>
    isProfile &&
    css`
      margin-right: 13px;

      svg {
        border-radius: 50%;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.quaternary};
      }
    `}
`;
